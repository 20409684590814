<template>
	<form>
		<GuestHeading>Iniciar sesión</GuestHeading>
		<GuestErrors
			:class="Object.keys(errors).length > 0 ? 'd-block' : 'd-none'"
		>
			<span class="d-block" v-for="error in errors" :key="error">{{
				error[0]
			}}</span>
		</GuestErrors>
		<GuestInput
			type="text"
			placeholder="Código de tu centro educativo"
			name="school_code"
			v-model="school_code"
			:class="errors.school_code ? 'is-invalid' : ''"
		/>
		<GuestPassword
			v-model="access_code"
			:placeholder="`Tu código de acceso`"
		/>

		<GuestButton @click.prevent="handleFormSubmit">Entrar</GuestButton>
		<GuestMessage>
			¿Has olvidado tu código de acceso? 
			<Link @click.prevent>Pregunta a tu profesor.</Link>
		</GuestMessage>
	</form>
</template>

<script>
import GuestPassword from '../../components/GuestPassword.vue'
import Link from "../../components/Link.vue";
import GuestErrors from "../../components/GuestErrors.vue";
import GuestHeading from "../../components/GuestHeading.vue";
import GuestInput from "../../components/GuestInput.vue";
import GuestButton from "../../components/GuestButton.vue";
import GuestMessage from "../../components/GuestMessage.vue";
import EyeButton from "../../components/EyeButton.vue";
import { useStore } from 'vuex';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import User from "@/api/User"

export default {
	name: "Login",
	components: {
		GuestErrors,
		GuestHeading,
		GuestInput,
		EyeButton,
		GuestMessage,
		GuestButton,
		Link, GuestPassword
	},
	setup() {
		const store = useStore();
		const errors = ref({});
		const school_code = ref("");
		const access_code = ref("");
		const router = useRouter();
		const route = useRoute();

		const handleFormSubmit = () => {
			User.login({
				school_code: school_code.value,
				access_code: access_code.value,
			})
				.then((response) => {
					if (response.status == 200) {
						errors.value = {};
						store.dispatch("login");
						if (route.query.redirect)
							router.push(route.query.redirect);
						else router.push({ name: "OneLearnBoth" });
					}
				})
				.catch((errs) => {
					// incorrect credentials
					if (
						errs.response.status == 404 ||
						errs.response.status == 422
					) {
						errors.value = errs.response.data.errors;
					}
				});
		};

		return {
			school_code,
			access_code,

			errors,
			handleFormSubmit,
		};
	},
};
</script>

<style scoped>
form {
	position: relative;
	z-index: 1;
	background: #ffffff;
	max-width: 360px;
	padding: 45px;
	text-align: center;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
</style>
