<template>
	<div class="left-to-right none">
		<div class="series-row 1">
			<div class="big-text">
				Fila superior Mano izquierda Meñique
				<div>
					<img src="@/assets/images/left-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone red-bg" data-dropzone="1">
					<div class="check-label" data-correct="1">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 3">
			<div class="big-text">
				Fila superior Mano izquierda Medio
				<div>
					<img src="@/assets/images/left-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone yellow-bg" data-dropzone="3">
					<div class="check-label" data-correct="3">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 4">
			<div class="big-text">
				Fila superior Mano izquierda Índice
				<div>
					<img src="@/assets/images/left-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone green-bg" data-dropzone="4">
					<div class="check-label" data-correct="4">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 2">
			<div class="big-text">
				Fila superior Mano izquierda Anular
				<div>
					<img src="@/assets/images/left-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone blue-bg" data-dropzone="2">
					<div class="check-label" data-correct="2">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 5">
			<div class="big-text">
				Fila superior Mano izquierda Índice
				<div>
					<img src="@/assets/images/left-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone green-bg" data-dropzone="4">
					<div class="check-label" data-correct="4">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div>
			<div class="wrapper2 drag">
				<div class="dropzone occupied" data-dropzone="3">
					<div class="draggable">
						E
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable">
						T
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="1">
					<div class="draggable">
						Q
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable">
						R
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="2">
					<div class="draggable">
						W
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setupActivityTwo } from "@/common/activities";

export default {
	name: "Second",
	mounted: ()=> setupActivityTwo('wrapper2', 'wrapper2', '.left-to-right .series-row'),
};
</script>

<style scoped>
</style>