import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import main from "./main"
import guest from "./guest"
import auth from "./auth"

import NProgress from "nprogress";

const routes = [
	// Main pages
	...main,

	// Guest Pages
	...guest,

	// Spanish Touch Typing Pages
	...auth,

	// page not found
	{
		path: "/:catchAll(.*)",
		name: "NotFound",
		component: () => import("@/views/ErrorPage/NotFound"),
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeResolve((to, from, next) => {
	// If this isn't an initial page load.
	if (to.name) {
		// Start the route progress bar.
		NProgress.start();
	}

	if (to.matched.some((record) => record.meta.layout == 'Auth')) {
		// this route requires auth, check if logged in
		// if not, redirect to login page.
		if (!store.getters.pupil.value) {
			next({
				path: "/login",
				query: { redirect: to.fullPath },
			});
		} else {
			next();
		}
	} else if(to.matched.some((record)=>record.meta.layout == 'Guest')){
		if (store.getters.pupil.value) {
			next({path: "/profile",});
		} else {
			next();
		}
	}
	else {
		next(); // make sure to always call next()!
	}
});

router.afterEach((to, from) => {
	// Complete the animation of the route progress bar.
	NProgress.done();
});
export default router;
