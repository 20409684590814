<template>
	<div class="key-text-left none">
		<h2 class="red-text">Q</h2>
		<h2 class="blue-text">W</h2>
		<h2 class="yellow-text">E</h2>
		<h2 class="green-text">R</h2>
		<h2 class="green-text">T</h2>
	</div>
	<div class="key-text-right none">
		<h2 class="red-text">P</h2>
		<h2 class="blue-text">O</h2>
		<h2 class="yellow-text">I</h2>
		<h2 class="green-text">U</h2>
		<h2 class="green-text">Y</h2>
	</div>
</template>

<script>
export default {
	name: "KeyText",
};
</script>

<style scoped>
</style>