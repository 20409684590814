<template>	
	<!-- Modal -->
	<div class="modal fade" id="preparationModal" tabindex="-1" role="dialog" aria-labelledby="preparationModalLabel" aria-hidden="true">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body" style="color: #325591;">
					<p>Está en preparación y estará disponible en unas semanas.</p>
					<p>Con este programa de entreno se puede llegar a velocidades muy altas de forma planificada y consistente. Utilizando la inteligencia artificial, el aprendizaje es altamente eficaz. El entreno de velocidad más innovador del mundo.</p> 
					<p>Esto es para todos aquellos que necesitan un certificado o quieren conseguir una alta velocidad rápidamente.</p>
				</div>
			</div>
		</div>
	</div> 
</template>

<script>
export default {
	name: "PreparationModal",
};
</script>


<style scoped>
	#preparationModal .modal-dialog{
		margin-top: 10%;
		max-width: 60%;
	}
</style>