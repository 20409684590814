<template>
	<div class="slide-1 green-text none" style="margin-top:-5em;">
		<div class="flex" style=" justify-content:center;">
			<h2 class="letter" style="font-size: 46px;">ü</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>