<template>
	<div class="golden-cup none" >
		<div style="height: 90vh" class="d-flex justify-content-center align-items-center">
			<img src="@/assets/images/golden-cup.gif" style="height:300px" alt="golden cup"  />
		</div>
	</div>
	<div class="letters-fancy none" >
		<div style="height: 90vh" class="d-flex flex-column justify-content-center align-items-center">
			<div class="mt-4 mb-4 tecleo_text none">Tecleo Mix</div>
			<img src="@/assets/images/letters-fancy.gif" alt="golden cup" style="height:300px" />
		</div>
	</div>
</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
	.tecleo_text{
		font-size: 24px;
		font-weight: bold;
		color: var(--color-navy-blue)
	}
</style>