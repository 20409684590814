<template>
	<div class="keyboard__row row__one">
		<div class="key--double key-flex red-button" data-key="192"></div>
		<div class="key--double key-left-column red-button" data-key="49">
			<div>!</div>
			<div>1</div>
		</div>
		<div class="key--double key-flex blue-button" data-key="50">
			<div><br />2</div>
			<div>@</div>
		</div>
		<div class="key--double key--bottom-left yellow-button" data-key="51">
			<div>3</div>
		</div>
		<div class="key--double key--bottom-left green-button" data-key="52">
			<div>4</div>
		</div>
		<div class="key--double key--bottom-left green-button" data-key="53">
			<div>5</div>
		</div>
		<div class="key--double key--bottom-left green-button" data-key="54">
			<div>6</div>
		</div>
		<div class="key--double key--bottom-left green-button" data-key="55">
			<div>7</div>
		</div>
		<div class="key--double key--bottom-left yellow-button" data-key="56">
			<div>8</div>
		</div>
		<div class="key--double key--bottom-left blue-button" data-key="57">
			<div>9</div>
		</div>
		<div class="key--double key--bottom-left red-button" data-key="48">
			<div>0</div>
		</div>
		<div class="key--double key-left-column red-button" data-key="189">
			<div>?</div>
		</div>
		<div class="key--double key-left-column red-button" data-key="191">
			<div>¿</div>
			<div>¡</div>
		</div>
		<div class="key--top-left red-button key--word key-backspace delete-key" data-key="8">
			<img src="@/assets/images/back.png" style="height:50px;"/>
		</div>
	</div>
</template>

<script>
export default {
	name: "RowOne",
};
</script>

<style scoped>
	div.delete-key{
		display: flex;
		justify-content: center;
	}
	div.delete-key img{
		top:0em;
		left: auto;
	}
</style>