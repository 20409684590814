<template>

	<div class="col-md-3">
		<div class="btns pb-2 d-flex flex-column justify-content-end align-items-start h-100">
			<div  class="sensacional-text">
				<ul>
					<li	style="list-style: none;"> ¡Sensacional!</li>
					<li > Aprende teclear sin mirar en 1 hora.</li>
					<li > Tienes acceso a todos los niveles de tecleo por 3 meses.</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "LeftSide",
}
</script>

<style scoped>
	.btns {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		font-weight: bold;
	}


	a:hover {
		text-decoration: none;
		color: turquoise;
	}

	a {
		color: turquoise;
	}

	.side__btn {
		border-radius: 0.3rem;
		display: flex;
		align-items: center;
		font-size: 20px;
		background-color: #41E0D0;
		padding: 10px;
		margin: 2px;
		width: 100%;
		flex: 1;
		color: white;
	}
	.side__btn a{
		color: turquoise;
		text-align: center;	
	}
	.side__btn:hover {
		box-shadow: 2px 4px 6px turquoise;
		color:#212529;
	}


	.sensacional-text{
		color:  #325591;
		font-size: 20px;
	}

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.sensacional-text {
			width: 100%;
		}
	}


</style>