<template>
	<div class="slides none">
		<div class="slide-1 red-text">
			<div class="flex">
				<h2 class="letter">
					Q
				</h2>
				<div>
					<img
						class="symbol"
						src="@/assets/images/11-Kiosk-(Shop)-min.png"
						alt="quiosco"
					/>
				</div>
			</div>
			<h2 class="word">
				Quiosco
			</h2>
		</div>
		<div class="slide-2 blue-text">
			<div class="flex">
				<h2 class="letter">
					W
				</h2>
				<div>
					<img
						src="@/assets/images/16-windsurf-board-with-a-sail-min.png"
						class="symbol"
						alt="Windsurf"
					/>
				</div>
			</div>
			<h2 class="word">
				Windsurf
			</h2>
		</div>
		<div class="slide-3 yellow-text">
			<div class="flex">
				<h2 class="letter">
					E
				</h2>
				<div>
					<img
						src="@/assets/images/19-star-fish-min.png"
						class="symbol"
						alt="Estrella de mar"
					/>
				</div>
			</div>
			<h2 class="word">
				Estrella de mar
			</h2>
		</div>
		<div class="slide-4 green-text">
			<div class="flex">
				<h2 class="letter">
					R
				</h2>
				<div>
					<img
						src="@/assets/images/23-Prince-frog.jpg"
						class="symbol"
						alt="Rana"
					/>
				</div>
			</div>
			<h2 class="word">
				Rana
			</h2>
		</div>
		<div class="slide-5 green-text">
			<div class="flex">
				<h2 class="letter">
					T
				</h2>
				<div>
					<img
						src="@/assets/images/24-Turtle.jpg"
						class="symbol"
						alt="Tortuga"
					/>
				</div>
			</div>
			<h2 class="word">
				Tortuga
			</h2>
		</div>
	</div>

</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>