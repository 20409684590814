<template>
	<main class="main-container container-fluid px-md-5 py-1 d-flex flex-row" style="position: relative;">
		<div class="row special-ie-row" >
			<MainBody/>
			<LeftSide/>
		</div>
	</main>
	<div>
		<Footer/>
	</div>
</template>

<script>

import MainBody from "./MainBody.vue";
import LeftSide from "./LeftSide.vue";
import Footer from "@/common/components/Footer";

export default {
	name: "HomePage",
	components: {
		MainBody,
		LeftSide,
		Footer
	},
}
</script>

<style scoped>
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.special-ie-row {
			width: 100%;
		}
	}

</style>