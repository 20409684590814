import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";

export const startLesson = () => {
	store.getters.animation.value
		.add({
			delay: 500,
			duration: 16300,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("both"));
			},
		})
		.add({begin: () => {
            store.dispatch("playing", false);
        }});

    store.dispatch("loading", false);
	store.dispatch("start", false);
	store.dispatch("playing", true);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/repeated_login.mp3")],
			sprite: {
                // repeated login + TTBR3
                both: [0,15270.861678004534]
            },
			loop: false,
			onload: () => {
                startLesson();
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};