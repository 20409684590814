<template>
	<div class="right-ok none" style="height: 80%">
		<img
			src="@/assets/images/like.png"
			alt="left thumb"
		/>
	</div>
</template>

<script>
export default {
	name: "LeftOkay"
}
</script>

<style scoped>
	.right-ok img{
		width: 500px;
		height: 500px;
	}
</style>