<template>
	<div id="modal-container" class="typing-complete-modal">
		<div class="modal-background">
			<div class="modal">
				¡Felicidades! <br><br>
				¡Lo has hecho muy bien!<br><br>
				Fue fácil,<br>
				rápido y divertido, ¿verdad?<br>
				<br><br>
				<div style="display: flex; justify-content:space-between;width:250px;">
					<AuthButton to="/" @click.prevent="handleLogout" style="position:static">Cerrar sesión</AuthButton>
					<AuthButton to="/two/learn/both" style="position:static">Etapa 2</AuthButton>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AuthButton from '@/components/AuthButton.vue'

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import User from "@/api/User";

export default {
	components: { AuthButton },
	name: "Congrats",
	setup(){
		const store = useStore();
		const router = useRouter();

		const handleLogout = () => {
			User.logout().then((res) => {
				store.dispatch('logout');
				router.push({ name: "Home" });
			});
		};
		return {
			handleLogout
		}
	}

};
</script>

<style scoped>
	.typing-complete-modal{
		position: relative;
		width: 100%;
		height: 100%;
	}
	

	.modal {
		position: absolute;
		display: flex !important;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin: 0 auto;
		color: #3567a1;
		font-weight: revert;
		line-height: 1.5em;
		font-size: 16px;
		font-weight: 600 !important;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
</style>