const actions = {
	pupil: ({ commit }, value) => commit("pupil", value),
	sptt: ({ commit }, value) => commit("sptt", value),
	trone: ({ commit }, value) => commit("trone", value),

	login: ({commit}) => {
		localStorage.setItem("pupil", "true");
		commit("pupil", true);
	},
	logout: ({commit})=>{
		localStorage.removeItem("pupil");
		commit("pupil", false);
	},

	requesting: ({ commit }, value) => commit("requesting", value),
	loading: ({ commit }, value) => commit("loading", value),
	start: ({ commit }, value) => commit("start", value),
	next: ({ commit }, value) => commit("next", value),

	playing: ({ commit }, value) => commit("playing", value),
	maximized: ({ commit }, value) => commit("maximized", value),

	activity: ({ commit }, value) => commit("activity", value),

	primary: ({ commit, state: { howler } }, value) => {
		howler.on(
			"end",
			() => {
				commit("primary", null);
			},
			value
		);
		commit("primary", value);
	},
	secondary: ({ commit }, value) => commit("secondary", value),

	animation: ({ commit }, value) => commit("animation", value),
	howler: ({ commit }, value) => commit("howler", value),

	playAnimation: ({
		state: { howler, primary, secondary, animation },
		commit,
	}) => {
		if (primary) commit("primary", howler.play(primary));
		if (secondary) commit("secondary", howler.play(secondary));
		if (animation) animation.play();
		commit("playing", true);
	},
	pauseAnimation: ({
		state: { howler, animation, primary, secondary },
		commit,
	}) => {
		if (primary) howler.pause(primary);
		if (secondary) howler.pause(secondary);
		if (animation) animation.pause();
		commit("playing", false);
	},

	onActivity: ({ state: { howler, primary, animation }, commit, dispatch }) => {
		if (primary) howler.pause(primary);
		if (animation) animation.pause();
		commit("activity", true);
	},

	offActivity: ({ state: { howler, primary, animation }, commit, dispatch }) => {
		if (animation) animation.play();
		commit("activity", false);
	},

	reset: ({ commit }) => commit("reset"),

	accent_down: ({ commit }, value) => commit("accent_down", value),
	curr_pointer: ({ commit }, value) => commit("curr_pointer", value),
	word_count: ({ commit }, value) => commit("word_count", value),
	strokes_count: ({ commit }, value) => commit("strokes_count", value),
	text: ({ commit }, value) => commit("text", value),
	formatted_text: ({ commit }, value) => commit("formatted_text", value),
	typing_text: ({ commit }, value) => commit("typing_text", value),

};

export default actions;
