<template>
	<div class="keyboard__row row__four">
		<div class="key--top-left red-button key--word key-shift-left" data-key="16">
			<div>Mayús</div>
			<div class="shift-icon">⇧</div>
		</div>
		<div class="key--letter red-button"></div>
		<div class="key--letter red-button bottom-row" data-char="Z">Z</div>
		<div class="key--letter blue-button bottom-row" data-char="X">X</div>
		<div class="key--letter yellow-button bottom-row" data-char="C">C</div>
		<div class="key--letter green-button bottom-row" data-char="V">V</div>
		<div class="key--letter green-button bottom-row" data-char="B">B</div>
		<div class="key--letter green-button bottom-row" data-char="N">N</div>
		<div class="key--letter green-button bottom-row" data-char="M">M</div>
		<div class="key--double yellow-button key--bottom-center bottom-row" data-char="," data-key="188">
			<div>,</div>
		</div>
		<div class="key--double blue-button key--bottom-center bottom-row" data-char="." data-key="190">
			<div>.</div>
		</div>
		<div class="key--double red-button key--bottom-center bottom-row" data-char="-"  data-key="191">
			<div>_</div>
		</div>
		<div class="key--top-left key--word red-button key-shift-right" data-key="16-R">
			<div>Mayús</div>
			<div class="shift-icon">⇧</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RowFour",
};
</script>

<style scoped>
</style>