import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import { windowBlur } from "@/common/helpers";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started("one_activity_right");

	store.getters.animation.value
		.add({
			duration: 2000,
			begin: () => {
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})

		/** *************************************************************************************************
		 * Activity 1
		 ***************************************************************************************************
		 */
		// show draggable and hands  -> activity 1
		.add({
			delay: 0,
			targets: [".right-hand-drag", "#overlay-animation"],
			opacity: [0, 1],
			easing: "easeInOutSine",
			duration: 2000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
		})
		.add({
			targets: ["#overlay-animation"],
			duration: 7000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR44"));
			},
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				// start Activity 1
				window.removeEventListener("blur", windowBlur);

				store.dispatch("onActivity");
			},
		})

		// activity 1 stopped
		.add({
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			delay: 1000,
			targets: [".right-hand-drag"],
			duration: 2500,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR44-1"));

				animatables[0].target.classList.add("fade-out");
			},
		})
		.add({
			delay: 4000,
			targets: [".right-hand-drag", ".right-to-left", "#overlay-animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.remove("none");
				animatables[2].target.classList.remove("none");

				store.dispatch("primary", store.getters.howler.value.play("RHHR45"));
			},
			duration: 6200,
			complete: ({ animatables }) => {
				animatables[2].target.classList.add("none");
				// Start activity 2
				window.removeEventListener("blur", windowBlur);
				store.dispatch("onActivity");
			},
		})

		// activit 2 stopped
		.add({
			duration: 3000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
				store.dispatch("playing", true);
			},
		})
		.add({
			delay: 1000,
			targets: [".right-to-left"],
			duration: 1000,
			opacity: 0,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		.add({
			targets: [
				".red-button",
				".green-button",
				".yellow-button",
				".blue-button",
				"[data-key='93-R']",
			],
			duration: 100,
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: el.classList.contains("blue-button")
					? blueColor
					: greyColor;
			},
		})
		.add(
			{
				targets: ["[data-key='13'] img", "[data-key='8'] img"],
				opacity: 0,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
					animatables[1].target.classList.add("none");
				},
			},
			"-=100"
		)

		.add({
			targets: [".animation", ".keyboard"],
			translateY: ["500px", "0px"],
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
		})
		.add({
			targets: [".keyboard-drag", "#overlay-animation"],
			duration: 11000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("BHHR46"));
			},
			complete: ({ animatables }) => {
				animatables[1].target.classList.add("none");

				store.dispatch("playing", false);
				window.removeEventListener("blur", windowBlur);
				// Start Activity 5
				store.dispatch("onActivity");
			},
		})

		// activit 5 stopped
		.add({
			duration: 3000,
			begin: () => {
				store.dispatch("playing", true);
				window.addEventListener("blur", windowBlur);
			},
		})

		.add({
			delay: 1000,
			duration: 3000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("BHHR46-1"));
			},
		})

		.add({
			targets: [".keyboard", ".animation", ".keyboard-drag"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
				animatables[2].target.classList.add("none");
			},
			complete: () => {
				store.dispatch("next", true);
			},
		})
		.add({
			duration: 10000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("BHHR46-2"));
			},
		})
		.add({
			complete: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);
				SPTT.finished("one_activity_right");
			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/home_row/activity/right.mp3")],
			sprite: {
				active: [0, 252120],
				"BHHR46-1": [254000, 2499.3650793650772],
				"BHHR46-2": [258000, 9596.689342403635],
				BHHR46: [269000, 11497.097505668933],
				"RHHR44-1": [282000, 2299.433106575975],
				RHHR44: [286000, 6884.761904761888],
				RHHR45: [294000, 6098.458049886631],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
