import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import { windowBlur } from "@/common/helpers";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('three_activity_left');

	store.getters.animation.value
		.add({
			targets: [".left-hand-drag", "#overlay-animation"],
			opacity: [0, 1],
			duration: 2000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");

				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		/** *************************************************************************************************
		 * Activity 1
		 ***************************************************************************************************
		 */


		// show draggable and hands  -> activity 1
		.add({
			targets: ["#overlay-animation"],
			easing: "easeInOutSine",
			duration: 4500,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR44-0"));
			},
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				// start Activity 1
				window.removeEventListener("blur", windowBlur);

				store.dispatch("onActivity");
			},
		})

		// activity 1 stopped
		.add({
			delay: 1000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			targets: [".left-hand-drag"],
			duration: 5000,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR44-1"));

				animatables[0].target.classList.add("fade-out");
			},
		})
		.add({
			targets: [".left-hand-drag", ".left-to-right", "#overlay-animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.remove("none");
				animatables[2].target.classList.remove("none");

				store.dispatch("primary", store.getters.howler.value.play("LHBR45-0"));
			},
			duration: 5000,
			complete: ({ animatables }) => {
				animatables[2].target.classList.add("none");
				// Start activity 2
				window.removeEventListener("blur", windowBlur);
				store.dispatch("onActivity");
			},
		})

		// activit 2 stopped
		.add({
			duration: 1000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})

		.add({
			duration: 10000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR45-1"));
			},
		})
		.add({
			targets: [".left-to-right"],
			opacity: [1, 0],
			duration: 2500,
			complete: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// ok thumbsup
		.add({
			targets: ".right-ok",
			opacity: [0, 1],
			duration: 1000,
			translateX: ["-20%", "0%"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('three_activity_left');
			},
		});
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/bottom_row/activity/left.mp3")],
			sprite: {
				active: [0, 252120, true],
				"LHBR44-0": [254000, 4498.684807256212],
				"LHBR44-1": [260000, 2929.93197278912],
				"LHBR45-0": [264000, 4821.700680272102],
				"LHBR45-1": [270000, 9158.888888888896],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
