<template>
	<div class="learning-presentation none">
		<!-- 6  Right Hand-->
		<div class="presentation-6 ">
			<div class="ñ lesson-img">
				<img src="@/assets/images/2-Gnu-min.png" alt="School Bus" class="w-100 h-100" />
			</div>
			<div class="ñ red-text label">
				<h2>Ñ</h2>
				<h2>Ñ</h2>
				<h2>Ñ</h2>
			</div>
		</div>
		<div class="presentation-7">
			<div class="l lesson-img">
				<img src="@/assets/images/4-lion-min.png" alt="School Bus" class="w-100 h-100" />
			</div>
			<div class="l blue-text label">
				<h2>L</h2>
				<h2>L</h2>
				<h2>L</h2>
			</div>
		</div>
		<div class="presentation-8">
			<div class="k lesson-img">
				<img src="@/assets/images/6-kola-min.png" alt="School Bus" class="w-100 h-100" />
			</div>
			<div class="k yellow-text label">
				<h2>K</h2>
				<h2>K</h2>
				<h2>K</h2>
			</div>
		</div>
		<div class="presentation-9">
			<div class="j lesson-img">
				<img src="@/assets/images/9-Jungle-min.png" alt="School Bus" class="w-100 h-100" />
			</div>
			<div class="j green-text label">
				<h2>J</h2>
				<h2>J</h2>
				<h2>J</h2>
			</div>
		</div>
		<div class="presentation-10">
			<div class="h lesson-img">
				<img src="@/assets/images/10-hammock-min.png" alt="School Bus" class="w-100 h-100" />
			</div>
			<div class="h green-text label">
				<h2>J</h2>
				<h2>H</h2>
				<h2>J</h2>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Presentation",
};
</script>

<style scoped>
</style>