<template>
	<div class="col-md-9">
		<!-- <AdSection/> -->
		<div class="video__container">
			<video poster="@/assets/images/eres-mono.png" controls="controls" controlsList=”nodownload” width="100%"
				id="video-area-1" style="object-fit: fill;" playsinline>
				<source src="@/assets/videos/schoollanding.mp4" type="video/mp4"></video>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MainBody",
	}
</script>


<style scoped>
	.video__container {
		flex: 1;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.video__container video {
		margin: 10px;
		border-radius: 10px;
	}

	@media only screen and (min-device-width: 768px) {
		video{
			width: auto;
		}
	}
	

	video {
		height:68vh
	}

	@media only screen and (max-device-width: 480px) {
		video {
			height:auto;
		}
	}


	.none {
		display: none !important;
	}

</style>