<template>
	<div class="keyboard__row row__three">
		<div
			class="key--bottom-left red-button key--word key-capslock"
			data-key="20"
		>
			<span>Bloq <br />Mayús</span>
			<div class="shift-icon" style="float: right">⇧</div>
		</div>
		<div class="key--letter red-button home-row" data-char="A">A</div>
		<div class="key--letter blue-button home-row" data-char="S">S</div>
		<div class="key--letter yellow-button home-row" data-char="D">D</div>
		<div class="key--letter green-button home-row" data-char="F">
			<span>F</span>
			<div class="under"></div>
		</div>
		<div class="key--letter green-button home-row" data-char="G">G</div>
		<div class="key--letter green-button home-row" data-char="H">H</div>
		<div class="key--letter green-button home-row" data-char="J">
			<span>J</span>
			<div class="under"></div>
		</div>
		<div class="key--letter yellow-button home-row" data-char="K">K</div>
		<div class="key--letter blue-button home-row" data-char="L">L</div>
		<div
			class="key--letter red-button home-row"
			data-char="Ñ"
			data-key="164"
		>
			Ñ
		</div>
		<div class="key--double red-button" data-key="222">
			<div>..</div>
			<div>/</div>
		</div>
		<div class="key--double red-button" data-key="223"></div>
		<div
			class="key--top-left key-enter-leg red-button key--word"
			data-key="13"
		>
			<div><i class="fas fa-arrow-alt-up"></i></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "RowThree",
};
</script>

<style scoped>
</style>