<template>
	<div class="key-text-left none">
		<h2 class="red-text">A</h2>
		<h2 class="blue-text">S</h2>
		<h2 class="yellow-text">D</h2>
		<h2 class="green-text">F</h2>
		<h2 class="green-text">G</h2>
	</div>
</template>

<script>
export default {
	name: "KeyText",
};
</script>

<style scoped>
</style>