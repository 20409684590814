import getDefaultState from './state'
const mutations = {
	pupil: (state, value) => state.pupil = value,
	sptt: (state, value) => Object.assign(state.sptt, value),
	trone: (state, value) => Object.assign(state.trone, value),

	requesting: (state, value) => state.requesting = value,
	loading: (state, value) => state.loading = value,
	start: (state, value) => state.start = value,
	next: (state, value) => state.next = value,

	primary: (state, value) => 	state.primary = value,
	secondary: (state, value) => state.secondary = value,

	animation: (state, value) => state.animation = value,
	howler: (state, value) => state.howler = value,

	playing: (state, value) => state.playing = value,
	maximized: (state, value) => state.maximized = value,

	activity: (state, value) => state.activity = value,

	
	accent_down: ( state , value) => state.accent_down = value ,
	curr_pointer: ( state , value) => state.curr_pointer = value ,
	word_count: ( state , value) => state.word_count = value ,
	strokes_count: ( state , value) => state.strokes_count = value ,
	text: ( state , value) => state.text = value ,
	formatted_text: ( state , value) => state.formatted_text = value ,
	typing_text: ( state , value) => state.typing_text = value ,

	reset: (state) => {
		let defaultState = getDefaultState();
		
		state.requesting = defaultState.requesting;
		state.loading = defaultState.loading;
		state.start = defaultState.start;
		state.next = defaultState.next;
	
		state.primary = defaultState.primary;
		state.secondary = defaultState.secondary;
	
		state.animation = defaultState.animation;
		state.howler = defaultState.howler;
	
		state.playing = defaultState.playing;
		state.maximized = defaultState.maximized;

		state.activity = defaultState.activity;
		
		state.accent_down = defaultState.accent_down;
		state.curr_pointer = defaultState.curr_pointer;
		state.word_count = defaultState.word_count;
		state.strokes_count = defaultState.strokes_count;
		state.text = defaultState.text;
		state.formatted_text = defaultState.formatted_text;
		state.typing_text = defaultState.typing_text;
	}

};

export default mutations;
