<template>
	<div class="navigation">
		<div class="d-flex justify-content-center">
			<router-link :to="to">
				<button id="skip-to-content" class="congrats-btn btn mt-2" >
					<span>
						<slot/> &nbsp;
					</span>
					<span>
						<i class="fas fa-arrow-right"></i>
					</span>
				</button>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	name: "AuthButton",
	props: ['to']
}
</script>

<style scoped>
	.navigation{
		position: absolute;
		width: 100%;
		bottom: 4em;
	}
	.congrats-btn{
		background-color: turquoise;
		color: #33649f;
		font-weight: bold;
		font-size: 12px;
		font-family: Arial, Helvetica, sans-serif;
	}

</style>