import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import { windowBlur } from '@/common/helpers'
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('one_learn_right');

	store.getters.animation.value
		.add({
			targets: ".animation",
			duration: 1000,
			opacity: 1,
			translateY: "0px",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		// hide other rows and show home rows
		.add({
			targets: [
				".row__one .red-button",
				".row__two .red-button",
				".row__four .red-button",
				".row__five .red-button",
				".row__one .green-button",
				".row__two .green-button",
				".row__four .green-button",
				".row__five .green-button",
				".row__one .yellow-button",
				".row__two .yellow-button",
				".row__four .yellow-button",
				".row__five .yellow-button",
				".row__one .blue-button",
				".row__two .blue-button",
				".row__four .blue-button",
				".row__five .blue-button",
				"[data-key='20']",
				"[data-key='222']",
				"[data-key='93-R']",
			],
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: el.classList.contains("blue-button")
					? blueColor
					: greyColor;
			},
		})
		.add({
			targets: [
				"[data-key='13'] img",
				"[data-key='8'] img",
			],
			opacity: 0,
			complete: ({animatables})=>{
				animatables[0].target.classList.add('none');
				animatables[1].target.classList.add('none');
			}
		})
		.add({
			duration: 2000,
			targets: [".keyboard"],
			opacity: 1,
			translateY: ["0px", "240px"],
		})
		// display block slides
		.add({
			targets: ".slides",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		//  Ñ Key in keyboard
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR2"));
			},

			targets: ['[data-char="Ñ"]'],
			backgroundColor: [
				{
					value: lightRedColor,
					duration: 500,
				},
				{
					value: redColor,
					duration: 500,
					delay: 17000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 17000,
				},
			],
		})

		// Ñ letter
		.add(
			{
				targets: [".slide-6 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "-20%"],
				fontSize: [
					{
						value: "50px",
					},
				],
			},
			"-=18000"
		)
		// Ñ image
		.add({
			targets: ".slide-6 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-6 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-6 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RHHR4"));
				},
				targets: ".slide-6 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-6"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		//  L Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR5"));
			},

			targets: ['[data-char="L"]'],
			backgroundColor: [
				{
					value: lightBlueColor,
					duration: 500,
				},
				{
					value: blueColor,
					duration: 500,
					delay: 14000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 14000,
				},
			],
		})

		.add(
			{
				targets: [".slide-7 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=15000"
		)
		// image
		.add({
			targets: ".slide-7 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-7 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-7 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RHHR7"));
				},
				targets: ".slide-7 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-7"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// K
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR8"));
			},

			targets: ['[data-char="K"]'],
			backgroundColor: [
				{
					value: lightYellowColor,
					duration: 500,
				},
				{
					value: yellowColor,
					duration: 500,
					delay: 19000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 19000,
				},
			],
		})

		.add(
			{
				targets: [".slide-8 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=20000"
		)
		// image
		.add({
			targets: ".slide-8 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-8 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-8 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RHHR10"));
				},
				targets: ".slide-8 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-8"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// J
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR11"));
			},

			targets: ['[data-char="J"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 16000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 16000,
				},
			],
		})

		.add(
			{
				targets: [".slide-9 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=17000"
		)
		// image
		.add({
			targets: ".slide-9 .flex div",
			height: "55px",
			duration: 0,
		})
		.add({
			targets: ".slide-9 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-9 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-9 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add(
			{
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("RHHR13"));
				},
				targets: ".slide-9 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-9"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// H Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR14"));
			},

			targets: ['[data-char="H"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 14000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 14000,
				},
			],
		})

		.add(
			{
				targets: [".slide-10 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=14000"
		)
		// image
		.add({
			targets: ".slide-10 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-10 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-10 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add(
			{
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("RHHR16"));
				},
				targets: ".slide-10 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-10", ".slides"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");

				store.getters.howler.value.fade(
					0.3,
					0,
					3000,
					store.getters.secondary.value
				);
			},
		})
		// remove and hide keyboard
		.add({
			targets: [".keyboard", ".animation"],
			translateY: "500px",
			opacity: [1, 0],
			duration: 3000,

			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})

		// cloud  Right
		.add({
			duration: 6000,
			translateX: ["100%", "0%"],
			targets: ".right-container",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("passive"));

				store.getters.howler.value.fade(
					0,
					0.3,
					5000,
					store.getters.secondary.value
				);

				store.dispatch("primary", store.getters.howler.value.play("RHHR17"));
			},
			endDelay: 3000,
		})

		.add({
			targets: ".right-container",
			opacity: 1,
			duration: 3000,
		})
		.add({
			targets: ".right-container",
			opacity: [1, 0],
			duration: 3000,
			endDelay: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		/** ****************************************************************************************
		 *  Learning Presentation 2                                                                *
		 * *****************************************************************************************
		 */
		// ñ ÑNN
		.add({
			targets: ".learning-presentation",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR18"));
			},
			targets: ".ñ.lesson-img",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 14000,
		})
		.add({
			targets: ".ñ.lesson-img",
			begin: (_anim) => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHHR19-20-21")
				);
			},
			opacity: 1,
			endDelay: 2300,
		})
		// label
		.add({
			targets: ".ñ.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],

			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-6"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// LLL
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR22"));
			},
			targets: ".l.lesson-img",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 9000,
		})
		.add({
			targets: ".l.lesson-img",
			begin: (_anim) => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHHR23-24-25")
				);
			},
			opacity: 1,
			endDelay: 5000,
		})
		// label
		.add({
			targets: ".l.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-7"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// KKK
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR26"));
			},
			targets: ".k.lesson-img",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 11000,
		})
		.add({
			targets: ".k.lesson-img",
			begin: (_anim) => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHHR27-28-29")
				);
			},
			opacity: 1,
			endDelay: 2000,
		})
		// label
		.add({
			targets: ".k.label h2",
			delay: anime.stagger(1000),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],

			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-8"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// JJJ

		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR30"));
			},
			targets: ".j.lesson-img",
			duration: 1000,
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 13000,
		})
		.add({
			targets: ".j.lesson-img",
			begin: (_anim) => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHHR31-32-33")
				);
			},
			opacity: 1,
			endDelay: 1500,
		})
		// label
		.add({
			targets: ".j.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],

			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-9"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// HHH .right-container

		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHHR34"));
			},
			targets: ".h.lesson-img",
			duration: 1000,
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 13000,
		})
		.add({
			targets: ".h.lesson-img",
			begin: (_anim) => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHHR35-36-37")
				);
			},
			opacity: 1,
			endDelay: 1500,
		})
		// label
		.add({
			targets: ".h.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
		})

		.add({
			targets: [".presentation-10", ".learning-presentation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})

		// slide up keyboard
		.add({
			targets: [".animation"],
			translateY: "50px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
			},
		})
		
		// slide up keyboard
		.add({
			targets: [".keyboard"],
			translateY: "0px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			targets: [".animation .key-text-right"],
			easing: "easeInOutSine",
			translateX: "4em",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		}, "-=3000")
		.add({
			complete: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHHR38-39-40-41-42")
				);
			},
		})


		.add({
			targets: [".keyboard", ".animation"],
			opacity: 1,
			delay: 3500,
		})

		.add({
			delay: anime.stagger(1500),
			targets: [
				'[data-char="Ñ"]',
				'[data-char="L"]',
				'[data-char="K"]',
				'[data-char="J"]',
				'[data-char="H"]',
			],
			backgroundColor: [
				{
					value(el) {
						return el.classList.contains("red-button")
							? lightRedColor
							: el.classList.contains("green-button")
							? lightGreenColor
							: el.classList.contains("yellow-button")
							? lightYellowColor
							: lightBlueColor;
					},
					duration: 1000,
				},
				{
					value(el) {
						return el.classList.contains("red-button")
							? redColor
							: el.classList.contains("green-button")
							? greenColor
							: el.classList.contains("yellow-button")
							? yellowColor
							: blueColor;
					},
					duration: 500,
					delay: 1000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1000,
				},
			],
		})
		.add(
			{
				targets: ".key-text-right h2",
				delay: anime.stagger(1500, {
					direction: "reverse",
				}),
				direction: "reverse",
				opacity: [0, 1],
				translateX: [10, 0],
				duration: 1000,
			},
			"-=8500"
		)
		.add({
			targets: ".key-text-right h2",
			delay: 2000,
			opacity: [1, 0],
			duration: 1000,
		})
		// hide animation
		.add({
			targets: [".animation", ".animation .key-text-right"],
			opacity: [1, 0],
			translateY: "500px",
			easing: "easeInOutSine",
			duration: 3000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			endDelay: 1000,
		})

		// ok thumbsup
		.add({
			targets: ".right-like",
			opacity: 1,
			duration: 3000,
			translateX: ["20%", "0%"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("RHHR43"));
				store.getters.howler.value.fade(
					0.3,
					0,
					8000,
					store.getters.secondary.value
				);
			},
			endDelay: 7000,
		})

		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('one_learn_right');

			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/home_row/learn/right.mp3")],
			sprite: {
				active: [0, 252120, true],
				passive: [254000, 548571.4285714285],
				"RHHR1-2": [804000, 6263.900226757414],
				RHHR10: [812000, 2597.5510204082184],
				RHHR11: [816000, 16000.40816326532],
				RHHR13: [834000, 3414.3310657595975],
				RHHR14: [839000, 14661.473922902474],
				RHHR16: [855000, 4806.825396825388],
				RHHR17: [861000, 12504.444444444402],
				RHHR18: [875000, 17245.64625850337],
				"RHHR19-20-21": [894000, 7926.553287981847],
				RHHR2: [903000, 17098.344671201856],
				RHHR22: [922000, 12880.65759637186],
				"RHHR23-24-25": [936000, 11032.925170067983],
				RHHR26: [949000, 14192.834467120178],
				"RHHR27-28-29": [965000, 7203.537414966036],
				RHHR30: [974000, 16721.564625850304],
				"RHHR31-32-33": [992000, 7465.192743764192],
				RHHR34: [1001000, 16528.185941043033],
				"RHHR35-36-37": [1019000, 6954.988662131427],
				"RHHR38-39-40-41-42": [1027000, 11305.21541950111],
				RHHR4: [1040000, 2811.7913832199974],
				RHHR43: [1044000, 7330.92970521534],
				RHHR5: [1053000, 13991.995464852607],
				RHHR7: [1068000, 1687.074829932044],
				RHHR8: [1071000, 19374.55782312918],
			},

			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
