<template>
	<div class="oval-thought-container right-container none">
		<div
			class="right-cloud"
			:style="{ backgroundImage: 'url(' + require('@/assets/images/cloud-right.png') + ')' }"
		>
			<div class="cloud-text">
				<span style="font-size: 0.7em">Historia</span>
				<br />
				<span class="green-text">H&nbsp;</span>
				<span class="green-text">J&nbsp;</span>
				<span class="yellow-text">K&nbsp;</span>
				<span class="blue-text">L&nbsp;</span>
				<span class="red-text">Ñ</span>
			</div>
		</div>
		<div
			class="right-hand-cloud"
			:style="{ backgroundImage: 'url(' + require('@/assets/images/right-hand.png') + ')' }"
		></div>
	</div>
</template>

<script>
export default {
	name: "CloudHistory",
};
</script>

<style scoped>
</style>