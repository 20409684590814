<template>
	<div id="modal-container" class="typing-complete-modal">
		<div class="modal-background">
			<div class="modal h-auto p-4">
				<div class="flex justify-content-center">
					<div class="d-flex justify-content-center my-3">
						<img src="@/assets/images/gold-medal.gif" alt="gold medal"  class="final-medal"/>
					</div>
					<div>
						<p>¡Felicidades! Has completado con éxito el programa.</p>
						<p>Es importante que apliques esta nueva habilidad de ahora en adelante en tu trabajo diario con el PC. - </p>
						<p>No vuelvas a caer en tus antiguos hábitos.</p> 
						<p>Incluso si tienes la sensación de que eres más lento al principio. ¡No es así!</p>
						<p>Debido a que ahora usas los 10 dedos en el lugar correcto,</p> 
						<p>serás automáticamente más rápido ¡y podrás ser mucho, mucho más rápido!</p>
						<p>Recuerda: Puedes usar y practicar todos los entrenos de tecleo,</p> 
						<p>incluyendo el Tecleo Mix, por otros 3 meses.</p>
						<p>¡Que te vaya bien!</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AuthButton from '@/components/AuthButton.vue'


export default {
	components: { AuthButton },
	name: "Congrats",

};
</script>

<style scoped>
	.typing-complete-modal{
		position: relative;
		width: 100%;
		height: 100%;
	}
	

	.modal {
		position: absolute;
		display: flex !important;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin: 0 auto;
		color: #3567a1;
		font-weight: revert;
		line-height: 1.5em;
		font-size: 16px;
		font-weight: 600 !important;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.final-logo{
		height: 70px;
	}
	.final-medal{
		height:200px;
	}
</style>