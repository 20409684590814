<template>
	<header>
		<nav class="navbar navbar-expand-lg navbar-light">
			<SiteLogo />
			
			<button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarColor03"
				aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>

			<div class="navbar-collapse collapse" id="navbarColor03" style="">
				<ul class="navbar-nav ml-auto">

					<li class="nav-item active text-center">
						<WhiteButton to="/about">Sobre nosotros</WhiteButton>
					</li>

					<li class="nav-item text-center">
						<TurqoiseButton :to="pupil ? '/introduction': '/login'">Iniciar sesión</TurqoiseButton>
					</li>
				</ul>
			</div>
		</nav>
	</header>

</template>

<script>
import TurqoiseButton from '../../components/TurqoiseButton.vue'
import WhiteButton from '../../components/WhiteButton.vue'
import SiteLogo from '../../components/SiteLogo.vue'
import { useStore } from 'vuex';

export default {
	components: { SiteLogo, WhiteButton, TurqoiseButton },
	name: "Header",
	setup(){
		const store = useStore();
		return{
			pupil: store.getters.pupil,
		}
	}
};
</script>

<style scoped>
	header {
		background-color: white;
	}
</style>
