<template>
	<div class="key-text-right none">
		<h2 class="red-text text-p">
			P
		</h2>
		<h2 class="blue-text text-o">
			O
		</h2>
		<h2 class="yellow-text text-i">
			I
		</h2>
		<h2 class="green-text text-u">
			U
		</h2>
		<h2 class="green-text text-y">
			Y
		</h2>
	</div>
</template>

<script>
export default {
	name: "KeyText",
};
</script>

<style scoped>
</style>