<template>
	<div class="right-hand-drag none">
		<div class="drag-drop">
			<img src="@/assets/images/right-hand.png" alt="right hand" />
			<div class="wrapper right-hand drop">
				<div class="hand dropzone green-bg" data-dropzone="5">
					<div class="check-label" data-correct="5">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
				</div>
				<div class="hand dropzone green-bg" data-dropzone="4">
					<div class="check-label" data-correct="4">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
				</div>
				<div class="hand dropzone yellow-bg" data-dropzone="3">
					<div class="check-label" data-correct="3">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
				</div>
				<div class="hand dropzone blue-bg" data-dropzone="2">
					<div class="check-label" data-correct="2">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
				</div>
				<div class="hand dropzone red-bg" data-dropzone="1">
					<div class="check-label" data-correct="1">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
				</div>
			</div>
			<div class="wrapper drag">
				<div class="dropzone occupied" data-dropzone="1">
					<div class="draggable">
						P
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable">
						U
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="3">
					<div class="draggable">
						I
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="5">
					<div class="draggable">
						Y
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="2">
					<div class="draggable">
						O
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setupActivityOne } from "@/common/activities";
// import { Draggable } from "@shopify/draggable";
export default {
	name: "First",
	mounted: () => setupActivityOne('wrapper', 'right-hand'),
};
</script>

<style scoped>
</style>