import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import $ from "jquery";
import { windowBlur } from "@/common/helpers";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started("two_practice");
	if(store.getters.sptt.value.two_practice_finished)
		store.getters.animation.value
			.add({
				delay: 500,
				duration: 4190,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RepL2A"));
				},
			});

	else
		store.getters.animation.value
			// TTUR1
			.add({
				delay: 500,
				duration: 12500,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR1"));
				},
			})

			// TTUR2
			.add({
				delay: 500,
				duration: 13500,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR2"));
				},
			});


	store.getters.animation.value
		.add({
			begin: () => {
				window.addEventListener("keypress", keyPressEvent);

				window.removeEventListener("blur", windowBlur);
				// practice started
				store.dispatch("onActivity");
			},
		})

	// if not started / not first time
	// set this condition
	if (!store.getters.sptt.value.two_practice_finished)
		store.getters.animation.value

			// timeline starts playing from here after first music and first round of typing
			// after unas
			// slide down typer
			.add({
				targets: ["#typer-app"],
				translateY: ["0px", "500px"],
				opacity: 0,
				duration: 1000,
				easing: "easeOutSine",
				begin: (anim) => {
					store.dispatch("playing", true);

					window.addEventListener("blur", windowBlur);
				},
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})
			.add({
				targets: [".animation"],
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// slide down keyboard
			.add(
				{
					targets: [".keyboard"],
					opacity: 1,
					translateY: ["-500px", "0px"],
					duration: 1000,
					begin: ({ animatables }) => {
						animatables[0].target.classList.remove("none");
					},
				},
				"-=1000"
			)

			// hide other rows and show uper row and home row only
			.add(
				{
					complete: (anim) => {},
					targets: [
						".row__one .red-button",
						".row__four .red-button",
						".row__five .red-button",
						".row__one .green-button",
						".row__four .green-button",
						".row__five .green-button",
						".row__one .yellow-button",
						".row__four .yellow-button",
						".row__five .yellow-button",
						".row__one .blue-button",
						".row__four .blue-button",
						".row__five .blue-button",
						"[data-key='93-R']",	
					],
					color(el) {
						return el.classList.contains("red-button")
							? redColor
							: el.classList.contains("green-button")
							? greenColor
							: el.classList.contains("yellow-button")
							? yellowColor
							: el.classList.contains("blue-button")
							? blueColor
							: greyColor;
					},
				},
				"-=1500"
			)
			.add({
				targets: [
					"[data-key='13'] img",
					"[data-key='8'] img",
					"[data-char='Q'] .bottom-right",
				],
				opacity: 0,
				complete: ({animatables})=>{
					animatables[0].target.classList.add('none');
					animatables[1].target.classList.add('none');
				}
			}, "-=1500")
	
			// show caps lock , shift left , shift right, i with accent
			.add(
				{
					targets: [
						"[data-key='20']",
						"[data-key='16']",
						"[data-key='222']",
						"[data-key='219']",
						"[data-key='16-R']",
					],
					color: "#ffffff",
				},
				"-=1500"
			)

			// TTUR9
			.add({
				duration: 17000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR9"));
				},
			})

			// animate accent right to Ñ
			.add(
				{
					targets: '[data-key="222"]',
					backgroundColor: [
						{ value: lightRedColor, duration: 500 },
						{ value: redColor, duration: 500, delay: 2000 },
					],
					top: [
						{ value: "0.2em", duration: 500 },
						{ value: "0em", duration: 500, delay: 2000 },
					],
					endDelay: 0,
				},
				"-=8500"
			)

			// animate accent right to P
			.add(
				{
					targets: '[data-key="219"]',
					backgroundColor: [
						{ value: lightRedColor, duration: 500 },
						{ value: redColor, duration: 500, delay: 2000 },
					],
					top: [
						{ value: "0.2em", duration: 500 },
						{ value: "0em", duration: 500, delay: 2000 },
					],
					endDelay: 0,
				},
				"-=5000"
			)

			// slide up keyboard
			.add({
				delay: 1000,
				targets: [".animation", ".keyboard"],
				opacity: 0,
				translateY: ["0px", "-500px"],
				duration: 1000,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
					animatables[1].target.classList.add("none");
				},
			})

			// slide up typer
			.add({
				targets: ["#typer-app"],
				translateY: ["500px", "0px"],
				opacity: 1,
				duration: 3000,
				easing: "easeOutSine",
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
				complete: () => {
					diaPlaying = true;
					diaTyping = true;

					store.dispatch("activity", true);
					store.dispatch("playing", false);
					window.removeEventListener("blur", windowBlur);
				},
			})

			// TTUR10
			.add({
				duration: 33000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR10"));
				},
			})

			// TTUR11
			.add({
				duration: 7100,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR11"));
				},
			})

			// TTUR12
			.add({
				duration: 7000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR12"));
				},
				complete: () => {
					diaPlaying = false;
					if (diaTyping) {
						store.dispatch("onActivity");
					}
				},
			})

			// TTUR13
			.add({
				duration: 13000,
				begin: (anim) => {
					store.dispatch("activity", true);
					store.dispatch("playing", false);

					liaPlaying = true;
					liaTyping = true;

					store.dispatch("primary", store.getters.howler.value.play("TTUR13"));
				},
			})

			// TTUR14
			.add({
				duration: 6000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR14"));
				},
			})

			// TTUR15
			.add({
				duration: 8500,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR15"));
				},
				complete: () => {
					liaPlaying = false;
					if (liaTyping) {
						store.dispatch("onActivity");
					}
				},
			})

			// TTUR16
			.add({
				duration: 2000,
				begin: (anim) => {
					window.addEventListener("blur", windowBlur);

					store.dispatch("activity", false);
					store.dispatch("playing", true);

					store.dispatch("primary", store.getters.howler.value.play("TTUR16"));
				},
			})

			// slide down typer
			.add({
				targets: ["#typer-app"],
				translateY: ["0px", "500px"],
				opacity: 0,
				delay: 1000,
				duration: 1500,
				easing: "easeOutSine",
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})

			// slide down keyboard
			.add({
				targets: [".animation", ".keyboard"],
				opacity: 1,
				delay: 1000,
				translateY: ["-500px", "0px"],
				duration: 1000,
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
					animatables[1].target.classList.remove("none");
				},
			})

			// TTUR17
			// animate left and right shit
			.add({
				duration: 10000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR17"));
				},

				targets: ['[data-key="16"]','[data-key="16-R"]'],
				backgroundColor: [
					{ value: lightRedColor, duration: 1000, delay: 500 },
					{ value: redColor, duration: 500, delay: 7000 },
				],
				top: [
					{ value: "0.2em", duration: 1000 , delay: 500},
					{ value: "0em", duration: 500, delay: 7000 },
				],
			})
			// TTUR18
			.add({
				duration: 9000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR18"));
				},
			})

			// animate bottom row
			.add(
				{
					targets: [".bottom-row"],
					top: [
						{ value: "0.2em", duration: 500 },
						{ value: "0em", duration: 500, delay: 4000 },
					],
				},
				"-=7000"
			)

			// TTUR19
			.add({
				duration: 7000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR19"));
				},
			})

			// animate left shift
			.add(
				{
					targets: '[data-key="16"]',
					backgroundColor: [
						{ value: lightRedColor, duration: 500 },
						{ value: redColor, duration: 500, delay: 1000 },
					],
					top: [
						{ value: "0.2em", duration: 500 },
						{ value: "0em", duration: 500, delay: 1000 },
					],
					duration: 4000,
					endDelay: 0,
				},
				"-=6000"
			)

			// animate right shift
			.add(
				{
					delay: 1000,
					targets: '[data-key="16-R"]',
					backgroundColor: [
						{ value: lightRedColor, duration: 500 },
						{ value: redColor, duration: 500, delay: 1000 },
					],
					top: [
						{ value: "0.2em", duration: 500 },
						{ value: "0em", duration: 500, delay: 1000 },
					],
				},
				"-=4000"
			)

			// slide up keyboard
			.add({
				targets: [".animation", ".keyboard"],
				opacity: 0,
				translateY: ["0px", "-500px"],
				duration: 1000,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
					animatables[1].target.classList.add("none");
				},
			})

			// slide up typer
			.add({
				targets: ["#typer-app"],
				translateY: ["500px", "0px"],
				opacity: 1,
				duration: 3000,
				easing: "easeOutSine",
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// TTUR20
			.add({
				duration: 26000,
				begin: (anim) => {
					store.dispatch("activity", true);
					store.dispatch("playing", false);
					window.removeEventListener("blur", windowBlur);

					diegoPlaying = true;
					diegoTyping = true;

					store.dispatch("primary", store.getters.howler.value.play("TTUR20"));
				},
			})

			// TTUR21
			.add({
				duration: 17100,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR21"));
				},
			})

			// TTUR22
			.add({
				duration: 17000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR22"));
				},
				complete: () => {
					diegoPlaying = false;
					if (diegoTyping) {
						store.dispatch("onActivity");
					}
				},
			})

			// TTUR23
			.add({
				duration: 25000,
				begin: (anim) => {
					store.dispatch("activity", true);
					store.dispatch("playing", false);
					window.removeEventListener("blur", windowBlur);

					juliaPlaying = true;
					juliaTyping = true;
					store.dispatch("primary", store.getters.howler.value.play("TTUR23"));
				},
			})

			// TTUR24
			.add({
				duration: 13000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR24"));
				},
			})

			// TTUR25
			.add({
				duration: 16000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR25"));
				},
				complete: () => {
					juliaPlaying = false;
					if (juliaTyping) {
						store.dispatch("onActivity");
					}
				},
			})

			// slide down typer
			.add({
				targets: ["#typer-app"],
				translateY: ["0px", "500px"],
				opacity: 0,
				delay: 1000,
				duration: 1500,
				easing: "easeOutSine",
				begin: () => {
					window.addEventListener("blur", windowBlur);

					store.dispatch("playing", true);
					store.dispatch("offActivity");
				},
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})

			// slide down keyboard
			.add({
				targets: [".animation", ".keyboard"],
				opacity: 1,
				delay: 1000,
				translateY: ["-500px", "0px"],
				duration: 1000,
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
					animatables[1].target.classList.remove("none");
				},
			})

			// TTUR26
			.add({
				duration: 27100,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR26"));
				},
			})

			// animate caps lock key
			.add(
				{
					delay: 1000,
					targets: '[data-key="20"]',
					backgroundColor: [
						{ value: lightRedColor, duration: 500, delay: 2000 },
						{ value: redColor, duration: 500, delay: 10000 },
					],
					top: [
						{ value: "0.2em", duration: 500, delay: 2000 },
						{ value: "0em", duration: 500, delay: 10000 },
					],
				},
				"-=22000"
			)

			// TTUR27
			.add({
				duration: 8000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR27"));
				},
			})

			// animate accent key with two dots above
			.add(
				{
					delay: 1000,
					targets: '[data-key="222"]',
					backgroundColor: [
						{ value: lightRedColor, duration: 500, delay: 1000 },
						{ value: redColor, duration: 500, delay: 2000 },
					],
					top: [
						{ value: "0.2em", duration: 500, delay: 1000 },
						{ value: "0em", duration: 500, delay: 2000 },
					],
				},
				"-=6000"
			)

			// TTUR28
			.add({
				duration: 4500,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR28"));
				},
			})

			// animate left shift key starts here
			.add(
				{
					targets: '[data-key="16"]',
					backgroundColor: [
						{ value: lightRedColor, duration: 500, delay: 1000 },
					],
					top: [{ value: "0.2em", duration: 500, delay: 1000 }],
				},
				"-=4000"
			)

			// TTUR29
			.add({
				duration: 7000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR29"));
				},
			})

			// animate accent key on right of ñ
			.add({
				targets: '[data-key="222"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 1000 },
				],
			})

			// animate accent key on right of p
			.add({
				endDelay: 4000,
				targets: '[data-key="219"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "0.2em", duration: 300 },
					{ value: "0em", duration: 300, delay: 1500 },
				],
			})

			// TTUR30
			.add({
				duration: 2200,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR30"));
				},
			})

			// stop animation of left shift key here
			.add(
				{
					targets: '[data-key="16"]',
					backgroundColor: [{ value: redColor, duration: 500, delay: 1000 }],
					top: [{ value: "0em", duration: 500, delay: 1000 }],
				},
				"-=2000"
			)

			// TTUR31
			.add({
				duration: 12000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR31"));
				},
			})
			// U Key in keyboard is animated
			.add(
				{
					targets: ['[data-char="U"]'],
					backgroundColor: [
						{ value: lightGreenColor, duration: 500, delay: 2000 },
						{ value: greenColor, duration: 500, delay: 5000 },
					],
					top: [
						{ value: "0.2em", duration: 500, delay: 2000 },
						{ value: "0em", duration: 500, delay: 5000 },
					],
				},
				"-=12000"
			)

			// U with dots slides right to left
			.add(
				{
					targets: [".slide-1"],
					begin: ({ animatables }) => {
						animatables[0].target.classList.remove("none");
					},
				},
				"-=7000"
			)

			.add(
				{
					targets: [".slide-1 .letter"],
					opacity: [0, 1],
					duration: 2000,
					translateX: ["20%", "0%"],
				},
				"-=7000"
			)

			// U with dots slides left to right backward
			.add({
				targets: [".slide-1 .letter"],
				opacity: [1, 0],
				duration: 2000,
				translateX: ["0%", "20%"],
			})

			.add({
				targets: [".slide-1"],
				begin: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})

			// slide up keyboard
			.add({
				targets: [".animation", ".keyboard"],
				opacity: 0,
				translateY: ["0px", "-500px"],
				duration: 1000,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
					animatables[1].target.classList.add("none");
				},
			})

			// slide up typer
			.add({
				targets: ["#typer-app"],
				translateY: ["500px", "0px"],
				opacity: 1,
				duration: 3000,
				easing: "easeOutSine",
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// TTUR32
			.add({
				duration: 8000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR32"));
				},
				complete: () => {
					window.removeEventListener("blur", windowBlur);
					store.dispatch("onActivity");
					// tl.pause();
					// firstTime('/v1/metas/visit',{name: '/user/practice/lesson/2'}).then(data=>console.log(data));
				},
			});

	store.getters.animation.value

		// TTUR33
		.add({
			duration: 4000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTUR33"));
			},
		});

	if(store.getters.sptt.value.two_practice_finished)
		store.getters.animation.value
			// RepL2B
			.add({
				duration: 4650,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RepL2B"));
				},
				complete: () => {
					$(".timeline-controls").addClass('none');
				},
			})
	else
		store.getters.animation.value
			// TTUR34
			.add({
				duration: 9000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR34"));
				},
				complete: () => {
					store.dispatch("next", true);
				},
			})
			// TTUR35
			.add({
				duration: 5000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTUR35"));
				},
			});
	
	store.getters.animation.value
		// Practice stopped
		.add({
			begin: () => {
				// update progress here
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);
				SPTT.finished("two_practice");
			},
		});
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);
	window.removeEventListener("keypress", keyPressEvent);

	store.getters.animation.value.play();

	window.addEventListener("keydown", (e) => {
		if (e.which == 222 || e.which == 219) store.dispatch("accent_down", true);
	});
	window.addEventListener("keypress", (e) => {
		if (e.which == 222 || e.which == 219) store.dispatch("accent_down", true);
	});
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/upper_row/practice.mp3")],
			sprite: {
				RepL2A: [0, 4175.419501133787],
				RepL2B: [6000, 4635.238095238094],
				TTUR1: [12000, 11794.376417233558],
				TTUR10: [25000, 32546.167800453517],
				TTUR11: [59000, 7005.238095238099],
				TTUR12: [68000, 6740.884353741492],
				TTUR13: [76000, 12644.671201814062],
				TTUR14: [90000, 5683.5147392290255],
				TTUR15: [97000, 8393.061224489798],
				TTUR16: [107000, 1982.630385487525],
				TTUR17: [110000, 9384.353741496596],
				TTUR18: [121000, 8304.943310657593],
				TTUR19: [131000, 6564.648526077093],
				TTUR2: [139000, 12520.58956916099],
				TTUR20: [153000, 25135.12471655329],
				TTUR21: [180000, 17072.49433106577],
				TTUR22: [199000, 16698.027210884364],
				TTUR23: [217000, 24320.045351473935],
				TTUR24: [243000, 12931.04308390022],
				TTUR25: [257000, 15640.612244897966],
				TTUR26: [274000, 27051.655328798177],
				TTUR27: [303000, 7776.258503401379],
				TTUR28: [312000, 4361.768707483008],
				TTUR29: [318000, 12079.909297052154],
				TTUR30: [332000, 2136.8253968253725],
				TTUR31: [336000, 11829.591836734722],
				TTUR32: [349000, 7754.217687074856],
				TTUR33: [358000, 3722.925170068038],
				TTUR34: [363000, 8216.825396825414],
				TTUR35: [373000, 4871.315192743737],
				TTUR8: [379000, 11888.526077097482],
				TTUR9: [392000, 16296.780045351454]
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};

var diaPlaying = false,
	diaTyping = false,
	liaPlaying = false,
	liaTyping = false,
	diegoPlaying = false,
	diegoTyping = false,
	juliaPlaying = false,
	juliaTyping = false;

export const setupPractice = () => {
	//ññn
	store.dispatch(
		"text",
		"aqa.sws.ded.frf.ftf.ñpñ.lol.kik.juj.jyj.dado.dedo.jefe.joya.lata.lujo.aire.arte.ñora.uñas.día.allí.Diego.Julia.el gato gris.la foto del faro.idea.isla.wifi.osos.que.pila.sopa.rato.surf.yate.tres kilos de kiwis.a ella le gusta el yoga"
	);
	store.dispatch(
		"formatted_text",
		store.getters.text.value.split(".").join(" ")
	);
	store.dispatch(
		"typing_text",
		store.getters.text.value
			.split(".")
			.map((word) => (word + " ").repeat(3))
			.join("")
			.trimRight()
	);

	$("#text").text(store.getters.formatted_text.value);
	$("#typing-text").text(
		store.getters.typing_text.value.slice(
			store.getters.curr_pointer.value,
			store.getters.curr_pointer.value + 50
		)
	);
};

export const keyPressEvent = function(evt) {
	if (!store.getters.activity.value) return;

	// after unas 3 times , show animation for dia
	if (
		!store.getters.sptt.value.two_practice_finished &&
		store.getters.word_count.value / 3 == 20 &&
		store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
	) {
		//uñas 3 times done show popup
		store.dispatch("offActivity");
	}

	//now dia is typed 3 times
	else if (
		!store.getters.sptt.value.two_practice_finished &&
		store.getters.word_count.value / 3 == 21 &&
		store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
	) {
		diaTyping = false;
		if (!diaPlaying) {
			store.dispatch("offActivity");
		} else {
			store.dispatch("activity", false);
			store.dispatch("playing", true);
		}
	}
	// now alli is typed 3 times
	else if (
		!store.getters.sptt.value.two_practice_finished &&
		store.getters.word_count.value / 3 == 22 &&
		store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
	) {
		liaTyping = false;
		if (!liaPlaying) {
			store.dispatch("offActivity");
		} else {
			store.dispatch("playing", true);
			store.dispatch("activity", false);
		}
	}

	//now Diego is typed 3 times
	else if (
		!store.getters.sptt.value.two_practice_finished &&
		store.getters.word_count.value / 3 == 23 &&
		store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
	) {
		diegoTyping = false;
		if (!diegoPlaying) {
			store.dispatch("offActivity");
		} else {
			store.dispatch("playing", true);

			store.dispatch("activity", false);
		}
	}
	// now Julia is typed 3 times
	else if (
		!store.getters.sptt.value.two_practice_finished &&
		store.getters.word_count.value / 3 == 24 &&
		store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
	) {
		juliaTyping = false;
		if (!juliaPlaying) {
			store.dispatch("offActivity");
		} else {
			store.dispatch("playing", true);

			store.dispatch("activity", false);
		}
	}

	// highlighting text finished
	if (
		store.getters.typing_text.value.length ==
		store.getters.curr_pointer.value + 1
	) {
		window.addEventListener("blur", windowBlur);
		store.dispatch("offActivity");
		$("#text").html(
			'<span class="green">' + store.getters.formatted_text.value + "</span>"
		);
		$("#typing-text").addClass("none");
	} else
		$("#text").html(
			'<span class="green">' +
				store.getters.formatted_text.value
					.split(" ")
					.slice(0, store.getters.word_count.value / 3)
					.join(" ") +
				"</span> <span>" +
				store.getters.formatted_text.value
					.split(" ")
					.slice(store.getters.word_count.value / 3)
					.join(" ") +
				"</span>"
		);

	if (
		String.fromCharCode(evt.charCode) == "í" ||
		(!store.getters.accent_down.value &&
			store.getters.typing_text.value[store.getters.curr_pointer.value] ===
				evt.key) ||
		(store.getters.accent_down.value &&
			evt.key == "i" &&
			store.getters.typing_text.value[store.getters.curr_pointer.value] == "í")
	) {
		store.dispatch("curr_pointer", store.getters.curr_pointer.value + 1);
		if (evt.shiftKey || store.getters.accent_down.value)
			store.dispatch("strokes_count", store.getters.strokes_count.value + 2);
		else store.dispatch("strokes_count", store.getters.strokes_count.value + 1);

		if (
			store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
		) {
			store.dispatch("word_count", store.getters.word_count.value + 1);
		}
		$("#progress").css(
			"width",
			(store.getters.curr_pointer.value /
				store.getters.typing_text.value.length) *
				600
		);
		$("#wordcount").text(Math.floor(store.getters.strokes_count.value / 5));
		// highlighting typing row
		$("#typing-text").html(
			'<span class="green">' +
				store.getters.typing_text.value
					.slice(0, store.getters.curr_pointer.value)
					.slice(-10) +
				"</span>" +
				"<span class='underline'>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value,
					store.getters.curr_pointer.value + 1
				) +
				"</span>" +
				"<span>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value + 1,
					store.getters.curr_pointer.value + 50
				) +
				"</span>"
		);
	} else {
		if (
			store.getters.curr_pointer.value <
			store.getters.typing_text.value.length - 1
		)
			$("#typing-text").html(
				'<span class="green">' +
					store.getters.typing_text.value
						.slice(0, store.getters.curr_pointer.value)
						.slice(-10) +
					"</span>" +
					'<span class="red">' +
					store.getters.typing_text.value[store.getters.curr_pointer.value] +
					"</span><span>" +
					store.getters.typing_text.value.slice(
						store.getters.curr_pointer.value + 1,
						store.getters.curr_pointer.value + 50
					) +
					"</span>"
			);
	}
	if (store.getters.accent_down.value) store.dispatch("accent_down", false);
};
