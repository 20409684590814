import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import { windowBlur } from '@/common/helpers'
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('two_learn_both');
	
	store.getters.animation.value
		.add({
			targets: ".animation",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		.add({
			targets: ".animation",
			opacity: 1,
			translateY: "0px",
		})
		.add({
			duration: 3000,
			targets: ".keyboard",
			opacity: 1,
			translateY: ["-300px", "0px"],
		})
		// hide other rows and show uper row and home row only
		.add(
			{
				targets: [
					".row__one .red-button",
					".row__four .red-button",
					".row__five .red-button",
					".row__one .green-button",
					".row__four .green-button",
					".row__five .green-button",
					".row__one .yellow-button",
					".row__four .yellow-button",
					".row__five .yellow-button",
					".row__one .blue-button",
					".row__four .blue-button",
					".row__five .blue-button",
				],
				color(el) {
					return el.classList.contains("red-button")
						? redColor
						: el.classList.contains("green-button")
						? greenColor
						: el.classList.contains("yellow-button")
						? yellowColor
						: blueColor;
				},
			},
			"-=3000"
		)
		// show caps lock , shift left , shift right, i with accent
		.add(
			{
				targets: [
					"[data-key='20']",
					"[data-key='16']",
					"[data-key='222']",
					"[data-key='16-R']",
				],
				color: "#ffffff",
			},
			"-=3000"
		)

		.add({
			// hands in Ñ
			duration: 4000,
			targets: ".hand-container",
			opacity: 1,
			translateY: [
				{
					value: "200%",
					duration: 500,
				},
				{
					value: "0",
					duration: 2000,
					delay: 1000,
				},
			],
		})
		.add({
			duration: 14000,
			target: ".green-button",
			backgroundColor: greenColor,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR1"));
			},
			endDelay: 7500,
		})

		// one by one animate
		// animate Q
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR2-0"));
			},
			targets: '[data-char="Q"]',
			backgroundColor: [
				{ value: lightRedColor, duration: 500, delay: 4000 },
				{ value: redColor, duration: 500, delay: 4000 },
			],
			top: [
				{ value: "0.2em", duration: 500, delay: 4000 },
				{ value: "0em", duration: 500, delay: 4000 },
			],
			endDelay: 0,
		})
		// animate W
		.add({
			targets: '[data-char="W"]',
			backgroundColor: [
				{ value: lightBlueColor, duration: 500, delay: 1000 },
				{ value: blueColor, duration: 500, delay: 3000 },
			],
			top: [
				{ value: "0.2em", duration: 500, delay: 1000 },
				{ value: "0em", duration: 500, delay: 3000 },
			],
		})
		// animate E
		.add({
			targets: '[data-char="E"]',
			backgroundColor: [
				{ value: lightYellowColor, duration: 300 },
				{ value: yellowColor, duration: 300, delay: 3500 },
			],
			top: [
				{ value: "0.2em", duration: 300 },
				{ value: "0em", duration: 300, delay: 3500 },
			],
		})
		// animate R
		.add({
			targets: ['[data-char="R"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 300 },
				{ value: greenColor, duration: 300, delay: 1500 },
			],
			top: [
				{ value: "0.2em", duration: 300 },
				{ value: "0em", duration: 300, delay: 1500 },
			],
		})
		// animate T
		.add({
			targets: ['[data-char="T"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 300 },
				{ value: greenColor, duration: 300, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 2000 },
			],
			endDelay: 3000,
		})

		// delay for music LHUR2-1
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR2-1"));
			},
			duration: 17000,
		})

		// delay for music LHUR2-2
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR2-2"));
			},
			duration: 3000,
		})

		// animate P
		.add({
			targets: '[data-char="P"]',
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 4000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 4000 },
			],
		})

		// animate O
		.add({
			targets: '[data-char="O"]',
			backgroundColor: [
				{ value: lightBlueColor, duration: 500, delay: 1000 },
				{ value: blueColor, duration: 500, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 500, delay: 1000 },
				{ value: "0em", duration: 500, delay: 2000 },
			],
		})

		// animate I
		.add({
			targets: '[data-char="I"]',
			backgroundColor: [
				{ value: lightYellowColor, duration: 300 },
				{ value: yellowColor, duration: 300, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 300 },
				{ value: "0em", duration: 300, delay: 2000 },
			],
		})

		// animate U
		.add({
			targets: ['[data-char="U"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 300, delay: 500 },
				{ value: greenColor, duration: 300, delay: 1500 },
			],
			top: [
				{ value: "0.2em", duration: 300, delay: 500 },
				{ value: "0em", duration: 300, delay: 1500 },
			],
		})

		// animate Y
		.add({
			targets: ['[data-char="Y"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 300 },
				{ value: greenColor, duration: 300, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 300 },
				{ value: "0em", duration: 300, delay: 2000 },
			],
		})

		// delay for music LHUR2-3
		.add({
			delay: 1000,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR2-3"));
			},
			duration: 1000,
		})

		// delay for music LHUR2-4
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR2-4"));
			},
			duration: 6500,
		})

		// delay for music LHUR2-5
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR2-5"));
			},
			duration: 10000,
		})

		.add({
			// remove hands
			targets: ".hand-container",
			opacity: "0",
			easing: "easeInOutSine",
			duration: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('two_learn_both');

			},
		});
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/upper_row/learn/both.mp3")],
			sprite: {
				active: [0, 252120, true],
				LHUR1: [254000, 12918.390022675738],
				"LHUR2-0": [268000, 21440.11337868483],
				"LHUR2-1": [291000, 15524.308390022668],
				"LHUR2-2": [308000, 19266.46258503399],
				"LHUR2-3": [329000, 926.2811791383001],
				"LHUR2-4": [331000, 5594.693877551037],
				"LHUR2-5": [338000, 9830.839002267567],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
