<template>
	<div id="typer-app" class="main">
		<div id="typing-text"></div>
		<div id="text"></div>
		<div class="count">
			Palabras tecleadas:&nbsp;
			<div id="wordcount"></div>
		</div>
		<div class="progress-container">
			<span class="progress-labels"> INICIO </span>
			<div class="progress-wrapper">
				<div id="progress"></div>
			</div>
			<span class="progress-labels"> META </span>
		</div>
	</div>
</template>

<script>

import { setupPractice } from "@/assets/js/mix";

export default {
	name: "Typer",
	mounted: setupPractice,

};
</script>

<style>
	@import url('https://fonts.googleapis.com/css2?family=Patrick+Hand&display=swap');

	.red {
		color: #ff2e2e;
		background-color: transparent;
		text-decoration: underline;
	}

	#typer-app {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	#typer-app div{
		max-width: 100%;
	}

	#typing-text {
		line-height: 2em;
		letter-spacing: 3px;
		font-size: 2em;
		color: #3567a1;
		text-align: center;
		font-weight: 600;
		overflow: hidden;
		height: 55px;
	}

	.green {
		color: #01ffa1 !important;
		background-color: #71b3ff;
	}

	#text {
		margin: 10px;
		padding: 25px;
		border-radius: 0.25rem;
		line-height: 1.6em;
		background-color: #cfe5ff;
		color: #3567a1;
		letter-spacing: 2px;
		font-size: 1.5em;
		text-decoration: underline;
		text-align: justify;
	}

	#progress {
		height: 12px;
		background-color: #01ff01;
		width: 0px;
		border-radius: 25px;
	}

	.progress-container {
		display: flex;
		align-items: center;
	}

	.progress-wrapper {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		width: 600px;
		height: 18px;
		background-color: #efefef;
		border-radius: 15px;
		border: 2px solid #aaa;
		z-index: 1;
	}

	.underline {
		text-decoration: underline;
	}

	.progress-container img {
		width: 70px;
		box-sizing: content-box;
		margin: -10px 5px 0px 5px;
		border-width: 2px;
		border-style: solid;
		padding: 1px;
		border-radius: 50%;
	}

	
	.progress-labels {
		background-color: turquoise;
		color: white;
		padding: 0 0.3em;
		margin: 0em 0.3em;
		border-radius: 0.25rem;
		font-family: 'Patrick Hand', cursive;
		font-size: 2em;
	}

	.count {
		margin: 0 auto;
		color: #455358;
		display: flex;
	}

	audio {
		display: none;
	}
</style>