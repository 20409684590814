<template>
	<router-link class="btn my-2 my-sm-0">
		<slot />
	</router-link>
</template>

<script>
export default {
	name: "WhiteButton"
}
</script>

<style scoped>
	.btn{
		background-color: transparent !important;
		color: turquoise !important;
		font-weight: bold;
		border-style: none;
		text-shadow: none;
	}
	.btn:target,
	.btn:hover {
		background-color: #41E0D0;
		box-shadow: 2px 4px 6px turquoise;
	}
</style>