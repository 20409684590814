<template>
	<div class="typer-container">
		<Loader v-if="loading" />
		<StartButton v-if="start" @click="startLesson" />
		<Typer v-if="!(start || loading || next)"/>
		<Congrats v-if="next"/>
	</div>
</template>

<script>
import AuthButton from '@/components/AuthButton.vue'
import Congrats from './Congrats.vue'
import Typer from './Typer.vue'
import StartButton from "@/components/StartButton.vue";
import Loader from "@/components/Loader.vue";

import { setupLesson, startLesson, keyPressEvent } from "@/assets/js/home_row/practice";

import { onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";


export default {
	name: "OnePractice",
	components: { Loader, StartButton, Typer, Congrats, AuthButton},
	setup() {
		const store = useStore();

		setupLesson();

		onMounted(()=>{
			store.dispatch('loading',true);
			store.dispatch('start',false);
			store.dispatch('next',false);
		});
		onBeforeUnmount(() => {
			window.removeEventListener('keypress',keyPressEvent)
			store.dispatch("pauseAnimation");
			store.dispatch("reset");
		});

		return {
			loading: store.getters.loading,
			start: store.getters.start,
			next: store.getters.next,
			startLesson,
		};
	},
};
</script>

<style scoped>
	.typer-container{
		height: 100%;
		background-color:#f4f4f4;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>