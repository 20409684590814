<template>
	<div class="keyboard__row row__two">
		<div class="key--bottom-left red-button key--word key-tab" data-key="9"></div>
		<div class="key--double red-button upper-row" data-char="Q">
			<div class="center-letter">Q</div>
			<div class="bottom-right">@</div>
		</div>
		<!-- <div class="key--letter red-button upper-row" data-char="Q">Q</div> -->
		<div class="key--letter blue-button upper-row" data-char="W">W</div>
		<div class="key--letter yellow-button upper-row" data-char="E">E</div>
		<div class="key--letter green-button upper-row" data-char="R">R</div>
		<div class="key--letter green-button upper-row" data-char="T">T</div>
		<div class="key--letter green-button upper-row" data-char="Y">Y</div>
		<div class="key--letter green-button upper-row" data-char="U">U</div>
		<div class="key--letter yellow-button upper-row" data-char="I">I</div>
		<div class="key--letter blue-button upper-row" data-char="O">O</div>
		<div class="key--letter red-button upper-row" data-char="P">P</div>
		<div class="key--double red-button" data-key="219" data-char="{[">
			<div>..</div>
			<div>/</div>
		</div>
		<div class="key--double red-button" data-key="221" data-char="}]"></div>
		<div class="key--word red-button key-enter-head " data-key="13">
			<img src="@/assets/images/enter.png" style="height:50px;"/>
		</div>
	</div>

</template>

<script>
export default {
	name: "RowTwo",
};
</script>

<style scoped>
</style>