<template>
	<div class="alert alert-danger" role="alert">
		<slot/>
	</div>
</template>

<script>
export default {
	name: "GuestErrors"
}
</script>

<style scoped>
	.alert{
		font-size: 12px;
	}
</style>