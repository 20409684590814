import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import { windowBlur } from "@/common/helpers";
import SPTT from "@/api/SPTT";


export const startLesson = () => {
	SPTT.started('three_learn_both');

	store.getters.animation.value
		.add({
			targets: ".animation",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		.add({
			targets: ".animation",
			opacity: 1,
			translateY: "0px",
		})
		.add({
			duration: 3000,
			targets: ".keyboard",
			opacity: 1,
			translateY: ["-300px", "0px"],
		})

		// Show hands container
		.add({
			duration: 4000,
			targets: ".hand-container",
			opacity: 1,
			translateY: [
				{ value: "500px", duration: 500 },
				{ value: "0", duration: 2000, delay: 1000 },
			],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			duration: 8000,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR1"));
			},
		})

		// one by one animate
		//  36000
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR2"));
			},
			duration: 11000,
		})
		// animate Z

		.add({
			targets: '[data-char="Z"]',
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 2000 },
			],
		})
		// animate X
		.add({
			targets: '[data-char="X"]',
			backgroundColor: [
				{ value: lightBlueColor, duration: 500 },
				{ value: blueColor, duration: 500, delay: 1500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1500 },
			],
		})
		// animate C
		.add({
			targets: '[data-char="C"]',
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 1500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1500 },
			],
		})

		// animate V and B
		.add({
			targets: ['[data-char="V"]', '[data-char="B"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 2000 },
			],
			endDelay: 2000,
		})
		// 15 seconds passed
		// animate hyphen (-)
		.add({
			targets: '[data-char="-"]',
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 2500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 2500 },
			],
		})

		// animate .
		.add({
			targets: '[data-char="."]',
			backgroundColor: [
				{ value: lightBlueColor, duration: 500 },
				{ value: blueColor, duration: 500, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 2000 },
			],
		})

		// animate ,
		.add({
			targets: '[data-char=","]',
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 2000 },
			],
		})

		// animate M and N
		.add({
			targets: ['[data-char="M"]', '[data-char="N"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 2000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 2000 },
			],
			endDelay: 1000,
		})

		.add({
			// remove hands
			targets: ".hand-container",
			opacity: "0",
			easing: "easeInOutSine",
			duration: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('three_learn_both');
			},
		});
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/bottom_row/learn/both.mp3")],
			sprite: {
				active: [0, 252120, true],
				LHBR1: [254000, 7700.4761904761945],
				LHBR2: [263000, 36040.11337868479],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
