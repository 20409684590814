<template>
	<main style="margin: 3em">
		<div class="row my-3">
			<div class="col-8 offset-2">
				<h2 class="text-left">Condiciones de servicio</h2>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">PANORAMA GENERAL</div>
				<p>
					Este sitio web es operado por EDconsulting. En todo el
					sitio, los términos "nosotros", "nos" y "nuestro" se
					refieren a EDconsulting. EDconsulting ofrece este sitio web,
					incluyendo toda la información, herramientas y servicios
					disponibles en este sitio para usted, el usuario,
					condicionado a la aceptación de todos los términos,
					condiciones, políticas y avisos aquí establecidos.
				</p>
				<p>
					Al visitar nuestro sitio y/o comprar algo de nosotros, usted
					participa en nuestro "Servicio" y acepta estar obligado por
					los siguientes términos y condiciones ("Términos de
					Servicio", "Términos"), incluyendo aquellos términos y
					condiciones y políticas adicionales a los que se hace
					referencia aquí y/o que están disponibles por medio de un
					hipervínculo. Estos Términos de Servicio se aplican a todos
					los usuarios del sitio, incluyendo sin limitación a los
					usuarios que son navegadores, vendedores, clientes,
					comerciantes y/o contribuyentes de contenido.
				</p>
				<p>
					Por favor, lea estos Términos de Servicio cuidadosamente
					antes de acceder o usar nuestro sitio web. Al acceder o
					utilizar cualquier parte del sitio, usted acepta estar
					obligado por estos Términos de Servicio. Si no está de
					acuerdo con todos los términos y condiciones de este
					acuerdo, entonces no podrá acceder al sitio web ni utilizar
					ningún servicio. Si estos Términos de servicio se consideran
					una oferta, la aceptación se limita expresamente a estos
					Términos de servicio.
				</p>
				<p>
					Cualquier nueva característica o herramienta que se añada a
					la tienda actual también estará sujeta a los Términos de
					Servicio. Puedes revisar la versión más actualizada de los
					Términos de Servicio en cualquier momento en esta página.
					Nos reservamos el derecho de actualizar, cambiar o sustituir
					cualquier parte de estos Términos de servicio mediante la
					publicación de actualizaciones y/o cambios en nuestro sitio
					web. Es su responsabilidad revisar esta página
					periódicamente para conocer los cambios. El uso o acceso
					continuo al sitio web después de la publicación de cualquier
					cambio constituye la aceptación de dichos cambios.
				</p>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 1 -TÉRMINOS DEL SITIO WEB</div>
				<p>
					Al aceptar estas Condiciones de servicio, usted declara que
					tiene al menos la mayoría de edad en su estado o provincia
					de residencia, o que es mayor de edad en su estado o
					provincia de residencia y que nos ha dado su consentimiento
					para permitir que cualquiera de sus dependientes menores de
					edad utilice este sitio.
				</p>
				<p>
					No puede utilizar nuestros productos para ningún propósito
					ilegal o no autorizado ni tampoco puede, en el uso del
					Servicio, violar ninguna ley en su jurisdicción (incluyendo,
					pero no limitándose a, las leyes de derechos de autor).
				</p>
				<p>
					No debes transmitir ningún gusano o virus o ningún código de
					naturaleza destructiva.
				</p>
				<p>
					El incumplimiento o la violación de cualquiera de los
					Términos resultará en la terminación inmediata de sus
					Servicios.
				</p>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 2 - CONDICIONES GENERALES</div>
				<p>
					Nos reservamos el derecho de rechazar el servicio a
					cualquiera por cualquier razón y en cualquier momento.
				</p>
				<p>
					Usted entiende que su contenido (sin incluir la información
					de la tarjeta de crédito), puede ser transferido sin cifrar
					e implicar (a) transmisiones a través de varias redes; y (b)
					cambios para ajustarse y adaptarse a los requisitos técnicos
					de las redes o dispositivos de conexión. La información de
					la tarjeta de crédito siempre se codifica durante la
					transferencia a través de las redes.
				</p>
				<p>
					Usted se compromete a no reproducir, duplicar, copiar,
					vender, revender o explotar ninguna parte del Servicio, el
					uso del Servicio, o el acceso al Servicio o a cualquier
					contacto en el sitio web a través del cual se proporciona el
					servicio, sin el permiso expreso por escrito de nosotros.
				</p>
				<p>
					Los encabezados utilizados en este acuerdo se incluyen sólo
					por conveniencia y no limitarán o afectarán de otro modo
					estos Términos.
				</p>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 3 - EXACTITUD, INTEGRIDAD Y OPORTUNIDAD DE LA
					INFORMACIÓN
				</div>
				<p>
					No somos responsables si la información disponible en este
					sitio no es exacta, completa o actual. El material de este
					sitio se proporciona sólo como información general y no debe
					ser utilizado como la única base para tomar decisiones sin
					consultar fuentes de información primarias, más precisas,
					más completas o más oportunas. Cualquier confianza en el
					material de este sitio es bajo su propio riesgo.
				</p>
				<p>
					Este sitio puede contener cierta información histórica. La
					información histórica, necesariamente, no es actual y se
					proporciona sólo para su referencia. Nos reservamos el
					derecho de modificar el contenido de este sitio en cualquier
					momento, pero no tenemos la obligación de actualizar ninguna
					información en nuestro sitio. Usted está de acuerdo en que
					es su responsabilidad monitorear los cambios en nuestro
					sitio.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 4 - MODIFICACIONES DEL SERVICIO Y PRECIOS (si
					procede)
				</div>
				<p>
					Los precios de nuestros productos están sujetos a cambios
					sin previo aviso (si procede).
				</p>
				<p>
					Nos reservamos el derecho de modificar o interrumpir el
					Servicio (o cualquier parte o contenido del mismo) en
					cualquier momento y sin previo aviso.
				</p>
				<p>
					No seremos responsables ante usted ni ante ningún tercero
					por ninguna modificación, cambio de precio, suspensión o
					interrupción del Servicio.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 5 - PRODUCTOS O SERVICIOS (si procede)
				</div>
				<p>
					Ciertos productos o servicios pueden estar disponibles
					exclusivamente en línea a través del sitio web. Estos
					productos o servicios pueden tener cantidades limitadas y
					están sujetos a devolución o cambio sólo de acuerdo con
					nuestra Política de Devolución.
				</p>
				<p>
					Hemos hecho todo lo posible para mostrar con la mayor
					precisión posible los colores e imágenes de nuestros
					productos que aparecen en la tienda. No podemos garantizar
					que el monitor de su computadora muestre cualquier color con
					exactitud.
				</p>
				<p>
					Nos reservamos el derecho, pero no estamos obligados, a
					limitar las ventas de nuestros productos o servicios a
					cualquier persona, región geográfica o jurisdicción. Podemos
					ejercer este derecho caso por caso. Nos reservamos el
					derecho de limitar las cantidades de cualquier producto o
					servicio que ofrezcamos. Todas las descripciones de los
					productos o los precios de los productos están sujetos a
					cambios en cualquier momento sin previo aviso, a nuestra
					entera discreción. Nos reservamos el derecho de interrumpir
					cualquier producto en cualquier momento. Cualquier oferta de
					cualquier producto o servicio hecha en este sitio es nula
					donde esté prohibida.
				</p>
				<p>
					No garantizamos que la calidad de los productos, servicios,
					información u otro material comprado u obtenido por usted
					satisfaga sus expectativas, o que se corrija cualquier error
					en el Servicio
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 6 - EXACTITUD DE LA INFORMACIÓN DE FACTURACIÓN Y DE
					LA CUENTA
				</div>
				<p>
					Nos reservamos el derecho de rechazar cualquier pedido que
					nos haga. Podemos, a nuestra única discreción, limitar o
					cancelar las cantidades compradas por persona, por hogar o
					por pedido. Estas restricciones pueden incluir pedidos
					realizados por o bajo la misma cuenta de cliente, la misma
					tarjeta de crédito, y/o pedidos que utilicen la misma
					dirección de facturación y/o envío. En caso de que
					modifiquemos o cancelemos un pedido, podemos intentar
					notificárselo poniéndonos en contacto con la dirección de
					correo electrónico y/o la dirección o el número de teléfono
					de facturación proporcionados en el momento en que se
					realizó el pedido. Nos reservamos el derecho de limitar o
					prohibir los pedidos que, a nuestro juicio, parezcan ser
					realizados por comerciantes, revendedores o distribuidores.
				</p>
				<p>
					Usted se compromete a proporcionar información de compra y
					de cuenta actual, completa y exacta para todas las compras
					realizadas en nuestra tienda. Usted acepta actualizar
					rápidamente su cuenta y otra información, incluyendo su
					dirección de correo electrónico y los números y fechas de
					vencimiento de su tarjeta de crédito, para que podamos
					completar sus transacciones y contactarlo cuando sea
					necesario.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 7 - HERRAMIENTAS OPCIONALES</div>
				<p>
					Podemos proporcionarle acceso a herramientas de terceros
					sobre las que no controlamos ni tenemos ningún control ni
					entrada.
				</p>
				<p>
					Usted reconoce y acepta que proporcionamos acceso a dichas
					herramientas "tal cual" y "según disponibilidad" sin ninguna
					garantía, representación o condición de ningún tipo y sin
					ningún tipo de respaldo. No tendremos responsabilidad alguna
					que surja o esté relacionada con su uso de las herramientas
					opcionales de terceros.
				</p>
				<p>
					Cualquier uso que haga de las herramientas opcionales
					ofrecidas a través del sitio es enteramente a su propio
					riesgo y discreción y debe asegurarse de que está
					familiarizado con los términos en los que las herramientas
					son proporcionadas por el proveedor o proveedores de
					terceros relevantes y de que los aprueba.
				</p>
				<p>
					También podemos, en el futuro, ofrecer nuevos servicios y/o
					características a través del sitio web (incluyendo el
					lanzamiento de nuevas herramientas y recursos). Tales nuevas
					características y/o servicios también estarán sujetos a
					estos Términos de Servicio.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 8 - ENLACES DE TERCEROS</div>
				<p>
					Ciertos contenidos, productos y servicios disponibles a
					través de nuestro Servicio pueden incluir materiales de
					terceros.
				</p>
				<p>
					Los enlaces de terceros en este sitio pueden dirigirle a
					sitios web de terceros que no están afiliados a nosotros. No
					somos responsables de examinar o evaluar el contenido o la
					exactitud y no garantizamos ni tendremos ninguna
					responsabilidad por los materiales o sitios web de terceros,
					o por cualquier otro material, producto o servicio de
					terceros.
				</p>
				<p>
					No somos responsables de ningún daño o perjuicio relacionado
					con la compra o el uso de bienes, servicios, recursos,
					contenidos o cualquier otra transacción realizada en
					relación con los sitios web de terceros. Por favor, revise
					cuidadosamente las políticas y prácticas de los terceros y
					asegúrese de que las entiende antes de realizar cualquier
					transacción. Las quejas, reclamaciones, inquietudes o
					preguntas relacionadas con los productos de terceros deben
					dirigirse al tercero.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 9 - COMENTARIOS DE LOS USUARIOS, RETROALIMENTACIÓN Y
					OTRAS PRESENTACIONES
				</div>
				<p>
					Si, a petición nuestra, envía determinadas presentaciones
					específicas (por ejemplo, participaciones en concursos) o
					si, sin nuestra solicitud, envía ideas creativas,
					sugerencias, propuestas, planes u otros materiales, ya sea
					en línea, por correo electrónico, por correo postal o de
					otro modo (colectivamente, "comentarios"), acepta que
					podamos, en cualquier momento, sin restricciones, editar,
					copiar, publicar, distribuir, traducir y utilizar de otro
					modo en cualquier medio los comentarios que nos envíe. No
					estamos ni estaremos obligados (1) a mantener ningún
					comentario en secreto; (2) a pagar una compensación por
					cualquier comentario; o (3) a responder a cualquier
					comentario.
				</p>
				<p>
					Podemos, pero no tenemos la obligación de, monitorear,
					editar o eliminar el contenido que determinemos, a nuestra
					entera discreción, que sea ilegal, ofensivo, amenazador,
					calumnioso, difamatorio, pornográfico, obsceno o de alguna
					otra manera objetable, o que viole la propiedad intelectual
					de alguna de las partes o estos Términos de Servicio.
				</p>
				<p>
					Usted acepta que sus comentarios no violarán ningún derecho
					de terceros, incluyendo los derechos de autor, marca
					registrada, privacidad, personalidad u otro derecho personal
					o de propiedad. Además, acepta que sus comentarios no
					contendrán material difamatorio o de otro modo ilegal,
					abusivo u obsceno, ni tampoco contendrán ningún virus
					informático u otro tipo de malware que pueda afectar de
					algún modo al funcionamiento del Servicio o de cualquier
					sitio web relacionado. No podrá utilizar una dirección de
					correo electrónico falsa, fingir ser otra persona que no sea
					usted, ni engañarnos de ninguna otra manera a nosotros o a
					terceros en cuanto al origen de los comentarios. Usted es el
					único responsable de cualquier comentario que haga y de su
					exactitud. No nos hacemos responsables ni asumimos ninguna
					responsabilidad por los comentarios publicados por usted o
					por terceros.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 10 - INFORMACIÓN PERSONAL</div>
				<p>
					El envío de información personal a través de la tienda se
					rige por nuestra Política de Privacidad. Para ver nuestra
					Política de Privacidad.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 11 - ERRORES, INEXACTITUDES Y OMISIONES
				</div>
				<p>
					Ocasionalmente puede haber información en nuestro sitio o en
					el Servicio que contenga errores tipográficos, inexactitudes
					u omisiones que pueden estar relacionadas con las
					descripciones de los productos, los precios, las
					promociones, las ofertas, los gastos de envío de los
					productos, los tiempos de tránsito y la disponibilidad. Nos
					reservamos el derecho de corregir cualquier error,
					inexactitud u omisión, y de cambiar o actualizar la
					información o cancelar los pedidos si cualquier información
					del Servicio o de cualquier sitio web relacionado es
					inexacta en cualquier momento sin previo aviso (incluso
					después de que haya enviado su pedido).
				</p>
				<p>
					No asumimos ninguna obligación de actualizar, modificar o
					aclarar la información del Servicio o de cualquier sitio web
					relacionado, incluida, entre otras cosas, la información
					sobre precios, excepto cuando lo exija la ley. No se debe
					considerar que ninguna fecha de actualización o
					actualización especificada aplicada en el Servicio o en
					cualquier sitio web relacionado, indique que toda la
					información del Servicio o de cualquier sitio web
					relacionado ha sido modificada o actualizada.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 12 - USOS PROHIBIDOS</div>
				<p>
					Además de las demás prohibiciones establecidas en las
					Condiciones de servicio, se le prohíbe utilizar el sitio o
					su contenido: (a) para cualquier propósito ilegal; (b) para
					solicitar a otros que realicen o participen en cualquier
					acto ilegal; (c) para violar cualquier reglamento, regla,
					ley u ordenanza local internacional, federal, provincial o
					estatal; (d) para infringir o violar nuestros derechos de
					propiedad intelectual o los derechos de propiedad
					intelectual de otros; (e) acosar, abusar, insultar,
					perjudicar, difamar, calumniar, desacreditar, intimidar o
					discriminar por motivos de género, orientación sexual,
					religión, etnia, raza, edad, nacionalidad o discapacidad;
					(f) presentar información falsa o engañosa; (g) para cargar
					o transmitir virus o cualquier otro tipo de código malicioso
					que será o puede ser utilizado de cualquier manera que
					afecte la funcionalidad o el funcionamiento del Servicio o
					de cualquier sitio web relacionado, otros sitios web o
					Internet; (h) para recoger o rastrear la información
					personal de otros; (i) para enviar spam, phish, pharm,
					pretextos, arañas, crawl o scrape; (j) para cualquier
					propósito obsceno o inmoral; o (k) para interferir con o
					eludir las características de seguridad del Servicio o
					cualquier sitio web relacionado, otros sitios web o
					Internet. Nos reservamos el derecho de terminar su uso del
					Servicio o de cualquier sitio web relacionado por violar
					cualquiera de los usos prohibidos.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 13 - EXCLUSIÓN DE GARANTÍAS; LIMITACIÓN DE
					RESPONSABILIDAD
				</div>
				<p>
					No garantizamos, representamos o aseguramos que el uso de
					nuestro servicio será ininterrumpido, oportuno, seguro o
					libre de errores.
				</p>
				<p>
					No garantizamos que los resultados que se obtengan del uso
					del servicio sean precisos o fiables.
				</p>
				<p>
					Usted acepta que, de vez en cuando, podemos eliminar el
					servicio por períodos de tiempo indefinidos o cancelar el
					servicio en cualquier momento, sin previo aviso.
				</p>
				<p>
					Usted acepta expresamente que el uso o la imposibilidad de
					usar el servicio es a su propio riesgo. El servicio y todos
					los productos y servicios que se le entregan a través del
					servicio se proporcionan (salvo que nosotros lo indiquemos
					expresamente) "tal cual" y "según estén disponibles" para su
					uso, sin ninguna representación, garantía o condición de
					ningún tipo, ya sea expresa o implícita, incluidas todas las
					garantías o condiciones implícitas de comerciabilidad,
					calidad comercial, idoneidad para un fin determinado,
					durabilidad, título y no infracción.
				</p>
				<p>
					En ningún caso EDconsulting, nuestros directores,
					funcionarios, empleados, afiliados, agentes, contratistas,
					pasantes, proveedores, proveedores de servicios o
					licenciatarios serán responsables de ninguna lesión,
					pérdida, reclamo o cualquier daño directo, indirecto,
					incidental, punitivo, especial o consecuente de cualquier
					tipo, incluyendo, sin limitación, la pérdida de beneficios,
					pérdida de ingresos, pérdida de ahorros, pérdida de datos,
					costos de reemplazo o cualquier daño similar, ya sea basado
					en un contrato, agravio (incluyendo negligencia),
					responsabilidad estricta o de otro tipo, que surja de su uso
					de cualquiera de los servicios o productos adquiridos
					mediante el servicio, o por cualquier otra reclamación
					relacionada de alguna manera con su uso del servicio o de
					cualquier producto, incluyendo, pero no limitándose, a
					cualquier error u omisión en cualquier contenido, o
					cualquier pérdida o daño de cualquier tipo que se produzca
					como resultado del uso del servicio o de cualquier contenido
					(o producto) publicado, transmitido o de otro modo puesto a
					disposición a través del servicio, incluso si se ha
					advertido de su posibilidad. Debido a que algunos estados o
					jurisdicciones no permiten la exclusión o la limitación de
					la responsabilidad por daños consecuentes o incidentales, en
					dichos estados o jurisdicciones, nuestra responsabilidad se
					limitará al máximo permitido por la ley.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 14 - INDEMNIZACIÓN</div>
				<p>
					Usted acepta indemnizar, defender y eximir de
					responsabilidad a EDconsulting y a nuestra empresa matriz,
					subsidiarias, afiliadas, socios, funcionarios, directores,
					agentes, contratistas, licenciatarios, proveedores de
					servicios, subcontratistas, proveedores, pasantes y
					empleados, de cualquier reclamo o demanda, incluyendo los
					honorarios razonables de abogados, hechos por cualquier
					tercero debido a o como resultado de su incumplimiento de
					estos Términos de Servicio o de los documentos que
					incorporan por referencia, o de su violación de cualquier
					ley o de los derechos de un tercero.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 15 - DIVISIBILIDAD</div>
				<p>
					En caso de que se determine que alguna disposición de estas
					Condiciones de servicio es ilegal, nula o inaplicable, dicha
					disposición será, no obstante, aplicable en la medida máxima
					permitida por la legislación aplicable, y la parte
					inaplicable se considerará separada de estas Condiciones de
					servicio, dicha determinación no afectará a la validez y
					aplicabilidad de las demás disposiciones.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 16 - TERMINACIÓN</div>
				<p>
					Las obligaciones y responsabilidades de las partes
					contraídas antes de la fecha de terminación sobrevivirán a
					la terminación del presente acuerdo a todos los efectos.
				</p>
				<p>
					Estos Términos de Servicio son efectivos a menos y hasta que
					sean terminados por usted o por nosotros. Puede cancelar
					estas Condiciones de servicio en cualquier momento
					notificándonos que ya no desea utilizar nuestros Servicios,
					o cuando deje de utilizar nuestro sitio.
				</p>
				<p>
					Si a nuestro juicio, usted no cumple, o sospechamos que no
					ha cumplido, con cualquier término o disposición de estas
					Condiciones de servicio, también podemos rescindir este
					acuerdo en cualquier momento sin previo aviso y usted
					seguirá siendo responsable de todas las cantidades adeudadas
					hasta la fecha de rescisión inclusive; y/o en consecuencia,
					puede negarle el acceso a nuestros Servicios (o a cualquier
					parte de los mismos).
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">SECCIÓN 17 - ACUERDO COMPLETO</div>
				<p>
					El hecho de que no ejerzamos o hagamos valer cualquier
					derecho o disposición de estas Condiciones de servicio no
					constituirá una renuncia a tal derecho o disposición.
				</p>
				<p>
					Estos Términos de Servicio y cualquier política o regla
					operativa publicada por nosotros en este sitio o con
					respecto al Servicio constituye el acuerdo y entendimiento
					completo entre usted y nosotros y rige su uso del Servicio,
					reemplazando cualquier acuerdo, comunicación y propuesta
					anterior o contemporánea, ya sea oral o escrita, entre usted
					y nosotros (incluyendo, pero no limitándose a cualquier
					versión anterior de los Términos de Servicio).
				</p>
				<p>
					Cualquier ambigüedad en la interpretación de estas
					condiciones de servicio no se interpretará en contra de la
					parte que las redactó.
				</p>
			</div>
		</div>

		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="heading">
					SECCIÓN 19 - CAMBIOS EN LAS CONDICIONES DE SERVICIO
				</div>
				<p>
					Puede revisar la versión más reciente de los Términos de
					Servicio en cualquier momento en esta página.
				</p>
				<p>
					Nos reservamos el derecho, a nuestra entera discreción, de
					actualizar, cambiar o reemplazar cualquier parte de estos
					Términos de Servicio publicando actualizaciones y cambios en
					nuestro sitio web. Es su responsabilidad revisar nuestro
					sitio web periódicamente para ver si hay cambios. El uso o
					acceso continuo a nuestro sitio web o al Servicio por parte
					de usted después de la publicación de cualquier cambio en
					estos Términos de servicio constituye la aceptación de
					dichos cambios.
				</p>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="text-left mb-3">
					<Link class="font-weight-bold" to="/"><i class="fas fa-arrow-left"></i> Volver</Link>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Link from "@/components/Link.vue";
export default {
	name: "TermsAndConditions",
	components: {Link},
};
</script>


<style scoped>
.heading {
	font-size: 20pt;
}
p {
	font-size: 12pt;
	text-align: justify;
}

.content-container {
	display: block;
	width: 45vw;
}
</style>