<template>
	<Loader v-if="loading" />
	<StartButton v-if="start" @click="startLesson" />
	<Slides/>
	<Presentation/>
	<div class="animation none">
		<KeyText/>
		<Keyboard />
	</div>
	<CloudHistory/>
	<LeftOkay/>
	<AuthButton v-if="next" to="/three/activity/left">¡A practicar!</AuthButton>
</template>

<script>
import KeyText from './KeyText.vue'
import LeftOkay from '@/components/LeftOkay.vue'
import CloudHistory from './CloudHistory.vue'
import Presentation from './Presentation.vue'

import Slides from './Slides.vue'
import Keyboard from "@/components/Keyboard/index";
import AuthButton from "@/components/AuthButton.vue";
import StartButton from "@/components/StartButton.vue";
import Loader from "@/components/Loader.vue";

import { setupLesson, startLesson } from "@/assets/js/bottom_row/learn/left";

import { onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";


export default {
	name: "OneLearnLeft",
	components: { Loader, StartButton, AuthButton, Keyboard, Slides, Presentation, CloudHistory, KeyText, LeftOkay },
	setup() {
		const store = useStore();

		setupLesson();

		onMounted(()=>{
			store.dispatch('loading',true);
			store.dispatch('start',false);
			store.dispatch('next',false);
		});
		onBeforeUnmount(() => {
			store.dispatch("pauseAnimation");
			store.dispatch("reset");
		});

		return {
			loading: store.getters.loading,
			start: store.getters.start,
			next: store.getters.next,
			startLesson,
		};
	},
};
</script>

<style scoped>
#explainer-video {
	width: 100%;
	height: 100%;
}
.lesson-finished {
	color: #33649f;
}
</style>