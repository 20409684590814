<template>
	<div id="modal-container" class="typing-complete-modal">
		<div class="modal-background">
			<div class="modal h-auto p-4">
				<div class="d-flex justify-content-center my-3">
					<img src="@/assets/images/gold-medal.gif" alt="gold medal"  class="final-medal"/>
				</div>
				<div class="d-flex justify-content-center flex-column align-items-center">
					<p>¡Genial!</p>
					<p>Ahora puedes escribir cualquier cosa con diez dedos y sin mirar las teclas.</p>
					<p>Has aprendido las teclas súper rápido. </p>
					<p>¡Y eso sólo en una hora!</p>
					<p>¡Grandioso!</p>
					<div class="d-flex justify-content-center w-50">
						<AuthButton to="/"  @click.prevent="handleLogout" style="position:static">Cerrar sesión</AuthButton>
						<AuthButton to="/mix" style="position:static">Tecleo Mix</AuthButton>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AuthButton from '@/components/AuthButton.vue'

import { useRouter } from "vue-router";
import { useStore } from "vuex";
import User from "@/api/User";

export default {
	components: { AuthButton },
	name: "Congrats",
	setup(){
		const store = useStore();
		const router = useRouter();

		const handleLogout = () => {
			User.logout().then((res) => {
				store.dispatch('logout');
				router.push({ name: "Home" });
			});
		};
		return {
			handleLogout
		}
	}

};
</script>

<style scoped>
	.typing-complete-modal{
		position: relative;
		width: 100%;
		height: 100%;
	}
	

	.modal {
		position: absolute;
		display: flex !important;
		justify-content: center;
		align-items: center;
		text-align: center;
		margin: 0 auto;
		color: #3567a1;
		font-weight: revert;
		line-height: 1.5em;
		font-size: 16px;
		font-weight: 600 !important;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	
	.final-logo{
		height: 70px;
	}
	.final-medal{
		height:200px;
	}
</style>