<template>
	<component :is="layout">
		<router-view></router-view>
	</component>
</template>
<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

import Main from "./layouts/Main";
import Guest from "./layouts/Guest";
import Auth from "./layouts/Auth";

export default {
	name: "App",
	components: {
		Main,
		Guest,
		Auth,
	},
	setup(){
		const layout = ref('Main')
		const route = useRoute()
		watch(
			() => route.meta,
			meta => {
				if(meta.layout){
					layout.value = meta.layout
				}else{
					layout.value = 'Main'
				}
			},
			{ immediate: true }
		)
		return { layout }
	},
};
</script>