import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

import { windowBlur } from "@/common/helpers";

export const startLesson = () => {
	SPTT.started('two_learn_left');

	store.getters.animation.value
		.add({
			targets: ".animation",
			opacity: 1,
			translateY: "0px",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})

		// hide other rows and show uper row and home row only
		.add({
			targets: [
				".row__one .red-button",
				".row__four .red-button",
				".row__five .red-button",
				".row__one .green-button",
				".row__four .green-button",
				".row__five .green-button",
				".row__one .yellow-button",
				".row__four .yellow-button",
				".row__five .yellow-button",
				".row__one .blue-button",
				".row__four .blue-button",
				".row__five .blue-button",
				"[data-key='93-R']",	
			],
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: el.classList.contains("blue-button")
					? blueColor
					: greyColor;
				},
		})
		.add({
			targets: [
				"[data-key='13'] img",
				"[data-key='8'] img",
				"[data-char='Q'] .bottom-right",
			],
			opacity: 0,
			complete: ({animatables})=>{
				animatables[0].target.classList.add('none');
				animatables[1].target.classList.add('none');
			}
		})
		// show caps lock , shift left , shift right, i with accent
		.add({
			targets: [
				"[data-key='20']",
				"[data-key='16']",
				"[data-key='222']",
				"[data-key='16-R']",
			],
			color: "#ffffff",
		})

		// hide other rows and show home rows
		.add({
			duration: 2000,
			targets: [".keyboard"],
			opacity: 1,
			translateY: ["0px", "240px"],
			easing: "easeInOutSine",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// display block slides
		.add({
			targets: ".slides",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// Q slide starts
		// Q Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR3"));
			},
			targets: ['[data-char="Q"]'],
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 18000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 18000 },
			],
		})
		// Q letter in block slides
		.add(
			{
				targets: [".slide-1 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
			},
			"-=18000"
		)
		// Q image in block slides
		.add({
			targets: ".slide-1 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-1 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-1 .flex div"],
				scale: 2,
				duration: 1500,
			},
			"-=1500"
		)
		// LHUR5 upon completion of aimation
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHUR5"));
				},
				targets: ".slide-1 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-1"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// Q slide ends

		// W slide starts
		// W Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR6"));
			},
			targets: ['[data-char="W"]'],
			backgroundColor: [
				{ value: lightBlueColor, duration: 500 },
				{ value: blueColor, duration: 500, delay: 10000 },
			],
			top: [
				{ value: "0.2em", duration: 100 },
				{ value: "0em", duration: 500, delay: 10000 },
			],
			endDelay: 6000,
		})

		// W letter on presentation
		.add(
			{
				targets: [".slide-2 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				begin: (_anim) => {},
				complete: () => {},
			},
			"-=16000"
		)
		// W image in block slide
		// LHUR7
		.add({
			targets: ".slide-2 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-2 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-2 .flex div"],
				scale: 2,
				duration: 2000,
			},
			"-=2000"
		)
		// upon completion
		// LHUR8
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR8"));
			},
			targets: ".slide-2 .word",
			opacity: [0, 1],
			fontSize: ["0em", "4em"],
			translateY: ["20%", "0%"],
			duration: 2000,
			endDelay: 2000,
		})
		.add({
			targets: [".slide-2"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// W slide ends

		// E slide starts

		// E letter in keyboard animation
		// LHUR9
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR9"));
			},

			targets: ['[data-char="E"]'],
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 13000 },
			],
			top: [
				{ value: "0.2em", duration: 100 },
				{ value: "0em", duration: 500, delay: 13000 },
			],
		})
		// E letter in presentation
		.add(
			{
				targets: [".slide-3 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=14000"
		)
		// E letter with image
		// LHUR10
		.add({
			begin: (_anim) => {},
			targets: ".slide-3 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
			endDelay: 1000,
		})
		.add({
			targets: [".slide-3 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-3 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		// upon completion of scaling
		// LHUR11
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHUR11"));
				},
				targets: ".slide-3 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-3"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// E slide ends

		// R slide starts
		// R Letter in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR12"));
			},

			targets: ['[data-char="R"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 12000 },
			],
			top: [
				{ value: "0.2em", duration: 100 },
				{ value: "0em", duration: 500, delay: 12000 },
			],
		})
		// R letter in presentation
		.add(
			{
				targets: [".slide-4 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=13000"
		)
		// image in block slides
		// LHUR13
		.add({
			targets: ".slide-4 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-4 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-4 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		// upon completion
		// LHUR14
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHUR14"));
				},
				targets: ".slide-4 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-4"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// R slide ends

		// T slide starts
		// T Key in keyboard
		// LHUR15
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR15"));
			},

			targets: ['[data-char="T"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 8000 },
			],
			top: [
				{ value: "0.2em", duration: 100 },
				{ value: "0em", duration: 500, delay: 8000 },
			],
			endDelay: 6000,
		})
		// T letter in presentation
		.add(
			{
				targets: [".slide-5 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=15000"
		)
		// image in block slides
		// LHUR16
		.add({
			begin: (_anim) => {},
			targets: ".slide-5 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-5 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-5 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)

		// upon completion of both animations [image and letter scaling]
		// LHUR17
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHUR17"));
				},
				targets: ".slide-5 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)

		.add({
			targets: [".slide-5", ".slides"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");

				store.dispatch("primary", store.getters.howler.value.play("LHUR18"));

				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
			endDelay: 2000,
		})
		// T slide ends

		// remove and hide keyboard
		.add({
			targets: [".keyboard", ".animation"],
			translateY: "500px",
			opacity: [1, 0],
			duration: 3000,

			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})
		// cloud left
		.add({
			duration: 7000,
			translateX: ["-100%", "0%"],
			targets: ".left-container",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");

				store.dispatch("secondary", store.getters.howler.value.play("passive"));

				store.getters.howler.value.fade(
					0,
					0.3,
					5000,
					store.getters.secondary.value
				);

				store.dispatch("primary", store.getters.howler.value.play("LHUR19"));
			},
			endDelay: 7000,
		})
		.add({
			duration: 2000,
			targets: ".left-container",
			opacity: [1, 0],
			endDelay: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		/** *************************************************************************************************
		 * Learning presentation 1                                                                   *
		 ***************************************************************************************************
		 */
		.add({
			targets: ".learning-presentation",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// kiosk AQA
		// LHUR19-1
		.add({
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			targets: ".presentation-1",
		})
		.add({
			duration: 4000,
			targets: ".q.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add(
			{
				duration: 16500,
				targets: ".q.lesson-img",
				width: "250px",
				height: "250px",
				begin: () => {
					store.dispatch(
						"primary",
						store.getters.howler.value.play("LHUR19-1")
					);
				},
			},
			"-=3000"
		)
		.add({
			duration: 1800,
			targets: ".q.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHUR20-21-22")
				);
			},
		})
		// label
		.add({
			targets: ".q.label h2",
			delay: anime.stagger(1000),
			translateX: [{ value: "20px" }, { value: "0px", duration: 1000 }],
			opacity: [{ value: "0" }, { value: "1", duration: 1000 }],
		})

		.add({
			duration: 2000,
			targets: [".presentation-1"],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// SWS
		.add({
			targets: ".presentation-2",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR23"));
			},
			targets: ".w.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 19000 })
		.add({
			targets: ".w.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHUR24-25-26")
				);
			},
			endDelay: 2400,
		})
		// label
		.add({
			targets: ".w.label h2",
			delay: anime.stagger(1200),
			// width: [{ value: "150px", duration: 2000, delay: 1000 }],
			// height: [{ value: "150px", duration: 2000 }],
			translateX: ["20px", "0px"],
			opacity: [0, 1],
			complete: () => {},
		})
		.add({
			duration: 2000,
			targets: ".presentation-2",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// DED
		// LHUR27
		.add({
			targets: ".presentation-3",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR27"));
			},
			targets: ".e.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 19000 })
		.add({
			targets: ".e.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHUR28-29-30")
				);
			},
			endDelay: 1500,
		})
		// label
		.add({
			targets: ".e.label h2",
			delay: anime.stagger(1000),
			translateX: ["20px", "0px"],
			opacity: [0, 1],
			complete: () => {},
		})

		.add({
			duration: 2000,
			targets: ".presentation-3",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// FTF
		// LHUR31
		.add({
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			targets: ".presentation-4",
		})
		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR31"));
			},
			targets: ".r.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 19000 })
		.add({
			targets: ".r.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHUR32-33-34")
				);
			},
			endDelay: 2500,
		})
		// label
		.add({
			targets: ".r.label h2",
			delay: anime.stagger(1200),
			// width: [{ value: "150px", duration: 2000, delay: 1000 }],
			// height: [{ value: "150px", duration: 2000 }],
			translateX: ["20px", "0px"],
			opacity: [0, 1],
			complete: () => {},
		})
		.add({
			duration: 2000,
			targets: ".presentation-4",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// FTF
		// LHUR35
		.add({
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			targets: ".presentation-5",
		})
		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR35"));
			},
			targets: ".t.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 18000 })
		.add({
			targets: ".t.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHUR36-37-38")
				);
			},
			endDelay: 2200,
		})
		// label
		.add({
			targets: ".t.label h2",
			delay: anime.stagger(1200),
			// width: [{ value: "150px", duration: 2000, delay: 1000 }],
			// height: [{ value: "150px", duration: 2000 }],
			translateX: ["20px", "0px"],
			opacity: [0, 1],
			complete: () => {},
		})
		.add({
			delay: 2000,
			duration: 3000,
			targets: ".presentation-5",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
			endDelay: 1000,
		})

		.add({
			targets: ".learning-presentation",
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// slide up keyboard
		.add({
			targets: [".animation"],
			translateY: "50px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
			},
		})		
		.add({
			targets: [".keyboard", ".animation .key-text-left"],
			translateY: "0px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			complete: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHUR39-40-41-42-43")
				);
			},
			// endDelay: 2200
		})
		.add({
			targets: [".keyboard", ".animation"],
			opacity: 1,
			delay: 3000,
		})

		.add({
			delay: 1700,
			targets: ['[data-char="Q"]'],
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-q",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2200,
			targets: ['[data-char="W"]'],
			backgroundColor: [
				{ value: lightBlueColor, duration: 500 },
				{ value: blueColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-w",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2500,
			targets: ['[data-char="E"]'],
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-e",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2500,
			targets: ['[data-char="R"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-r",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 3500,
			targets: ['[data-char="T"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-t",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 1000,
			targets: ".key-text-left h2",
			opacity: [1, 0],
			duration: 1000,
		})

		// hide animation
		.add({
			targets: [".animation", ".animation .key-text-left"],
			opacity: [1, 0],
			translateY: "500px",
			easing: "easeInOutSine",
			duration: 3000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			endDelay: 1000,
		})

		// ok thumbsup
		.add({
			targets: ".right-ok",
			opacity: 1,
			duration: 3000,
			translateX: ["-20%", "0%"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("LHUR44"));
				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('two_learn_left');
			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/upper_row/learn/left.mp3")],
			sprite: {
				active: [0, 252120, true],
				LHUR11: [254000, 2731.3605442176936],
				LHUR12: [258000, 11428.594104308388],
				LHUR14: [271000, 1734.376417233534],
				LHUR15: [274000, 15510.79365079363],
				LHUR17: [291000, 1832.8117913832216],
				LHUR18: [294000, 1469.2743764172178],
				"LHUR19-1": [297000, 16203.582766439922],
				LHUR19: [315000, 9843.17460317459],
				"LHUR20-21-22": [326000, 5877.142857142871],
				LHUR23: [333000, 18282.743764172323],
				"LHUR24-25-26": [353000, 6725.374149659842],
				LHUR27: [361000, 18570.521541950144],
				"LHUR28-29-30": [381000, 5559.024943310646],
				LHUR3: [388000, 17298.16326530613],
				LHUR31: [407000, 18419.070294784604],
				"LHUR32-33-34": [427000, 6240.680272108819],
				LHUR35: [435000, 16813.469387755107],
				"LHUR36-37-38": [453000, 7316.14512471657],
				"LHUR39-40-41-42-43": [462000, 24447.68707482996],
				LHUR44: [488000, 5574.19501133785],
				LHUR5: [495000, 2645.238095238085],
				LHUR6: [499000, 15871.360544217736],
				LHUR8: [516000, 2300.725623582821],
				LHUR9: [520000, 13263.061224489775],
				passive: [535000, 548571.4285714284, true],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
