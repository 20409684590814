import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import $ from "jquery";
import { windowBlur } from "@/common/helpers";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started("mix");
	if(store.getters.sptt.value.mix_finished)
		store.getters.animation.value
			.add({
				duration: 3000,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("RepTMA"));
				},
			});
	else
	store.getters.animation.value

		// TTTM1
		.add({
			delay: 500,
			duration: 10300,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTTM1"));
			},
		});
	
	store.getters.animation.value
		.add({
			begin: () => {
				window.addEventListener("keypress", keyPressEvent);

				window.removeEventListener("blur", windowBlur);
				// practice started
				store.dispatch("onActivity");
			},
		});

		// timeline starts playing from here after first music and first round of typing
		// slide down typer
	if(!store.getters.sptt.value.mix_finished)
		store.getters.animation.value
			.add({
				targets: ["#typer-app"],
				translateY: ["0px", "500px"],
				opacity: 0,
				duration: 1000,
				easing: "easeOutSine",
				begin: (anim) => {
					store.dispatch("playing", true);
					window.removeEventListener("keypress", keyPressEvent);

					window.addEventListener("blur", windowBlur);
				},
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})
			.add({
				targets: [".animation"],
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// slide in bronze medal gif
			.add({
				targets: [".bronze_gif"],
				opacity: [0, 1],
				translateX: ["-500px", "0px"],
				duration: 1000,
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// Play TTTM2
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTTM2"));
				},
				duration: 7500,
			})

			// Play TTTM3
			.add(
				{
					begin: () => {
						store.dispatch("primary", store.getters.howler.value.play("TTTM3"));
					},
					duration: 6700,
				},
				"-=500"
			)

			// slide out bronze medal gif
			.add({
				targets: [".bronze_gif"],
				opacity: [1, 0],
				translateX: ["0px", "-500px"],
				duration: 1000,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})
			.add({
				targets: [".animation"],
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})

			// slide up typer
			.add({
				targets: ["#typer-app"],
				translateY: ["500px", "0px"],
				opacity: 1,
				duration: 1000,
				easing: "easeOutSine",
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
				complete: () => {
					window.addEventListener("keypress", keyPressEvent);

					window.removeEventListener("blur", windowBlur);
					store.dispatch("onActivity");
				},
			})

			// after second round of typing

			// timeline resumes playing from here after second round of typing
			// slide down typer

			.add({
				targets: ["#typer-app"],
				translateY: ["0px", "500px"],
				opacity: 0,
				duration: 1000,
				easing: "easeOutSine",
				begin: (anim) => {
					store.dispatch("playing", true);
					window.removeEventListener("keypress", keyPressEvent);

					window.addEventListener("blur", windowBlur);
				},
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})
			.add({
				targets: [".animation"],
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// slide in  silver medal gif
			.add({
				targets: [".silver_gif"],
				opacity: [0, 1],
				translateX: ["-500px", "0px"],
				duration: 1000,
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// Play TTTM4
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTTM4"));
				},
				duration: 4000,
			})

			// Play TTTM5
			.add(
				{
					begin: () => {
						store.dispatch("primary", store.getters.howler.value.play("TTTM5"));
					},
					duration: 5000,
				},
				"-=500"
			)

			// slide out silver medal gif
			.add({
				targets: [".silver_gif"],
				opacity: [1, 0],
				translateX: ["0px", "-500px"],
				duration: 1000,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})
			.add({
				targets: [".animation"],
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})

			// slide up typer
			.add({
				targets: ["#typer-app"],
				translateY: ["500px", "0px"],
				opacity: 1,
				duration: 1000,
				easing: "easeOutSine",
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
				complete: () => {
					window.addEventListener("keypress", keyPressEvent);

					window.removeEventListener("blur", windowBlur);
					store.dispatch("onActivity");
				},
			});
	if(!store.getters.sptt.value.mix_finished)
		store.getters.animation.value
			// Play TTTM6
			.add({
				delay: 1000,
				begin: () => {
					window.removeEventListener("keypress", keyPressEvent);

					window.addEventListener("blur", windowBlur);
					store.dispatch("playing", true);

					store.dispatch("primary", store.getters.howler.value.play("TTTM6"));
				},
				duration: 4000,
			});
	
	store.getters.animation.value
		// slide down typer
		.add({
			targets: ["#typer-app"],
			translateY: ["0px", "500px"],
			opacity: 0,
			duration: 1000,
			easing: "easeOutSine",
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		.add({
			targets: [".animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		// slide in gold medal gif
		.add({
			targets: [".gold_gif"],
			opacity: [0, 1],
			translateX: ["500px", "0px"],
			duration: 1000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// Play TTTM7
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("TTTM7"));
			},
			duration: 10000,
		});



	if(store.getters.sptt.value.mix_finished)
		store.getters.animation.value
		
			// RepTMB
			.add({
				duration: 5280,
				begin: (anim) => {
					// update progress here
					SPTT.finished("mix");

					store.dispatch("primary", store.getters.howler.value.play("RepTMB"));
				},
				complete: () => {
					window.removeEventListener("blur", windowBlur);
					store.dispatch("playing", false);
					$(".timeline-controls").addClass('none');
				},
			});
	else
		store.getters.animation.value
			// slide out gold medal gif
			.add({
				targets: [".gold_gif"],
				opacity: [1, 0],
				translateX: ["0px", "500px"],
				duration: 1000,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})
			// TTTM8 was removed 
			// Play TTTM9 
			.add({
				targets: [".animation"],
				begin: ({animatables}) => {
					window.removeEventListener("blur", windowBlur);
					store.dispatch("playing", false);
					store.dispatch("next", true);
					// update progress here
					SPTT.finished("mix");
					animatables[0].target.classList.add("none");	
					store.dispatch("primary", store.getters.howler.value.play("TTTM9"));
				},
				duration: 40000,
			});
				

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);
	window.removeEventListener("keypress", keyPressEvent);

	store.getters.animation.value.play();

	window.addEventListener("keydown", (e) => {
		if (e.which == 222 || e.which == 219) store.dispatch("accent_down", true);
	});
	window.addEventListener("keypress", (e) => {
		if (e.which == 222 || e.which == 219) store.dispatch("accent_down", true);
	});
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/mix.mp3")],
			sprite: {
				RepTMA: [0, 2982.4489795918366],
				RepTMB: [4000, 5268.979591836736],
				TTTM1: [11000, 10291.74603174603],
				TTTM2: [23000, 7345.6462585034005],
				TTTM3: [32000, 6599.297052154192],
				TTTM4: [40000, 3888.866213151928],
				TTTM5: [45000, 4949.478458049889],
				TTTM6: [51000, 2356.893424036279],
				TTTM7: [55000, 9623.968253968258],
				TTTM8: [66000, 4635.238095238094],
				TTTM9: [72000, 40163.44671201814]
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};

export const setupPractice = () => {
	store.dispatch(
		"text",
		"la foto del jefe|la sopa salada|la lata de ñoras|la vida de lujo|el arte del yoga|el aire sano|la vida es loca|las gafas de Diego|las joyas de Julia|allí en la sala|allí en el faro|la gala del día|tres días en el yate|hay wifi en la isla|doce motos|bolas extras|bajo la mesa|textos falsos|un vaso de zumo|la idea de Diego|un kilo de kiwis|la zona de taxis|un rato en el cole|la foto de la boda|lasaña para los osos|las uñas de la gata gris|Vivo en la casa azul.|Dad besos a la niña.|Está de moda.|Nada está en el nido.|Lava la falda, la blusa, etc.|A él le gusta la casa-barco.|Da la salsa sana a los niños.|Añada lomo y sal a la ensalada.|A Julia le gusta el zumo de kiwi.|En la saga hay hadas con alas.|"
	);
	store.dispatch(
		"formatted_text",
		store.getters.text.value.split("|").join(" ")
	);
	store.dispatch(
		"typing_text",
		store.getters.text.value
			.split("|")
			.map((word) => (word + " ").repeat(3))
			.join("")
			.trimRight()
	);

	$("#text").text(store.getters.formatted_text.value);
	$("#typing-text").text(
		store.getters.typing_text.value.slice(
			store.getters.curr_pointer.value,
			store.getters.curr_pointer.value + 50
		)
	);
};

export const keyPressEvent = function (evt) {
	if (!store.getters.activity.value) return;

	// actuall 56
	if (
		!store.getters.sptt.value.mix_finished &&
		store.getters.word_count.value / 3 == 56 &&
		store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
	) {
		//uñas 3 times done show popup
		store.dispatch("offActivity");
	}
	// Dad besos a la nina
	else if (
		!store.getters.sptt.value.mix_finished &&
		store.getters.curr_pointer.value == 1448 &&
		!store.getters.accent_down.value
	) {
		store.dispatch("offActivity");
	}

	// highlighting text finished
	if (
		store.getters.typing_text.value.length ==
		store.getters.curr_pointer.value + 1
	) {
		$("#text").html(
			'<span class="green">' + store.getters.formatted_text.value + "</span>"
		);
		$("#typing-text").addClass("none");
		window.addEventListener("blur", windowBlur);
		store.dispatch("offActivity");
	} else
		$("#text").html(
			'<span class="green">' +
				store.getters.formatted_text.value
					.split(" ")
					.slice(0, store.getters.word_count.value / 3)
					.join(" ") +
				"</span> <span>" +
				store.getters.formatted_text.value
					.split(" ")
					.slice(store.getters.word_count.value / 3)
					.join(" ") +
				"</span>"
		);
	if (
		String.fromCharCode(evt.charCode) == "í" ||
		String.fromCharCode(evt.charCode) == "á" ||
		String.fromCharCode(evt.charCode) == "é" ||
		(store.getters.typing_text.value[store.getters.curr_pointer.value] ===
			evt.key &&
			!store.getters.accent_down.value) ||
		(store.getters.accent_down.value &&
			evt.key == "i" &&
			store.getters.typing_text.value[store.getters.curr_pointer.value] ==
				"í") ||
		(store.getters.accent_down.value &&
			evt.key == "a" &&
			store.getters.typing_text.value[store.getters.curr_pointer.value] ==
				"á") ||
		(store.getters.accent_down.value &&
			evt.key == "e" &&
			store.getters.typing_text.value[store.getters.curr_pointer.value] == "é")
	) {
		store.dispatch("curr_pointer", store.getters.curr_pointer.value + 1);
		if (evt.shiftKey || store.getters.accent_down.value)
			store.dispatch("strokes_count", store.getters.strokes_count.value + 2);
		else store.dispatch("strokes_count", store.getters.strokes_count.value + 1);

		if (
			store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
		) {
			store.dispatch("word_count", store.getters.word_count.value + 1);
		}
		$("#progress").css(
			"width",
			(store.getters.curr_pointer.value /
				store.getters.typing_text.value.length) *
				600
		);
		$("#wordcount").text(Math.floor(store.getters.strokes_count.value / 5));
		// highlighting typing row
		$("#typing-text").html(
			'<span class="green">' +
				store.getters.typing_text.value
					.slice(0, store.getters.curr_pointer.value)
					.slice(-10) +
				"</span>" +
				"<span class='underline'>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value,
					store.getters.curr_pointer.value + 1
				) +
				"</span>" +
				"<span>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value + 1,
					store.getters.curr_pointer.value + 50
				) +
				"</span>"
		);
	} else {
		if (
			store.getters.curr_pointer.value <
			store.getters.typing_text.value.length - 1
		)
			$("#typing-text").html(
				'<span class="green">' +
					store.getters.typing_text.value
						.slice(0, store.getters.curr_pointer.value)
						.slice(-10) +
					"</span>" +
					'<span class="red">' +
					store.getters.typing_text.value[store.getters.curr_pointer.value] +
					"</span><span>" +
					store.getters.typing_text.value.slice(
						store.getters.curr_pointer.value + 1,
						store.getters.curr_pointer.value + 50
					) +
					"</span>"
			);
	}
	if (store.getters.accent_down.value) store.dispatch("accent_down", false);
};
