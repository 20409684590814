<template>
	<div id="loader" class="h-100 p-0 d-flex flex-column justify-content-center align-items-center">
		<div>
			<h1>Por favor espera. Tu sesión se está cargando...</h1>
		</div>
		<div>
			<img src="@/assets/css/icons/loader.svg" alt="Loading Content">
		</div>
	</div>

</template>

<script>
export default {
	name: "Loader"
}
</script>

<style scoped>
	#loader h1{
		color:var(--color-navy-blue);
		font-size:16px;
		letter-spacing:1px;
		font-weight:200;
		text-align:center;
	}
	#loader i{
		font-size: 50px;
		color: #3ccfcf;
	}
</style>