<template>
	<div class="key-text-left none">
		<h2 class="red-text text-q">
			Q
		</h2>
		<h2 class="blue-text text-w">
			W
		</h2>
		<h2 class="yellow-text text-e">
			E
		</h2>
		<h2 class="green-text text-r">
			R
		</h2>
		<h2 class="green-text text-t">
			T
		</h2>
	</div>
</template>

<script>
export default {
	name: "KeyText",
};
</script>

<style scoped>
</style>