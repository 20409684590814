import { createStore } from "vuex";
import getDefaultState from './state'
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";


export default createStore({
	state: getDefaultState(),
	mutations,
	actions,
	getters,
});
