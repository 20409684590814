<template>
	<div class="keyboard-drag none">
		<div class="drag-drop">
			<div class="keyboard-wrapper keyboard-act drop activity-5">
				<div class="keyboard-activity dropzone red-bg" data-dropzone="1">
				</div>
				<div class="keyboard-activity dropzone blue-bg" data-dropzone="2">
				</div>
				<div class="keyboard-activity dropzone yellow-bg" data-dropzone="3">
				</div>
				<div class="keyboard-activity dropzone green-bg" data-dropzone="4">
				</div>
				<div class="keyboard-activity dropzone green-bg" data-dropzone="5">
				</div>
				<div class="keyboard-activity dropzone green-bg" data-dropzone="6">
				</div>
				<div class="keyboard-activity dropzone green-bg" data-dropzone="7">
				</div>
				<div class="keyboard-activity dropzone yellow-bg" data-dropzone="8">
				</div>
				<div class="keyboard-activity dropzone blue-bg" data-dropzone="9">
				</div>
				<div class="keyboard-activity dropzone red-bg" data-dropzone="10">
				</div>
			</div>
			<div class="keyboard-wrapper drag">
				<div class="dropzone occupied" data-dropzone="10">
					<div class="draggable">Ñ</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable" style="display: flex; flex-direction:column; line-height:10px">
						<span>F</span><span>_</span>
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="7">
					<div class="draggable" style="display: flex; flex-direction:column; line-height:10px">
						<span>J</span><span>_</span>
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="3">
					<div class="draggable">D</div>
				</div>
				<div class="dropzone occupied" data-dropzone="2">
					<div class="draggable">S</div>
				</div>
				<div class="dropzone occupied" data-dropzone="1">
					<div class="draggable">A</div>
				</div>
				<div class="dropzone occupied" data-dropzone="6">
					<div class="draggable">H</div>
				</div>
				<div class="dropzone occupied" data-dropzone="9">
					<div class="draggable">L</div>
				</div>
				<div class="dropzone occupied" data-dropzone="8">
					<div class="draggable">K</div>
				</div>
				<div class="dropzone occupied" data-dropzone="5">
					<div class="draggable">G</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setupActivityThree } from "@/common/activities";

export default {
	name: "Third",
	mounted: ()=> setupActivityThree('keyboard-wrapper', 'keyboard-act', 10),
};
</script>

<style scoped>
</style>