<template>
	<input type="text" :value="modelValue" @input="updateValue"/>
</template>

<script>
import { ref, watch } from 'vue';
export default {
	name: "GuestInput",
	props: ["modelValue"],
	setup(props, {emit}){
		const updateValue = (event)=>{
			emit('update:modelValue', event.target.value)
		}
		return {
			updateValue
		}
	}
}
</script>

<style scoped>
	input {
		outline: 0;
		background: #f2f2f2;
		width: 100%;
		border: 0;
		margin: 0 0 15px;
		padding: 15px;
		box-sizing: border-box;
		font-size: 14px;
	}
	.is-valid{
		border: 1px solid green;
	}
	.is-invalid{
		border: 1px solid red;
	}
	
</style>