import Api from './Api'
import csrf from './csrf'
export default {
	async login(data){
		await csrf.getCookie();
		return Api.post('/login',data)
	},

	async logout(data){
		await csrf.getCookie();

		return Api.post('/user/logout')
	},
	
	async self(){
		await csrf.getCookie();

		return Api.get('/user')
	},
	

}