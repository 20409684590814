import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

import { windowBlur } from "@/common/helpers";

export const startLesson = () => {
	SPTT.started('two_learn_right');

	store.getters.animation.value
		.add({
			targets: ".animation",
			duration: 1000,
			opacity: 1,
			translateY: "0px",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		// hide other rows and show home row and upper row only
		.add({
			targets: [
				".row__one .red-button",
				".row__four .red-button",
				".row__five .red-button",
				".row__one .green-button",
				".row__four .green-button",
				".row__five .green-button",
				".row__one .yellow-button",
				".row__four .yellow-button",
				".row__five .yellow-button",
				".row__one .blue-button",
				".row__four .blue-button",
				".row__five .blue-button",
				"[data-key='93-R']",	
			],
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: el.classList.contains("blue-button")
					? blueColor
					: greyColor;
			},
		})
		.add({
			targets: [
				"[data-key='13'] img",
				"[data-key='8'] img",
				"[data-char='Q'] .bottom-right",
			],
			opacity: 0,
			complete: ({animatables})=>{
				animatables[0].target.classList.add('none');
				animatables[1].target.classList.add('none');
			}
		})
		// show caps lock , shift left , shift right, i with accent
		.add({
			targets: [
				"[data-key='20']",
				"[data-key='16']",
				"[data-key='222']",
				"[data-key='16-R']",
			],
			color: "#ffffff",
		})

		.add({
			duration: 2000,
			targets: [".keyboard"],
			opacity: 1,
			translateY: ["0px", "240px"],
		})
		.add({
			duration: 7500,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR1"));
			},
		})
		// display block slides
		.add({
			targets: ".slides",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		//  P Key in keyboard
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR2"));
			},

			targets: ['[data-char="P"]'],
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 100 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
			endDelay: 13000,
		})

		// P letter
		.add(
			{
				targets: [".slide-6 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
				begin: (_anim) => {},
				complete: () => {},
			},
			"-=14000"
		)

		// P image
		// no such audio RHUR3 is the audio
		.add({
			targets: ".slide-6 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})

		.add({
			targets: [".slide-6 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
			delay: 0,
		})

		.add(
			{
				delay: 0,
				targets: [".slide-6 .flex div"],
				scale: 2,
				duration: 1500,
			},
			"-=1500"
		)

		// RHUR4 upon completion of aimation
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RHUR4"));
				},
				targets: ".slide-6 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)

		.add({
			targets: [".slide-6"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// P slide ends

		// O slide starts
		// O Key in keyboard
		// RHUR5
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR5"));
			},

			targets: ['[data-char="O"]'],
			backgroundColor: [
				{ value: lightBlueColor, duration: 500, delay: 1000 },
				{ value: blueColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500, delay: 1000 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
			endDelay: 18000,
		})

		// O letter on presentation
		.add(
			{
				targets: [".slide-7 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=20000"
		)
		// image
		// no such audio RHUR6
		.add({
			targets: ".slide-7 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-7 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-7 .flex div"],
				scale: 2,
				duration: 2000,
			},
			"-=2000"
		)
		// upon completion
		// RHUR7
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR7"));
			},
			targets: ".slide-7 .word",
			opacity: [0, 1],
			fontSize: ["0em", "4em"],
			translateY: ["20%", "0%"],
			duration: 2000,
			endDelay: 2000,
		})
		.add({
			targets: [".slide-7"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// O slide ends

		// I slide starts
		// I letter in keyboard animation
		// RHUR8
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR8"));
			},

			targets: ['[data-char="I"]'],
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
			endDelay: 10000,
		})
		// I letter in presentation
		.add(
			{
				targets: [".slide-8 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=11000"
		)
		// I letter with image
		.add({
			targets: ".slide-8 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
			endDelay: 1000,
		})
		.add({
			targets: [".slide-8 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-8 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		// upon completion of scaling
		// RHUR10
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RHUR10"));
				},
				targets: ".slide-8 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-8"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// I slide ends

		// U slide starts
		// U Letter in keyboard
		// RHUR11
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR11"));
			},

			targets: ['[data-char="U"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
			endDelay: 15000,
		})
		// U letter in presentation
		.add(
			{
				targets: [".slide-9 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=16000"
		)
		// image with letter in presentation
		.add({
			targets: ".slide-9 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-9 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-9 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		// upon completion
		// RHUR13
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RHUR13"));
				},
				targets: ".slide-9 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-9"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// U slide ends

		// Y slide starts
		// Y Key in keyboard
		// RHUR14
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR14"));
			},

			targets: ['[data-char="Y"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500, delay: 3000 },
				{ value: greenColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500, delay: 3000 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
			endDelay: 21000,
		})
		// Y letter in presentation
		.add(
			{
				targets: [".slide-10 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=23000"
		)
		// image with letter Y in presentation
		.add({
			targets: ".slide-10 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-10 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-10 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		// upon completion of both animations [image and letter scaling]
		// RHUR16
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RHUR16"));
				},
				targets: ".slide-10 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 4000,
			},
			"-=1000"
		)
		// Y slide ends

		.add({
			targets: [".slide-10", ".slides"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");

				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
		})
		// remove and hide keyboard
		.add({
			targets: [".keyboard", ".animation"],
			translateY: "500px",
			opacity: [1, 0],
			duration: 3000,

			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})

		// cloud  Right
		.add({
			duration: 6000,
			translateX: ["100%", "0%"],
			targets: ".right-container",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("passive"));

				store.getters.howler.value.fade(
					0,
					0.3,
					5000,
					store.getters.secondary.value
				);

				store.dispatch("primary", store.getters.howler.value.play("RHUR17"));
			},
			endDelay: 7000,
		})

		.add({
			targets: ".right-container",
			opacity: [1, 0],
			duration: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		/** ****************************************************************************************
		 *  Learning Presentation 2                                                                *
		 * *****************************************************************************************
		 */
		// ñ ÑNN
		.add({
			targets: ".learning-presentation",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			targets: ".presentation-6",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// ÑPÑ
		// RHUR18
		.add({
			targets: ".p.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [{ value: "0" }, { value: "1", duration: 4000 }],
			endDelay: 19500,
		})
		.add(
			{
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("RHUR18"));
				},
				delay: 2000,
			},
			"-=21500"
		)

		.add({
			targets: ".p.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHUR19-20-21")
				);
			},
			endDelay: 2000,
		})
		// label
		.add({
			targets: ".p.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{ value: "20px" },
				{ value: "0px", duration: 800, delay: 200 },
			],

			opacity: [{ value: "0" }, { value: "1", duration: 800, delay: 200 }],
			endDelay: 2000,
		})

		.add({
			targets: [".presentation-6"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// LOL
		.add({
			targets: ".presentation-7",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		// RHUR22
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR22"));
			},
			targets: ".o.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [{ value: "0" }, { value: "1", duration: 4000 }],
			endDelay: 18000,
		})
		.add({
			targets: ".o.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHUR23-24-25")
				);
			},
			endDelay: 1200,
		})
		// label
		.add({
			targets: ".o.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{ value: "20px" },
				{ value: "0px", duration: 800, delay: 200 },
			],

			opacity: [{ value: "0" }, { value: "1", duration: 800, delay: 200 }],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-7"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// LOL
		.add({
			targets: ".presentation-8",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		// KIK
		// RHUR26
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR26"));
			},
			targets: ".i.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [{ value: "0" }, { value: "1", duration: 4000 }],
			endDelay: 18000,
		})
		.add({
			targets: ".i.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHUR27-28-29")
				);
			},
			endDelay: 1000,
		})
		// label
		.add({
			targets: ".i.label h2",
			delay: anime.stagger(1000),
			translateX: [
				{ value: "20px" },
				{ value: "0px", duration: 800, delay: 200 },
			],

			opacity: [{ value: "0" }, { value: "1", duration: 800, delay: 200 }],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-8"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		.add({
			targets: ".presentation-9",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		// JUJ
		// RHUR30
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR30"));
			},
			targets: ".u.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [{ value: "0" }, { value: "1", duration: 4000 }],
			endDelay: 18000,
		})
		.add({
			targets: ".u.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHUR31-32-33")
				);
			},
			endDelay: 1000,
		})
		// label
		.add({
			targets: ".u.label h2",
			delay: anime.stagger(1000),
			translateX: [
				{ value: "20px" },
				{ value: "0px", duration: 800, delay: 200 },
			],

			opacity: [{ value: "0" }, { value: "1", duration: 800, delay: 200 }],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-9"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		.add({
			targets: ".presentation-10",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		// JYJ
		// RHUR34
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR34"));
			},
			targets: ".y.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [{ value: "0" }, { value: "1", duration: 4000 }],
			endDelay: 18000,
		})
		.add({
			targets: ".y.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHUR35-36-37")
				);
			},
			endDelay: 1000,
		})
		// label
		.add({
			targets: ".y.label h2",
			delay: anime.stagger(1000),
			translateX: [
				{ value: "20px" },
				{ value: "0px", duration: 800, delay: 200 },
			],
			opacity: [{ value: "0" }, { value: "1", duration: 800, delay: 200 }],
			endDelay: 2000,
		})

		.add({
			targets: [".presentation-10", ".learning-presentation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})
		
		// slide up keyboard
		.add({
			targets: [".animation"],
			translateY: "50px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
			},
		})
		
		.add({
			targets: [".keyboard"],
			translateY: "0px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			targets: [".animation .key-text-right"],
			easing: "easeInOutSine",
			translateX: "4em",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		}, "-=3000")
		.add({
			complete: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHUR38-39-40-41-42")
				);
			}
		})



		.add({
			targets: [".keyboard", ".animation"],
			opacity: 1,
			delay: 3600,
		})

		// right

		.add({
			delay: 1700,
			targets: ['[data-char="P"]'],
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-right h2.text-p",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2200,
			targets: ['[data-char="O"]'],
			backgroundColor: [
				{ value: lightBlueColor, duration: 500 },
				{ value: blueColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-right h2.text-o",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2500,
			targets: ['[data-char="I"]'],
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-right h2.text-i",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2500,
			targets: ['[data-char="U"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-right h2.text-u",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 3500,
			targets: ['[data-char="Y"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-right h2.text-y",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		// hide uper text letters

		.add({
			targets: ".key-text-right h2",
			delay: 1000,
			opacity: [1, 0],
			duration: 1000,
		})

		// hide animation
		.add({
			targets: [".animation", ".animation .key-text-right"],
			opacity: [1, 0],
			translateY: "500px",
			easing: "easeInOutSine",
			duration: 3000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			endDelay: 1000,
		})

		// ok thumbsup
		.add({
			targets: ".right-like",
			opacity: 1,
			duration: 3000,
			translateX: ["20%", "0%"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("RHUR43"));
				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
		})

		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('two_learn_right');

			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/upper_row/learn/right.mp3")],
			sprite: {
				active: [0, 252120, true],
				passive: [254000, 548571.4285714285, true],
				RHUR1: [804000, 7452.44897959185],
				RHUR10: [813000, 3302.0861678004394],
				RHUR11: [818000, 11254.421768707516],
				RHUR13: [831000, 3559.614512471626],
				RHUR14: [836000, 20039.841269841305],
				RHUR16: [858000, 3620.2040816326644],
				RHUR17: [863000, 6669.160997732433],
				RHUR18: [871000, 20933.514739229053],
				"RHUR19-20-21": [893000, 7088.911564625846],
				RHUR2: [902000, 10481.90476190473],
				RHUR22: [914000, 18116.099773242582],
				"RHUR23-24-25": [934000, 6134.648526077058],
				RHUR26: [942000, 15299.977324263069],
				"RHUR27-28-29": [959000, 5896.145124716554],
				RHUR30: [966000, 16609.591836734693],
				"RHUR31-32-33": [984000, 5831.814058956866],
				RHUR34: [991000, 18787.278911564612],
				"RHUR35-36-37": [1011000, 6496.190476190463],
				"RHUR38-39-40-41-42": [1019000, 25664.87528344669],
				RHUR4: [1046000, 3029.45578231288],
				RHUR43: [1051000, 4822.947845804947],
				RHUR5: [1057000, 16268.163265306157],
				RHUR7: [1075000, 1908.5487528345766],
				RHUR8: [1078000, 8861.156462585086],
			},

			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
