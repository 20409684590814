<template>
	<div style="position: relative">
		<input :type="visible ? 'text':'password'" :value="modelValue"  @input="updateValue" v-bind="$attrs" :placeholder="placeholder"/>
		<EyeButton @click.prevent="visible = !visible"/>
	</div>
</template>

<script>
import { computed, ref } from 'vue';
import EyeButton from './EyeButton.vue'
export default {
	components: { EyeButton },
	name: "GuestPassword",
	inheritAttrs: false,
	props: ["modelValue", "placeholder"],
	setup(props, {emit, attrs}){
		const visible = ref(false);
		const updateValue = (event)=>{
			emit('update:modelValue', event.target.value)
		}
		return {
			updateValue,
			visible,
		}
	}
}
</script>

<style scoped>
	input {
		outline: 0;
		background: #f2f2f2;
		width: 100%;
		border: 0;
		margin: 0 0 15px;
		padding: 15px;
		box-sizing: border-box;
		font-size: 14px;
	}
	.is-valid{
		border: 1px solid green;
	}
	.is-invalid{
		border: 1px solid red;
	}
	
</style>