<template>
	<div class="keyboard__row keyboard__row--h3 row__five">
		<div class="grey-button key--top-left key--word key-grey" data-key="17"></div>
		<div class="grey-button key--top-left key--word key-grey" data-key="18"></div>
		<div class="grey-button key--top-left key--word key-grey" data-key="91"></div>
		<div class="grey-button key--letter key--right key--space" data-key="32" data-char=" ">
			Espaciador
		</div>
		<div class="grey-button key--letter key--top-left key--word key-grey" data-key="93-R">
			Alt Gr
		</div>
		<div class="grey-button key--top-left key--word key-grey" data-key="18-R"></div>
		<div class="grey-button key--top-left key--word key-grey"></div>
		<div class="grey-button key--top-left key--word key-grey"></div>
	</div>
</template>

<script>
export default {
	name: "RowFive",
};
</script>

<style scoped>
</style>