<template>
	<Loader v-if="loading" />
	<StartButton v-if="start" @click="startLesson" />

	<First/>
	<Second/>
		
	<LeftOkay/>
	<AuthButton v-if="next" to="/two/learn/right">¡Seguimos!</AuthButton>
	<div id="overlay-animation" class="none"></div>

</template>

<script>
import Second from './Second.vue'
import First from './First.vue'
import LeftOkay from '@/components/LeftOkay.vue'

import AuthButton from "@/components/AuthButton.vue";
import StartButton from "@/components/StartButton.vue";
import Loader from "@/components/Loader.vue";

import { setupLesson, startLesson } from "@/assets/js/upper_row/activity/left";

import { onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";


export default {
	name: "OneActivityLeft",
	components: { Loader, StartButton, AuthButton, LeftOkay,  First, Second },
	setup() {
		const store = useStore();

		setupLesson();

		onMounted(()=>{
			store.dispatch('loading',true);
			store.dispatch('start',false);
			store.dispatch('next',false);
		});
		onBeforeUnmount(() => {
			store.dispatch("pauseAnimation");
			store.dispatch("reset");
		});

		return {
			loading: store.getters.loading,
			start: store.getters.start,
			next: store.getters.next,
			startLesson,
		};
	},
};
</script>

<style scoped>
#explainer-video {
	width: 100%;
	height: 100%;
}
.lesson-finished {
	color: #33649f;
}
</style>