<template>
	<router-link class="btn my-2 my-sm-0">
		<slot/>
	</router-link>
</template>

<script>
export default {
	name: "TurqoiseButton"
}
</script>

<style scoped>
	.btn {
		margin-left: 5px;
		background-color: #41E0D0;
		color: white;
		border: none;
	}

	.btn:hover,
	.btn:target{
		color:black;
		background-color: #41E0D0;
		box-shadow: 2px 4px 6px turquoise;
	}

</style>