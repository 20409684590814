import store from '@/store/index'
import router from '@/router/index'

import axios from "axios";
import NProgress from "nprogress";

let Api = axios.create({
	baseURL: process.env.VUE_APP_BACKEND_URL ? process.env.VUE_APP_BACKEND_URL : "https://staff.1hourlearning.com/api",
	withCredentials: true
});

// before a request is made start the nprogress
Api.interceptors.request.use((config) => {
	NProgress.start();
	return config;
});

// before a response is returned stop nprogress
Api.interceptors.response.use(
	(response) => {
		NProgress.done();
		return response;
	},
	(error) => {
		NProgress.done();
		if (error.response.status === 401) {
			store.dispatch("logout")
			router.push({ name: "Login" });
		}
		return Promise.reject(error);
	}
);

export default Api;
