<template>
	<button type="submit">
		<slot/>
	</button>
</template>

<script>
export default {
	name: "GuestButton"
}
</script>

<style scoped>
	button {
		text-transform: uppercase;
		outline: 0;
		margin-top: -0.5em;
		background: #41E0D0;
		width: 100%;
		border: 0;
		padding: 15px;
		color: #FFFFFF;
		font-size: 14px;
		-webkit-transition: all 0.3 ease;
		transition: all 0.3 ease;
		cursor: pointer;
	}
	button:hover,
	button:active,
	button:focus {
		background: #34C9B8;
	}

</style>