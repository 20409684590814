<template>
	<main style="margin:3em;">
		<h2 class="">El programa de aprendizaje se distribuye por:</h2>
		<p>
			EDconsulting <br>
			Educational Solutions Ltd. <br>
			20-22 Wenlock Road <br>
			London <br>
			England N1 7GU <br>
		</p>
		<a href="mailto:info@edconsulting.biz" target="_blank">info@EDconsulting.biz</a> <br>
		<a href="https://edconsulting.biz" target="_blank">www.edconsulting.biz</a> <br><br>
		<div class="text-left">
			<Link class="font-weight-bold" to="/"><i class="fas fa-arrow-left"></i> Volver</Link>
		</div>
	</main>
</template>

<script>
import Link from "@/components/Link.vue";
export default {
	name: "ContactPage",
	components: {Link},
};
</script>


<style scoped>
    p {
        padding-top: 10px;
        font-size: 12pt;
    }
    .content-container {
        display: block;
        width: 45vw;
    }

</style>