<template>
	<div class="left-hand-drag none">
		<div class="drag-drop" style="padding-left: 1em; padding-top: 1em">
			<img src="@/assets/images/left-hand.png" alt="left hand" />
			<div class="wrapper left-hand drop">
				<div class="hand dropzone red-bg" data-dropzone="1">
					<div class="check-label" data-correct="1">
						<img
							src="@/assets/images/orange-check.png"
							alt="orange check"
						/>
					</div>
				</div>
				<div class="hand dropzone blue-bg" data-dropzone="2">
					<div class="check-label" data-correct="2">
						<img
							src="@/assets/images/orange-check.png"
							alt="orange check"
						/>
					</div>
				</div>
				<div class="hand dropzone yellow-bg" data-dropzone="3">
					<div class="check-label" data-correct="3">
						<img
							src="@/assets/images/orange-check.png"
							alt="orange check"
						/>
					</div>
				</div>
				<div class="hand dropzone green-bg" data-dropzone="4">
					<div class="check-label" data-correct="4">
						<img
							src="@/assets/images/orange-check.png"
							alt="orange check"
						/>
					</div>
				</div>
				<div class="hand dropzone green-bg" data-dropzone="5">
					<div class="check-label" data-correct="5">
						<img
							src="@/assets/images/orange-check.png"
							alt="orange check"
						/>
					</div>
				</div>
			</div>
			<div class="wrapper drag">
				<div class="dropzone occupied" data-dropzone="3">
					<div class="draggable">D</div>
				</div>
				<div class="dropzone occupied" data-dropzone="5">
					<div class="draggable">G</div>
				</div>
				<div class="dropzone occupied" data-dropzone="1">
					<div class="draggable">A</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable">F</div>
				</div>
				<div class="dropzone occupied" data-dropzone="2">
					<div class="draggable">S</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setupActivityOne } from "@/common/activities";
// import { Draggable } from "@shopify/draggable";
export default {
	name: "First",
	mounted: () => setupActivityOne('wrapper', 'left-hand'),
};
</script>

<style scoped>
</style>