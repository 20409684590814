import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import { windowBlur } from "@/common/helpers";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started("three_activity_right");

	store.getters.animation.value

		.add({
			targets: [".right-hand-drag", "#overlay-animation"],
			opacity: [0, 1],
			easing: "easeInOutSine",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");

				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.2,
					1000,
					store.getters.secondary.value
				);
			},
		})

		.add({
			targets: ["#overlay-animation"],
			duration: 4500,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR44-0"));
			},
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				// start Activity 1
				window.removeEventListener("blur", windowBlur);

				store.dispatch("onActivity");
			},
		})

		// activity 1 stopped
		.add({
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			delay: 1000,
			targets: [".right-hand-drag"],
			duration: 5000,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR44-1"));

				animatables[0].target.classList.add("fade-out");
			},
		})
		.add({
			targets: [".right-hand-drag", ".right-to-left", "#overlay-animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.remove("none");
				animatables[2].target.classList.remove("none");

				store.dispatch("primary", store.getters.howler.value.play("RHBR45-0"));
			},
			duration: 5000,
			complete: ({ animatables }) => {
				animatables[2].target.classList.add("none");
				// Start activity 2
				window.removeEventListener("blur", windowBlur);
				store.dispatch("onActivity");
			},
		})

		// activit 2 stopped
		.add({
			delay: 1000,
			duration: 3000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR45-1"));
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			targets: [".right-to-left"],
			duration: 1000,
			opacity: 0,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// Activity 5 Start
		.add({
			targets: [".home-row", ".upper-row", ".bottom-row"],
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: blueColor;
			},
		})

		.add({
			targets: [".animation", ".keyboard"],
			translateY: ["500px", "0px"],
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
		})
		.add({
			targets: [".keyboard-drag", "#overlay-animation"],
			duration: 13000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("BHBR46-0"));
			},
			complete: ({ animatables }) => {
				animatables[1].target.classList.add("none");

				window.removeEventListener("blur", windowBlur);
				// Start Activity 5
				store.dispatch("onActivity");
			},
		})

		// activit 5 stopped
		.add({
			duration: 3000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})

		.add({
			delay: 1000,
			duration: 19000,
			targets: [".keyboard", ".animation", ".keyboard-drag"],
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("BHBR46-1"));
			},
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
				animatables[2].target.classList.add("none");
				window.removeEventListener("blur", windowBlur);
				store.dispatch("next", true);
			},
		})

		.add({
			delay: 200,
			duration: 41000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("BHBR46-2"));
			},
		})

		.add({
			complete: () => {
				store.dispatch("playing", false);
				//update progress here
				SPTT.finished("three_activity_right");
			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};
export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/bottom_row/activity/right.mp3")],
			sprite: {
				active: [0, 252120],
				"BHBR46-0": [254000, 13196.16780045351],
				"BHBR46-1": [269000, 16056.870748299332],
				"BHBR46-2": [287000, 40610.8163265306],
				"RHBR44-0": [329000, 4498.707482993212],
				"RHBR44-1": [335000, 1868.7074829932158],
				"RHBR45-0": [338000, 5052.403628117929],
				"RHBR45-1": [345000, 1868.6848072562157],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
