import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import $ from "jquery";
import { windowBlur } from "@/common/helpers";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started("three_practice");

	if(store.getters.sptt.value.three_practice_finished)
		store.getters.animation.value
			// RepL3A
			.add({
				delay: 500,
				duration: 3840,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RepL3A"));
				},
			});

	else
		store.getters.animation.value
			// TTBR1
			.add({
				delay: 500,
				duration: 19500,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR1"));
				},
			})

			// TTBR2
			.add({
				delay: 500,
				duration: 14000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR2"));
				},
			})

			// TTBR3
			.add({
				delay: 500,
				duration: 2000,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR3"));
				},
			});


	store.getters.animation.value
		.add({
			begin: () => {
				window.addEventListener("keypress", keyPressEvent);

				window.removeEventListener("blur", windowBlur);
				// practice started
				store.dispatch("onActivity");
			}
		});

	// if not started / not first time
	// set this condition
	if (!store.getters.sptt.value.three_practice_finished)

	store.getters.animation.value
		// timeline starts playing from here after first music and first round of typing
		// after beso
		// TTBR18
		.add({
			duration: 2400,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR18"));
			},
		})
		.add({
			targets: ["#typer-app"],
			translateY: ["0px", "500px"],
			opacity: 0,
			duration: 1000,
			easing: "easeOutSine",
			begin: (anim) => {
				store.dispatch("playing", true);

				window.addEventListener("blur", windowBlur);
			},
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		.add({
			targets: [".animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// slide down keyboard
		.add({
			targets: [".keyboard"],
			opacity: 1,
			translateY: ["-300px", "0px"],
			easing: "easeOutSine",
			duration: 1000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// TTBR19
		.add({
			duration: 4000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR19"));
			},
		})

		// TTBR20
		.add({
			duration: 16500,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR20"));
			},
		})

		.add(
			{
				targets: [".key-enter-head"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=16000"
		)
		.add(
			{
				targets: [".key-enter-leg"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "+=8", duration: 500 },
					{ value: "-=8", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=16000"
		)

		// animate enter key 2
		.add(
			{
				targets: [".key-enter-head"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=13000"
		)
		.add(
			{
				targets: [".key-enter-leg"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "+=8", duration: 500 },
					{ value: "-=8", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=13000"
		)

		// animate enter key 3
		.add(
			{
				targets: [".key-enter-head"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=10000"
		)
		.add(
			{
				targets: [".key-enter-leg"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "+=8", duration: 500 },
					{ value: "-=8", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=10000"
		)

		// animate enter key 4
		.add(
			{
				targets: [".key-enter-head"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=7000"
		)
		.add(
			{
				targets: [".key-enter-leg"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "+=8", duration: 500 },
					{ value: "-=8", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=7000"
		)

		// animate enter key 5
		.add(
			{
				targets: [".key-enter-head"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=4000"
		)
		.add(
			{
				targets: [".key-enter-leg"],
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 1000 },
				],
				top: [
					{ value: "+=8", duration: 500 },
					{ value: "-=8", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=4000"
		)

		// TTBR21
		.add({
			duration: 12000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR21"));
			},
		})

		// animate delete key
		.add(
			{
				targets: "[data-key='8']",
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 8000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 8000 },
				],
				endDelay: 1000,
			},
			"-=10000"
		)

		// TTBR22
		.add({
			duration: 5500,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR22"));
			},
		})

		// animate upper row
		.add(
			{
				targets: [
					'[data-key="49"]',
					'[data-key="50"]',
					'[data-key="51"]',
					'[data-key="52"]',
					'[data-key="53"]',
					'[data-key="54"]',
					'[data-key="55"]',
					'[data-key="56"]',
					'[data-key="57"]',
					'[data-key="48"]',
				],
				backgroundColor: [
					{
						value: (el) => {
							return el.classList.contains("red-button")
								? lightRedColor
								: el.classList.contains("green-button")
								? lightGreenColor
								: el.classList.contains("yellow-button")
								? lightYellowColor
								: lightBlueColor;
						},
						duration: 500,
					},
					{
						value: (el) => {
							return el.classList.contains("red-button")
								? redColor
								: el.classList.contains("green-button")
								? greenColor
								: el.classList.contains("yellow-button")
								? yellowColor
								: blueColor;
						},
						duration: 500,
						delay: 3000,
					},
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 3000 },
				],
				endDelay: 1000,
			},
			"-=3000"
		)

		// TTBR23
		.add({
			duration: 5500,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR23"));
			},
		})

		// animate upper row keys one by one quickly
		.add(
			{
				delay: anime.stagger(1000),
				targets: [
					'[data-key="49"]',
					'[data-key="50"]',
					'[data-key="51"]',
					'[data-key="52"]',
					'[data-key="53"]',
					'[data-key="54"]',
					'[data-key="55"]',
					'[data-key="56"]',
					'[data-key="57"]',
					'[data-key="48"]',
				],
				backgroundColor: [
					{
						value: (el) => {
							return el.classList.contains("red-button")
								? lightRedColor
								: el.classList.contains("green-button")
								? lightGreenColor
								: el.classList.contains("yellow-button")
								? lightYellowColor
								: lightBlueColor;
						},
						duration: 500,
					},
					{
						value: (el) => {
							return el.classList.contains("red-button")
								? redColor
								: el.classList.contains("green-button")
								? greenColor
								: el.classList.contains("yellow-button")
								? yellowColor
								: blueColor;
						},
						duration: 500,
						delay: 1000,
					},
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 1000 },
				],
				endDelay: 1000,
			},
			"-=3000"
		)

		// TTBR24
		.add({
			duration: 29000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR24"));
			},
		})

		// animate upper row keys one by one in sync

		// animate 1
		.add(
			{
				targets: '[data-key="49"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=28000"
		)

		// animate 2
		.add(
			{
				targets: '[data-key="50"]',
				backgroundColor: [
					{ value: lightBlueColor, duration: 500 },
					{ value: blueColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=25000"
		)

		// animate 3
		.add(
			{
				targets: '[data-key="51"]',
				backgroundColor: [
					{ value: lightYellowColor, duration: 500 },
					{ value: yellowColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=22000"
		)

		// animate 4,5
		.add(
			{
				targets: ['[data-key="52"]', '[data-key="53"]'],
				backgroundColor: [
					{ value: lightGreenColor, duration: 500 },
					{ value: greenColor, duration: 500, delay: 3000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 3000 },
				],
			},
			"-=19000"
		)

		// animate 6,7
		.add(
			{
				targets: ['[data-key="54"]', '[data-key="55"]'],
				backgroundColor: [
					{ value: lightGreenColor, duration: 500 },
					{ value: greenColor, duration: 500, delay: 3000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 3000 },
				],
			},
			"-=15000"
		)

		// animate 8
		.add(
			{
				targets: '[data-key="56"]',
				backgroundColor: [
					{ value: lightYellowColor, duration: 500 },
					{ value: yellowColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=11000"
		)

		// animate 9
		.add(
			{
				targets: '[data-key="57"]',
				backgroundColor: [
					{ value: lightBlueColor, duration: 500 },
					{ value: blueColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=8000"
		)

		// animate 0
		.add(
			{
				targets: '[data-key="48"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 3000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 3000 },
				],
			},
			"-=5000"
		)

		// TTBR25
		.add({
			duration: 4500,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR25"));
			},
		})

		// TTBR26
		.add({
			duration: 14000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR26"));
			},
		})

		// animate 0
		.add(
			{
				targets: '[data-key="48"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=13000"
		)

		// animate delete
		.add(
			{
				targets: '[data-key="8"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=10000"
		)

		// animate question mark
		.add(
			{
				targets: '[data-key="189"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=7000"
		)

		// animate inverted question mark
		.add(
			{
				targets: '.row__one [data-key="191"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=4000"
		)

		// TTBR27
		.add({
			duration: 14300,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR27"));
			},
		})

		// animate left shift
		.add(
			{
				targets: '[data-key="16"]',
				backgroundColor: [{ value: lightRedColor, duration: 1000 }],
				top: [{ value: "0.2em", duration: 1000 }],
			},
			"-=12000"
		)

		// animate question mark
		.add(
			{
				targets: '[data-key="189"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=7000"
		)

		// animate inverted question mark
		.add(
			{
				targets: '.row__one [data-key="191"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 1000 },
					{ value: redColor, duration: 1000, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 1000 },
					{ value: "0em", duration: 1000, delay: 2000 },
				],
			},
			"-=3000"
		)

		// animate left shift to normal
		.add(
			{
				targets: '[data-key="16"]',
				backgroundColor: [{ value: redColor, duration: 1000 }],
				top: [{ value: "0em", duration: 1000 }],
			},
			"-=1000"
		)

		// TTBR28
		.add({
			duration: 4000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR28"));
			},
		})

		// TTBR29
		.add({
			duration: 12000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR29"));
			},
		})

		// animate 1 ( exclamation mark )
		.add(
			{
				targets: '[data-key="49"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 10000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 10000 },
				],
			},
			"-=11000"
		)

		// animate right shift key
		.add(
			{
				targets: '[data-key="16-R"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 10000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 10000 },
				],
			},
			"-=11000"
		)

		// TTBR30
		.add({
			duration: 15000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR30"));
			},
		})

		// animate inverted question and inverted exclamation button
		.add(
			{
				targets: '.row__one [data-key="191"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 12000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 12000 },
				],
			},
			"-=14000"
		)

		// TTBR32
		.add({
			duration: 24500,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR32"));
			},
		})

		// animate @(2)
		.add(
			{
				targets: '[data-key="50"]',
				backgroundColor: [
					{ value: lightBlueColor, duration: 500 },
					{ value: blueColor, duration: 500, delay: 2500 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2500 },
				],
			},
			"-=24000"
		)
		// animate @(Q)
		.add(
			{
				targets: '[data-char="Q"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2500 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2500 },
				],
			},
			"-=24000"
		)

		// animate @(2)
		.add(
			{
				targets: '[data-key="50"]',
				backgroundColor: [
					{ value: lightBlueColor, duration: 500 },
					{ value: blueColor, duration: 500, delay: 4000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 4000 },
				],
			},
			"-=20500"
		)

		// animate @(Q)
		.add(
			{
				targets: '[data-char="Q"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 2000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 2000 },
				],
			},
			"-=15500"
		)




		// animate @(2)
		.add(
			{
				targets: '[data-key="50"]',
				backgroundColor: [
					{ value: lightBlueColor, duration: 500 },
					{ value: blueColor, duration: 500, delay: 11000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 11000 },
				],
			},
			"-=12500"
		)
		// animate @(Q)
		.add(
			{
				targets: '[data-char="Q"]',
				backgroundColor: [
					{ value: lightRedColor, duration: 500 },
					{ value: redColor, duration: 500, delay: 11000 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 11000 },
				],
			},
			"-=12500"
		)
		

		// animate ALt gr (right to space)
		.add(
			{
				targets: '.row__five [data-key="93-R"]',
				backgroundColor: [
					{ value: lightGreyColor, duration: 500 },
					{ value: greyColor, duration: 500, delay: 9500 },
				],
				top: [
					{ value: "0.2em", duration: 500 },
					{ value: "0em", duration: 500, delay: 9500 },
				],
			},
			"-=11000"
		)

		// TTBR33
		.add({
			duration: 9900,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("TTBR33"));
			},
		})

		// slide up keyboard
		.add({
			duration: 4000,
			targets: [".animation", ".keyboard"],
			opacity: 0,
			translateY: ["0px", "-500px"],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})

		// slide up typer
		.add({
			targets: ["#typer-app"],
			translateY: ["500px", "0px"],
			opacity: 1,
			duration: 3000,
			easing: "easeOutSine",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			complete: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("onActivity");
			},
		});

	if(store.getters.sptt.value.three_practice_finished)
		store.getters.animation.value
			// RepL3B
			.add({
				duration: 4930,
				begin: (anim) => {
					store.dispatch("primary", store.getters.howler.value.play("RepL3B"));
				},
				complete: () => {
					$(".timeline-controls").addClass('none');
				},
			});
	else
		store.getters.animation.value

			// timeline resumes from here
			.add({duration:2000})
			// slide down typer
			.add({
				delaly: 3000,
				targets: ["#typer-app"],
				translateY: ["0px", "500px"],
				opacity: 0,
				duration: 2000,
				easing: "easeOutSine",
				begin: (anim) => {
					// update progress here
					window.removeEventListener("keypress", keyPressEvent);

					window.addEventListener("blur", windowBlur);
					store.dispatch("playing", true);
					store.dispatch("activity", false);
				},
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})

			// show animation
			.add({
				targets: [".animation"],
				opacity: 1,
				translateY: "0px",
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// slide in golden cup
			.add({
				targets: [".golden-cup"],
				opacity: [0, 1],
				translateX: ["-500px", "0px"],
				duration: 1000,
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// Play TTBR34
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR34"));
				},
				duration: 17400,
			})

			// Play TTBR35
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR35"));
				},
				duration: 5000,
			})

			// slide out golden cup
			.add({
				targets: [".golden-cup"],
				opacity: [1, 0],
				translateX: ["0px", "-500px"],
				duration: 1000,
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
				},
			})

			// slide in gold cup fancy
			.add({
				targets: [".letters-fancy"],
				opacity: [0, 1],
				translateX: ["500px", "0px"],
				duration: 1000,
				begin: ({ animatables }) => {
					animatables[0].target.classList.remove("none");
				},
			})

			// Play TTBR36
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR36"));
				},
				duration: 11000,
			})

			// display word Tecleo mix
			.add({
				targets: ['.tecleo_text'],
				begin: ({animatables}) => {
					animatables[0].target.classList.remove('none');
				},
			}, "-=2500")

			// Play TTBR37
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR37"));
				},
				duration: 13000,
			})
			// Play TTBR38
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR38"));
				},
				duration: 4100,
			})
			// Play TTBR39
			.add({
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR39"));
				},
				duration: 17000,
			})
			// Play TTBR40
			.add({
				targets: [".letters-fancy", ".animation"],
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTBR40"));
				},
				complete: ({ animatables }) => {
					animatables[0].target.classList.add("none");
					animatables[1].target.classList.add("none");
				},
				duration: 3600,
			})
			// Play TTBR41
			.add({
				duration: 14000,
				begin: () => {
					store.dispatch("next", true);
					store.dispatch("primary", store.getters.howler.value.play("TTBR41"));
				},
			});

		
	store.getters.animation.value
		.add({
			begin: ()=>{
				store.dispatch("playing", false);				
				// update progress here
				SPTT.finished("three_practice");
				window.removeEventListener("blur", windowBlur);
			}
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);
	window.removeEventListener("keypress", keyPressEvent);

	store.getters.animation.value.play();

	window.addEventListener("keydown", (e) => {
		if (e.which == 222 || e.which == 219) store.dispatch("accent_down", true);
	});
	window.addEventListener("keypress", (e) => {
		if (e.which == 222 || e.which == 219) store.dispatch("accent_down", true);
	});
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/bottom_row/practice.mp3")],
			sprite: {
				RepL3A: [0, 3827.4603174603176],
				RepL3B: [5000, 4921.0204081632655],
				TTBR1: [11000, 19076.87074829932],
				TTBR16: [32000, 2274.0589569161],
				TTBR17: [36000, 7428.616780045352],
				TTBR18: [45000, 2375.147392290252],
				TTBR19: [49000, 3815.3968253968246],
				TTBR2: [54000, 13947.596371882086],
				TTBR20: [69000, 16348.004535147396],
				TTBR21: [87000, 11597.73242630385],
				TTBR22: [100000, 5255.623582766447],
				TTBR23: [107000, 5331.428571428574],
				TTBR24: [114000, 28526.84807256236],
				TTBR25: [144000, 4472.33560090703],
				TTBR26: [150000, 13770.725623582763],
				TTBR27: [165000, 14250.79365079364],
				TTBR28: [181000, 3764.8299319727985],
				TTBR29: [186000, 11976.734693877546],
				TTBR3: [199000, 1970.8616780045247],
				TTBR30: [202000, 14907.755102040825],
				TTBR31: [218000, 23372.335600907034],
				TTBR32: [243000, 24433.537414966],
				TTBR33: [269000, 9803.74149659866],
				TTBR34: [280000, 17343.673469387737],
				TTBR35: [299000, 4813.990929705198],
				TTBR36: [305000, 10850.204081632626],
				TTBR37: [317000, 12621.15646258502],
				TTBR38: [331000, 4040.7709750567165],
				TTBR39: [337000, 16751.337868480732],
				TTBR40: [355000, 3516.961451247141],
				TTBR41: [360000, 13918.049886621304]
			},			
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};

export const setupPractice = () => {
	store.dispatch(
		"text",
		"aza|sxs|dcd|fvf|fbf|ñ-ñ|l.l|k,k|jmj|jnj|vaso|vale|vela|visa|vida|vivo|diva|lava|cole|cada|casa|doce|loco|laca|saco|seco|boda|bola|bajo|beso|zumo|zona|un taxi azul|textos extras|casa-barco|tel.|etc.|o sea,|es decir,|luna|mesa|moda|moto|dama|lomo|alma|amar|nada|nido|niña|los niños son sanos"
	);
	store.dispatch(
		"formatted_text",
		store.getters.text.value.split("|").join(" ")
	);
	store.dispatch(
		"typing_text",
		store.getters.text.value
			.split("|")
			.map((word) => (word + " ").repeat(3))
			.join("")
			.trimRight()
	);

	$("#text").text(store.getters.formatted_text.value);
	$("#typing-text").text(
		store.getters.typing_text.value.slice(
			store.getters.curr_pointer.value,
			store.getters.curr_pointer.value + 50
		)
	);
};

export const keyPressEvent = function (evt) {
	if (!store.getters.activity.value) return;

	// after beso 3 times , show animation
	// actual 30
	if (
		!store.getters.sptt.value.three_practice_finished &&
		store.getters.word_count.value / 3 == 30 &&
		store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
	) {
		store.dispatch("offActivity");
	}

	// highlighting text finished
	if (
		store.getters.typing_text.value.length ==
		store.getters.curr_pointer.value + 1
	) {
		$("#text").html(
			'<span class="green">' + store.getters.formatted_text.value + "</span>"
		);
		$("#typing-text").addClass("none");
		window.addEventListener("blur", windowBlur);
		store.dispatch("offActivity");
	} else
		$("#text").html(
			'<span class="green">' +
				store.getters.formatted_text.value
					.split(" ")
					.slice(0, store.getters.word_count.value / 3)
					.join(" ") +
				"</span> <span>" +
				store.getters.formatted_text.value
					.split(" ")
					.slice(store.getters.word_count.value / 3)
					.join(" ") +
				"</span>"
		);

	if (
		String.fromCharCode(evt.charCode) == "í" ||
		(!store.getters.accent_down.value &&
			store.getters.typing_text.value[store.getters.curr_pointer.value] ===
				evt.key) ||
		(store.getters.accent_down.value &&
			evt.key == "i" &&
			store.getters.typing_text.value[store.getters.curr_pointer.value] == "í")
	) {
		store.dispatch("curr_pointer", store.getters.curr_pointer.value + 1);
		if (evt.shiftKey || store.getters.accent_down.value)
			store.dispatch("strokes_count", store.getters.strokes_count.value + 2);
		else store.dispatch("strokes_count", store.getters.strokes_count.value + 1);

		if (
			store.getters.typing_text.value[store.getters.curr_pointer.value] === " "
		) {
			store.dispatch("word_count", store.getters.word_count.value + 1);
		}
		$("#progress").css(
			"width",
			(store.getters.curr_pointer.value /
				store.getters.typing_text.value.length) *
				600
		);
		$("#wordcount").text(Math.floor(store.getters.strokes_count.value / 5));
		// highlighting typing row
		$("#typing-text").html(
			'<span class="green">' +
				store.getters.typing_text.value
					.slice(0, store.getters.curr_pointer.value)
					.slice(-10) +
				"</span>" +
				"<span class='underline'>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value,
					store.getters.curr_pointer.value + 1
				) +
				"</span>" +
				"<span>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value + 1,
					store.getters.curr_pointer.value + 50
				) +
				"</span>"
		);
	} else {
		if (
			store.getters.curr_pointer.value <
			store.getters.typing_text.value.length - 1
		)
			$("#typing-text").html(
				'<span class="green">' +
					store.getters.typing_text.value
						.slice(0, store.getters.curr_pointer.value)
						.slice(-10) +
					"</span>" +
					'<span class="red">' +
					store.getters.typing_text.value[store.getters.curr_pointer.value] +
					"</span><span>" +
					store.getters.typing_text.value.slice(
						store.getters.curr_pointer.value + 1,
						store.getters.curr_pointer.value + 50
					) +
					"</span>"
			);
	}
	if (store.getters.accent_down.value) store.dispatch("accent_down", false);
};
