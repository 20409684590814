import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import { windowBlur } from "@/common/helpers";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('two_activity_right');

	store.getters.animation.value
		.add({
			targets: [".right-hand-drag", "#overlay-animation"],
			opacity: [0, 1],
			easing: "easeInOutSine",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");

				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.2,
					1000,
					store.getters.secondary.value
				);
			},
		})

		.add({
			targets: ["#overlay-animation"],
			duration: 2900,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR44"));
			},
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				// start Activity 1
				window.removeEventListener("blur", windowBlur);

				store.dispatch("onActivity");
			},
		})

		// activity 1 stopped
		.add({
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			delay: 1000,
			targets: [".right-hand-drag"],
			duration: 5000,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("RHUR44-1"));

				animatables[0].target.classList.add("fade-out");
			},
		})
		.add({
			targets: [".right-hand-drag", ".right-to-left", "#overlay-animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.remove("none");
				animatables[2].target.classList.remove("none");

				store.dispatch("primary", store.getters.howler.value.play("RHUR45"));
			},
			duration: 1500,
			complete: ({ animatables }) => {
				animatables[2].target.classList.add("none");
				// Start activity 2
				window.removeEventListener("blur", windowBlur);
				store.dispatch("onActivity");
			},
		})

		// activit 2 stopped
		.add({
			duration: 3000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			delay: 1000,
			targets: [".right-to-left"],
			duration: 1000,
			opacity: 0,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// Activity 5 Start
		.add({
			targets: [
				".row__one .red-button",
				".row__one .yellow-button",
				".row__one .green-button",
				".row__one .blue-button",
				".home-row",
				".upper-row",
				".bottom-row",
				"[data-key='93-R']",
			],
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: el.classList.contains("blue-button")
					? blueColor
					: greyColor;
			},
		})
		.add({
			targets: [
				"[data-key='13'] img",
				"[data-key='8'] img",
				"[data-char='Q'] .bottom-right",
			],
			opacity: 0,
			complete: ({animatables})=>{
				animatables[0].target.classList.add('none');
				animatables[1].target.classList.add('none');
			}
		})
		.add({
			targets: [".animation", ".keyboard"],
			translateY: ["500px", "0px"],
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
		})
		.add({
			targets: [".keyboard-drag", "#overlay-animation"],
			duration: 7000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("BHUR46"));
			},
			complete: ({ animatables }) => {
				animatables[1].target.classList.add("none");

				window.removeEventListener("blur", windowBlur);
				// Start Activity 5
				store.dispatch("onActivity");
			},
		})

		// activit 5 stopped
		.add({
			duration: 3000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})

		.add({
			delay: 1000,
			duration: 16000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("BHUR46-1"));
			},
		})

		.add({
			targets: [".keyboard", ".animation", ".keyboard-drag"],
			duration: 9000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
				animatables[2].target.classList.add("none");
			
				
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);
				store.dispatch("next", true);				
				//update progress here
				SPTT.finished('two_activity_right');

				store.dispatch("primary", store.getters.howler.value.play("BHUR47"));
			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/upper_row/activity/right.mp3")],
			sprite: {
				active: [0, 252120, true],
				"BHUR46-1": [254000, 15761.700680272099],
				BHUR46: [271000, 7087.392290249454],
				BHUR47: [280000, 8991.564625850344],
				"RHUR44-1": [290000, 1707.9818594104381],
				RHUR44: [293000, 2891.4512471655485],
				"RHUR45-1": [297000, 2514.875283446713],
				RHUR45: [301000, 1586.9387755101911]
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
