<template>
	<main style="margin:3em;">
		<div class="content-container">
			<h2 class="">Quienes somos</h2>
			<p>
				Somos <span style="font-size: 13pt;">especialistas</span> en el desarrollo de programas de aprendizaje 
				que trabajan con técnicas de enseñanza aceleradas.<br><br>
				Nuestros <span style="font-size: 13pt;">más de 20 años</span> de experiencia en el sector educativo nos distinguen y, sin
				embargo, somos una bocanada de aire fresco.<br><br>
				Aquí tienes la página web de la agencia que desarrolló el producto y que también es actualmente responsable de las ventas: <a href="https://www.edconsulting.biz" target="_blank">www.EDconsulting.biz</a><br>
			</p>
		</div>
		<div class="text-left">
			<Link class="font-weight-bold" to="/"><i class="fas fa-arrow-left"></i> Volver</Link>
		</div>
	</main>
</template>

<script>
import Link from "@/components/Link.vue";
export default {
	name: "AboutPage",
	components: {Link},
};
</script>


<style scoped>
    p {
        padding-top: 10px;
        font-size: 12pt;
    }
    .content-container {
        display: block;
        width: 45vw;
    }

</style>