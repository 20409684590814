<template>
	<div class="key-text-right none">
		<h2 class="red-text text-hyphen">-</h2>
		<h2 class="blue-text text-punto">.</h2>
		<h2 class="yellow-text text-comma">,</h2>
		<h2 class="green-text text-m">M</h2>
		<h2 class="green-text text-n">N</h2>
	</div>

</template>

<script>
export default {
	name: "KeyText",
};
</script>

<style scoped>
</style>