<template>
	<div class="keyboard">
		<RowOne/>
		<RowTwo/>
		<RowThree/>
		<RowFour/>
		<RowFive/>
	</div>
</template>

<script>
import RowFive from './RowFive.vue'
import RowFour from './RowFour.vue'
import RowThree from './RowThree.vue'
import RowTwo from './RowTwo.vue'
import RowOne from './RowOne.vue'
export default {
	components: { RowOne, RowTwo, RowThree, RowFour, RowFive },
	name: "Keyboard",
};
</script>

<style scoped>
</style>