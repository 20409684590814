<template>
	<div style="height: 15vh;">
		<SiteLogo />
	</div>
	<div style="height: 70vh; overflow-y: auto; overflow-x: hidden">
		<nav class="left-nav navbar p-2">
			<ul class="navbar-nav">
				<!-- <li class="nav-item">
					<Link to="/introduction" class="nav-link" :class="sptt.introduction ? 'disabled text-secondary':''">Introducción</Link>
				</li> -->
				<li class="nav-item dropdown">
					<button
						@click.prevent
						class="nav-link dropdown-toggle"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						Etapa 1<br /><small>(Fila básica)</small>
					</button>

					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
						<Link class="dropdown-item" to="/one/learn/both" :class="sptt.one_learn_both ? 'disabled text-secondary':''">1 - Inicio</Link>
						<Link class="dropdown-item" to="/one/learn/left" :class="sptt.one_learn_left ? 'disabled text-secondary':''">2 - Aprendizaje</Link>
						<Link class="dropdown-item" to="/one/activity/left" :class="sptt.one_activity_left ? 'disabled text-secondary':''">3 - Actividades</Link>
						<Link class="dropdown-item" to="/one/learn/right" :class="sptt.one_learn_right ? 'disabled text-secondary':''">4 - Aprendizaje</Link>
						<Link class="dropdown-item" to="/one/activity/right" :class="sptt.one_activity_right ? 'disabled text-secondary':''">5 - Actividades</Link>
						<Link class="dropdown-item" to="/one/practice" :class="sptt.one_practice ? 'disabled text-secondary':''">Entreno</Link>
					</div>
				</li>
				<li class="nav-item dropdown">
					<button
						@click.prevent
						class="nav-link dropdown-toggle"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						Etapa 2 <br /><small>(Fila superior)</small>
					</button>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
						<Link class="dropdown-item" to="/two/learn/both" :class="sptt.two_learn_both ? 'disabled text-secondary':''">1 - Inicio</Link>
						<Link class="dropdown-item" to="/two/learn/left" :class="sptt.two_learn_left ? 'disabled text-secondary':''">2 - Aprendizaje</Link>
						<Link class="dropdown-item" to="/two/activity/left" :class="sptt.two_activity_left ? 'disabled text-secondary':''">3 - Actividades</Link>
						<Link class="dropdown-item" to="/two/learn/right" :class="sptt.two_learn_right ? 'disabled text-secondary':''">4 - Aprendizaje</Link>
						<Link class="dropdown-item" to="/two/activity/right" :class="sptt.two_activity_right ? 'disabled text-secondary':''">5 - Actividades</Link>
						<Link class="dropdown-item" to="/two/practice" :class="sptt.two_practice ? 'disabled text-secondary':''">Entreno</Link>
					</div>
				</li>
				<li class="nav-item dropdown">
					<button
						@click.prevent
						class="nav-link dropdown-toggle"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						Etapa 3 <br /><small>(Fila inferior)</small>
					</button>
					<div class="dropdown-menu" aria-labelledby="navbarDropdown">
						<Link class="dropdown-item" to="/three/learn/both" :class="sptt.three_learn_both ? 'disabled text-secondary':''">1 - Inicio</Link>
						<Link class="dropdown-item" to="/three/learn/left" :class="sptt.three_learn_left ? 'disabled text-secondary':''">2 - Aprendizaje</Link>
						<Link class="dropdown-item" to="/three/activity/left" :class="sptt.three_activity_left ? 'disabled text-secondary':''">3 - Actividades</Link>
						<Link class="dropdown-item" to="/three/learn/right" :class="sptt.three_learn_right ? 'disabled text-secondary':''">4 - Aprendizaje</Link>
						<Link class="dropdown-item" to="/three/activity/right" :class="sptt.three_activity_right ? 'disabled text-secondary':''">5 - Actividades</Link>
						<Link class="dropdown-item" to="/three/practice" :class="sptt.three_practice ? 'disabled text-secondary':''">Entreno</Link>
					</div>
				</li>
				<li class="nav-item">
					<Link class="nav-link" to="/mix" :class="sptt.mix ? 'disabled text-secondary':''">Tecleo mix</Link>
				</li>
				<div class="dropdown-divider"></div>
				<li class="nav-item">
					<Link to="/profile" class="nav-link">Completado</Link>
				</li>

				<div class="dropdown-divider"></div>
				<li class="nav-item">
					<a class="nav-link" href='https://1horatecleando.com/acelerador1' target="_blank">
						<div style="white-space: nowrap;">Acelerador I</div> 
						<div class="oblique-text">
							<small>¡Incluido</small>
							<small>gratis!</small>
						</div>
					</a>
				</li>
				<div class="dropdown-divider"></div>
				<li class="nav-item">
					<a type="button" class="nav-link" data-toggle="modal" data-target="#preparationModal" >
						<div style="white-space: nowrap;">Acelerador II</div>
						<div class="oblique-text">
							<small>Próximamente</small>
						</div>

					</a>
				</li>
				<div class="dropdown-divider"></div>
				<li class="nav-item">
					<button class="nav-link" @click.prevent="handleLogout" id="logout">Cerrar sesión</button>
				</li>				
			</ul>
		</nav>
	</div>
	<PreparationModal />
</template>

<script>
import PreparationModal from './PreparationModal.vue';
import Link from "../../components/Link.vue";
import SiteLogo from "../../components/SiteLogo.vue";
import MediaControl from "./MediaControl.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";


import User from "@/api/User";
import SPTT from "@/api/SPTT";

export default {
	components: { MediaControl, SiteLogo, Link, PreparationModal },
	name: "AuthSidebar",
	setup() {

		const store = useStore();
		const router = useRouter();


		onMounted(() => SPTT.all());

		const handleLogout = () => {
			User.logout().then((res) => {
				store.dispatch('logout');
				router.push({ name: "Home" });
			});
		};
		return {
			handleLogout,
			sptt: store.getters.sptt,
		};
	},
};
</script>

<style scoped>
sup{
	left: -0.5em;
}
.oblique-text{
	color:red;
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	transform: rotate(-18deg);
}
.dropdown-item {
	color: black;
}
.nav-link {
	color: #3ccfcf;
	transition-property: color;
	transition-duration: 0.3s;
	font-weight: bold;
}

.nav-link:hover {
	color: var(--color-navy-blue);
	text-decoration: none;
}

.nav-item button{
	background: transparent;
	border: none;
	width: 100%;
	display:flex;
	justify-content: space-between;
}


/* custom scrollbar */
::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: #d6dee1;
	border-radius: 10px;
	border: 2px solid transparent;
	background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #a8bbbf;
}
</style>
