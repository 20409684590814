<template>
	<div class="slides none">
		<div class="slide-1 red-text">
			<div class="flex">
				<h2 class="letter">A</h2>
				<div>
					<img
						class="symbol"
						src="@/assets/images/1-bus-min.png"
						alt="autobus"
					/>
				</div>
			</div>

			<h2 class="word">Autobús</h2>
		</div>
		<div class="slide-2 blue-text">
			<div class="flex">
				<h2 class="letter">S</h2>
				<div>
					<img
						src="@/assets/images/3-sofa-min.png"
						class="symbol"
						alt="Sofa"
					/>
				</div>
			</div>
			<h2 class="word">Sofá</h2>
		</div>
		<div class="slide-3 yellow-text">
			<div class="flex">
				<h2 class="letter">D</h2>
				<div>
					<img
						src="@/assets/images/5-dune-min.png"
						class="symbol"
						alt="Sand Dune"
					/>
				</div>
			</div>
			<h2 class="word">Duna</h2>
		</div>
		<div class="slide-4 green-text">
			<div class="flex">
				<h2 class="letter">F</h2>
				<div>
					<img
						src="@/assets/images/7-lighhouse-min.png"
						class="symbol"
						alt="lighthouse"
					/>
				</div>
			</div>
			<h2 class="word">Faro</h2>
		</div>
		<div class="slide-5 green-text">
			<div class="flex">
				<h2 class="letter">G</h2>
				<div>
					<img
						src="@/assets/images/8-sun-glasses-min.png"
						class="symbol"
						alt="gafas"
					/>
				</div>
			</div>
			<h2 class="word">Gafas de sol</h2>
		</div>
	</div>

</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>