// Auth Pages
const layout = "Auth"
export default [
	{
		path: "/profile",
		name: "Profile",
		component: () => import("@/views/Auth/Profile"),
		meta: { layout , paidOnly: false},
	},

	{
		path: "/introduction",
		name: "Introduction",
		component: () => import("@/views/Auth/Introduction"),
		meta: { layout , paidOnly: true, lesson: 'introduction'},
	},

	// Lesson 1
	{
		path: "/one/learn/both",
		name: "OneLearnBoth",
		component: () => import("@/views/Auth/One/LearnBoth"),
		meta: { layout, controls: true , paidOnly: true , lesson: 'one_learn_both'},
	},
	{
		path: "/one/learn/left",
		name: "OneLearnLeft",
		component: () => import("@/views/Auth/One/LearnLeft"),
		meta: { layout, controls: true , paidOnly: true, lesson: 'one_learn_left'},
	},

	{
		path: "/one/activity/left",
		name: "OneActivityLeft",
		component: () => import("@/views/Auth/One/ActivityLeft"),
		meta: { layout, controls: true , paidOnly: true, lesson: 'one_activity_left'},
	},

	{
		path: "/one/learn/right",
		name: "OneLearnRight",
		component: () => import("@/views/Auth/One/LearnRight"),
		meta: { layout, controls: true , paidOnly: true, lesson: 'one_learn_right'},
	},

	{
		path: "/one/activity/right",
		name: "OneActivityRight",
		component: () => import("@/views/Auth/One/ActivityRight"),
		meta: { layout, controls: true , paidOnly: true, lesson: 'one_activity_right'},
	},

	{
		path: "/one/practice",
		name: "OnePractice",
		component: () => import("@/views/Auth/One/Practice"),
		meta: { layout, controls: true , paidOnly: true, lesson: 'one_practice'},
	},



	// Lesson 2
	{
		path: "/two/learn/both",
		name: "TwoLearnBoth",
		component: () => import("@/views/Auth/Two/LearnBoth"),
		meta: { layout, controls: true , lesson: 'two_learn_both'},
	},
	{
		path: "/two/learn/left",
		name: "TwoLearnLeft",
		component: () => import("@/views/Auth/Two/LearnLeft"),
		meta: { layout, controls: true , lesson: 'two_learn_left'},
	},

	{
		path: "/two/activity/left",
		name: "TwoActivityLeft",
		component: () => import("@/views/Auth/Two/ActivityLeft"),
		meta: { layout, controls: true , lesson: 'two_activity_left'},
	},

	{
		path: "/two/learn/right",
		name: "TwoLearnRight",
		component: () => import("@/views/Auth/Two/LearnRight"),
		meta: { layout, controls: true , lesson: 'two_learn_right'},
	},

	{
		path: "/two/activity/right",
		name: "TwoActivityRight",
		component: () => import("@/views/Auth/Two/ActivityRight"),
		meta: { layout, controls: true , lesson: 'two_activity_right'},
	},

	{
		path: "/two/practice",
		name: "TwoPractice",
		component: () => import("@/views/Auth/Two/Practice"),
		meta: { layout, controls: true , lesson: 'two_practice'},
	},

	




	// Lesson 3
	{
		path: "/three/learn/both",
		name: "ThreeLearnBoth",
		component: () => import("@/views/Auth/Three/LearnBoth"),
		meta: { layout, controls: true , lesson: 'three_learn_both'},
	},
	{
		path: "/three/learn/left",
		name: "ThreeLearnLeft",
		component: () => import("@/views/Auth/Three/LearnLeft"),
		meta: { layout, controls: true , lesson: 'three_learn_left'},
	},

	{
		path: "/three/activity/left",
		name: "ThreeActivityLeft",
		component: () => import("@/views/Auth/Three/ActivityLeft"),
		meta: { layout, controls: true , lesson: 'three_activity_left'},
	},

	{
		path: "/three/learn/right",
		name: "ThreeLearnRight",
		component: () => import("@/views/Auth/Three/LearnRight"),
		meta: { layout, controls: true , lesson: 'three_learn_right'},
	},

	{
		path: "/three/activity/right",
		name: "ThreeActivityRight",
		component: () => import("@/views/Auth/Three/ActivityRight"),
		meta: { layout, controls: true , lesson: 'three_activity_right'},
	},

	{
		path: "/three/practice",
		name: "ThreePractice",
		component: () => import("@/views/Auth/Three/Practice"),
		meta: { layout, controls: true , lesson: 'three_practice'},
	},


	// Tecleo Mix
	{
		path: "/mix",
		name: "MixPractice",
		component: () => import("@/views/Auth/Mix"),
		meta: { layout, controls: true , lesson: 'mix'},
	},
	
]