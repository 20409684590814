<template>		
	<Loader v-if="loading"/>
	<StartButton v-if="start" @click="startLesson"/>

	<div id="video-container" style="height:90vh; width: 100%;" :class="loading || start ? 'none':''">
		<video ref="video" poster="@/assets/images/explainer.png" controls="controls" controlsList=”nodownload”
		id="explainer-video" style="object-fit: fill;" playsinline @ended="endLesson" @loadeddata="loaded">
		<source src="@/assets/videos/ExplainerVideo1.mp4" type="video/mp4"></video>
	</div>
	<AuthButton v-if="next" to="/one/learn/both"  style="position: static;">¡Empezamos!</AuthButton>
</template>

<script>
import AuthButton from '@/components/AuthButton.vue'
import StartButton from '@/components/StartButton.vue'
import Loader from '@/components/Loader.vue'

import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import SPTT from "@/api/SPTT";

export default {
	name: "Introduction",
	components: { Loader, StartButton, AuthButton},
	setup () {
		const video = ref(null);
		const store = useStore();

		store.dispatch('loading',true);
		store.dispatch('start',false);
		store.dispatch('next',false);

		watch(store.getters.requesting, ()=>{
			if(!store.getters.requesting.value){
				store.dispatch('loading',false);
				store.dispatch('start',true);
			}else{
				store.dispatch('loading',true);
				store.dispatch('start',false);
			}
		})

		const loaded = ()=>{
			store.dispatch('loading',false);
			store.dispatch('start',true);
		}

		const startLesson = ()=>{
			store.dispatch('start',false);
			video.value.play();
			SPTT.started('introduction');
		}
		const endLesson = ()=>{
			store.dispatch('next',true);
			video.value.load();
			SPTT.finished('introduction');
		}
		
		return {
			loading: store.getters.loading,
			start: store.getters.start,
			next: store.getters.next,

			loaded,
			startLesson,
			endLesson,
			video,
		}
	},
};
</script>

<style scoped>
	#explainer-video{
		width:100%;
		height:100%;
	}
	.lesson-finished{
		color: #33649f;
	}
</style>