<template>
	<router-link class="navbar-brand m-0" to="/" style="height: 50px;">
		<img src="@/assets/images/logo.png" class="img-fluid" style="height: 50px;" alt="logo">
	</router-link>
</template>

<script>
export default {
	name: "SiteLogo"
}
</script>

<style>

</style>