<template>
	<div class="slides none">
		<div class="slide-1 red-text">
			<div class="flex">
				<h2 class="letter">Z</h2>
				<div>
					<img class="symbol" src="@/assets/images/12-Glass-filled-with-Tomato-Juice-(1)-min.png" alt="Zumo" />
				</div>
			</div>

			<h2 class="word">Zumo</h2>
		</div>
		<div class="slide-2 blue-text">
			<div class="flex">
				<h2 class="letter">X</h2>
				<div>
					<img src="@/assets/images/15-xylophone-min.png" style="transform: rotate(-90deg)" class="symbol" alt="xilofone" />
				</div>
			</div>
			<h2 class="word">Xilófono</h2>
		</div>
		<div class="slide-3 yellow-text">
			<div class="flex">
				<h2 class="letter">C</h2>
				<div>
					<img src="@/assets/images/20-castle-min.png" class="symbol" alt="Castillo" />
				</div>
			</div>
			<h2 class="word">Castillo</h2>
		</div>
		<div class="slide-4 green-text">
			<div class="flex">
				<h2 class="letter">V</h2>
				<div>
					<img src="@/assets/images/25-Valley.jpg" class="symbol" alt="Valle" />
				</div>
			</div>
			<h2 class="word">Valle</h2>
		</div>
		<div class="slide-5 green-text">
			<div class="flex">
				<h2 class="letter">B</h2>
				<div>
					<img src="@/assets/images/26-scarf.jpg" class="symbol" alt="Bufanda" />
				</div>
			</div>
			<h2 class="word">Bufanda</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>