<template>
	<div id="modal">
		<a id="play-video" class="video-play-button" href="#">
			<span></span>
		</a>
		<div id="video-overlay" class="video-overlay">
			<a class="video-overlay-close">&times;</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "StartButton"
}
</script>

<style scoped>
	#loader h1{
		color:var(--color-navy-blue);
		font-size:16px;
		letter-spacing:1px;
		font-weight:200;
		text-align:center;
	}
	#loader i{
		font-size: 50px;
		color: #3ccfcf;
	}
</style>