<template>
	<Loader v-if="loading" />
	<StartButton v-if="start" @click="startLesson" />
	<div class="animation none">
		<Keyboard />
	</div>

	<First/>
	<Second/>
	<Third/>


	<RightOkay/>
	<Congrats v-if="next"/>
	<div id="overlay-animation" class="none"></div>

</template>

<script>
import Third from './Third.vue'
import Congrats from './Congrats.vue'
import Second from './Second.vue'
import First from './First.vue'
import RightOkay from '@/components/RightOkay.vue'
import Keyboard from "@/components/Keyboard/index";

import StartButton from "@/components/StartButton.vue";
import Loader from "@/components/Loader.vue";

import { setupLesson, startLesson } from "@/assets/js/home_row/activity/right";

import { onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";


export default {
	name: "OneActivityRight",
	components: { Loader, StartButton,  RightOkay,  First, Second, Congrats, Third, Keyboard },
	setup() {
		const store = useStore();

		setupLesson();

		onMounted(()=>{
			store.dispatch('loading',true);
			store.dispatch('start',false);
			store.dispatch('next',false);
		});
		onBeforeUnmount(() => {
			store.dispatch("pauseAnimation");
			store.dispatch("reset");
		});

		return {
			loading: store.getters.loading,
			start: store.getters.start,
			next: store.getters.next,
			startLesson,
		};
	},
};
</script>

<style scoped>
</style>