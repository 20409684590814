<template>
	<div class="learning-presentation none">
		<!-- 6  Right Hand-->
		<div class="presentation-6 none">
			<div class="p lesson-img">
				<img
					src="@/assets/images/13-Parrot-min.png"
					alt="Papagayo"
					class="w-100 h-100"
				/>
			</div>
			<div class="p red-text label">
				<h2>Ñ</h2>
				<h2>P</h2>
				<h2>Ñ</h2>
			</div>
		</div>
		<div class="presentation-7 none">
			<div class="o lesson-img">
				<img
					src="@/assets/images/17-opera-min.png"
					alt="Ópera"
					class="w-100 h-100"
				/>
			</div>
			<div class="o blue-text label">
				<h2>L</h2>
				<h2>O</h2>
				<h2>L</h2>
			</div>
		</div>
		<div class="presentation-8 none">
			<div class="i lesson-img">
				<img
					src="@/assets/images/21-Island.jpg"
					alt="Isla"
					class="w-100 h-100"
				/>
			</div>
			<div class="i yellow-text label">
				<h2>K</h2>
				<h2>I</h2>
				<h2>K</h2>
			</div>
		</div>
		<div class="presentation-9 none">
			<div class="u lesson-img">
				<img
					src="@/assets/images/27-grapes.jpg"
					alt="Uvas"
					class="w-100 h-100"
				/>
			</div>
			<div class="u green-text label">
				<h2>J</h2>
				<h2>U</h2>
				<h2>J</h2>
			</div>
		</div>
		<div class="presentation-10 none">
			<div class="y lesson-img">
				<img
					src="@/assets/images/28-man-yoga.jpg"
					alt="Yoga"
					class="w-100 h-100"
				/>
			</div>
			<div class="y green-text label">
				<h2>J</h2>
				<h2>Y</h2>
				<h2>J</h2>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "Presentation",
};
</script>

<style scoped>
</style>