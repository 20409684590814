<template>
	<h2 class="heading">
		<slot/>
	</h2>

</template>

<script>
export default {
	name: "GuestHeading"
}
</script>

<style scoped>
	.heading {
		color:#41E0D0;
	}
</style>