import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import { windowBlur } from "@/common/helpers";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('three_learn_left');

	store.getters.animation.value
		.add({
			targets: ".animation",
			opacity: 1,
			translateY: "0px",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})

		.add({
			duration: 2000,
			targets: [".keyboard"],
			opacity: 1,
			translateY: ["0px", "240px"],
			easing: "easeInOutSine",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// display block slides
		.add({
			targets: ".slides",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// Z Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR3-1"));
			},
			targets: ['[data-char="Z"]'],
			backgroundColor: [
				{ value: lightRedColor, duration: 1000 },
				{ value: redColor, duration: 1000, delay: 5500 },
			],
			top: [
				{ value: "0.2em", duration: 1000 },
				{ value: "0em", duration: 1000, delay: 5500 },
			],
		})
		// Z letter in block slides
		.add(
			{
				targets: [".slide-1 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
			},
			"-=7000"
		)
		// ZUMO image in block slides
		.add({
			targets: ".slide-1 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-1 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
		})
		.add(
			{
				targets: [".slide-1 .flex div"],
				scale: 2,
				duration: 1500,
			},
			"-=1500"
		)
		// LHBR5 upon completion of aimation
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHBR5"));
				},
				targets: ".slide-1 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 1000,
			},
			"-=1000"
		)

		.add({
			targets: [".slide-1"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// Z slide ends

		// X Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR6"));
			},
			targets: ['[data-char="X"]'],
			backgroundColor: [
				{ value: lightBlueColor, duration: 1000 },
				{ value: blueColor, duration: 1000, delay: 19000 },
			],
			top: [
				{ value: "0.2em", duration: 1000 },
				{ value: "0em", duration: 1000, delay: 19000 },
			],
			endDelay: 1000,
		})
		// X letter in block slides
		.add(
			{
				targets: [".slide-2 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
			},
			"-=20000"
		)
		// xilophone image in block slides
		.add({
			targets: ".slide-2 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-2 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
		})
		.add(
			{
				targets: [".slide-2 .flex div"],
				scale: 2,
				duration: 1500,
			},
			"-=1500"
		)
		// LHBR8 upon completion of aimation
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHBR8"));
				},
				targets: ".slide-2 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 3000,
				endDelay: 1000,
			},
			"-=1000"
		)

		.add({
			targets: [".slide-2"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// X slide ends here

		// C Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR9"));
			},
			targets: ['[data-char="C"]'],
			backgroundColor: [
				{ value: lightYellowColor, duration: 1000 },
				{ value: yellowColor, duration: 1000, delay: 9000 },
			],
			top: [
				{ value: "0.2em", duration: 1000 },
				{ value: "0em", duration: 1000, delay: 9000 },
			],
			endDelay: 1000,
		})
		// C letter in block slides
		.add(
			{
				targets: [".slide-3 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
			},
			"-=10000"
		)
		// castilo image in block slides
		.add({
			targets: ".slide-3 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-3 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
		})
		.add(
			{
				targets: [".slide-3 .flex div"],
				scale: 2,
				duration: 1500,
			},
			"-=1500"
		)
		// LHBR11 upon completion of aimation
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHBR11"));
				},
				targets: ".slide-3 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2500,
				endDelay: 1000,
			},
			"-=1000"
		)

		.add({
			targets: [".slide-3"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// C slide ends here

		// V Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR12"));
			},
			targets: ['[data-char="V"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 1000 },
				{ value: greenColor, duration: 1000, delay: 8500 },
			],
			top: [
				{ value: "0.2em", duration: 1000 },
				{ value: "0em", duration: 1000, delay: 8500 },
			],
			endDelay: 1000,
		})
		// V letter in block slides
		.add(
			{
				targets: [".slide-4 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
			},
			"-=10000"
		)
		// valley image in block slides
		.add({
			targets: ".slide-4 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-4 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
		})
		.add(
			{
				targets: [".slide-4 .flex div"],
				scale: 2,
				duration: 1500,
			},
			"-=1500"
		)
		// LHBR11 upon completion of aimation
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHBR14"));
				},
				targets: ".slide-4 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2500,
				endDelay: 1000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-4"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// V slide ends here

		// B Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR15"));
			},
			targets: ['[data-char="B"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 1000 },
				{ value: greenColor, duration: 1000, delay: 10000 },
			],
			top: [
				{ value: "0.2em", duration: 1000 },
				{ value: "0em", duration: 1000, delay: 10000 },
			],
			endDelay: 1000,
		})
		// B letter in block slides
		.add(
			{
				targets: [".slide-5 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
			},
			"-=12000"
		)
		// bufanda image in block slides
		.add({
			targets: ".slide-5 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-5 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
		})
		.add(
			{
				targets: [".slide-5 .flex div"],
				scale: 2,
				duration: 1500,
			},
			"-=1500"
		)
		// LHBR17 upon completion of aimation
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHBR17"));
				},
				targets: ".slide-5 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 4500,
				endDelay: 1000,
			},
			"-=1000"
		)
		// B slide ends here

		.add({
			targets: [".slide-5", ".slides"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");

				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
		})
		// T slide ends

		// remove and hide keyboard
		.add({
			targets: [".keyboard", ".animation"],
			translateY: "500px",
			opacity: [1, 0],
			duration: 3000,

			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})
		// cloud left
		.add({
			duration: 9000,
			translateX: ["-100%", "0%"],
			targets: ".left-container",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");

				store.dispatch("secondary", store.getters.howler.value.play("passive"));

				store.getters.howler.value.fade(
					0,
					0.3,
					5000,
					store.getters.secondary.value
				);

				store.dispatch("primary", store.getters.howler.value.play("LHBR18-0"));
			},
			endDelay: 5000,
		})
		.add({
			duration: 2000,
			targets: ".left-container",
			opacity: [1, 0],
			endDelay: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		/** *************************************************************************************************
		 * Learning presentation 1                                                                   *
		 ***************************************************************************************************
		 */
		.add({
			targets: ".learning-presentation",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		// ZUMO AZA
		.add({
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			targets: ".presentation-1",
		})

		.add({
			duration: 4000,
			targets: ".z.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add(
			{
				duration: 13500,
				targets: ".z.lesson-img",
				width: "250px",
				height: "250px",
				begin: () => {
					store.dispatch(
						"primary",
						store.getters.howler.value.play("LHBR18-1")
					);
				},
			},
			"-=3000"
		)
		.add({
			duration: 1800,
			targets: ".z.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHBR19-20-21")
				);
			},
		})
		// label
		.add({
			targets: ".z.label h2",
			delay: anime.stagger(1000),
			translateX: [{ value: "20px" }, { value: "0px", duration: 1000 }],
			opacity: [{ value: "0" }, { value: "1", duration: 1000 }],
		})

		.add({
			duration: 2000,
			opacity: [1, 0],
			targets: [".presentation-1"],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// animation zambo ends here

		// XILOPHONE SXS
		.add({
			targets: ".presentation-2",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR22"));
			},
			targets: ".x.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 23000 })
		.add({
			targets: ".x.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHBR23-24-25")
				);
			},
			endDelay: 2000,
		})
		// label
		.add({
			targets: ".x.label h2",
			delay: anime.stagger(1000),
			translateX: ["20px", "0px"],
			opacity: [0, 1],
		})

		.add({
			duration: 2000,
			targets: ".presentation-2",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// animation xilophone ends here

		// castilla  DCD
		// LHBR26
		.add({
			targets: ".presentation-3",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR26"));
			},
			targets: ".c.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 15000 })
		.add({
			targets: ".c.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHBR27-28-29")
				);
			},
			endDelay: 2000,
		})
		// label
		.add({
			targets: ".c.label h2",
			delay: anime.stagger(1000),
			translateX: ["20px", "0px"],
			opacity: [0, 1],
		})

		.add({
			duration: 2000,
			targets: ".presentation-3",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// animation castilla ends here

		// valley  FVF
		// LHBR30
		.add({
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			targets: ".presentation-4",
		})
		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR30"));
			},
			targets: ".v.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 16000 })
		.add({
			targets: ".v.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHBR31-32-33")
				);
			},
			endDelay: 2000,
		})
		// label
		.add({
			targets: ".v.label h2",
			delay: anime.stagger(1000),
			translateX: ["20px", "0px"],
			opacity: [0, 1],
		})

		.add({
			duration: 2000,
			targets: ".presentation-4",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// animation valley ends here

		// Bufando  FBF
		// LHBR34
		.add({
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			targets: ".presentation-5",
		})
		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR34"));
			},
			targets: ".b.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [0, 1],
		})
		.add({ duration: 17000 })
		.add({
			targets: ".b.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHBR35-36-37")
				);
			},
			endDelay: 2000,
		})
		// label
		.add({
			targets: ".b.label h2",
			delay: anime.stagger(1000),
			translateX: ["20px", "0px"],
			opacity: [0, 1],
		})
		.add({
			duration: 2000,
			targets: ".presentation-5",
			opacity: [1, 0],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// animation bufanda ends here

		.add({
			targets: ".learning-presentation",
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})

		// slide up keyboard
		.add({
			targets: [".animation"],
			translateY: "50px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
			},
		})		
		.add({
			targets: [".keyboard", ".animation .key-text-left"],
			translateY: "0px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			complete: () => {
				store.dispatch(
					"primary",
					store.getters.howler.value.play("LHBR38-39-40-41-42")
				);
			},
		})
		.add({
			targets: [".keyboard", ".animation"],
			opacity: 1,
			delay: 3000,
		})

		.add({
			delay: 3000,
			targets: ['[data-char="Z"]'],
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 500 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-z",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2200,
			targets: ['[data-char="X"]'],
			backgroundColor: [
				{ value: lightBlueColor, duration: 500 },
				{ value: blueColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-x",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2500,
			targets: ['[data-char="C"]'],
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-c",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 2500,
			targets: ['[data-char="V"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-v",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			delay: 3500,
			targets: ['[data-char="B"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: "0.2em", duration: 500 },
				{ value: "0em", duration: 500, delay: 1000 },
			],
		})

		.add(
			{
				targets: ".key-text-left h2.text-b",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=1500"
		)

		.add({
			duration: 5000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHBR43"));
			},
		})

		.add({
			delay: 1000,
			targets: ".key-text-left h2",
			opacity: [1, 0],
			duration: 1000,
		})

		// hide animation
		.add({
			targets: [".animation", ".animation .key-text-left"],
			opacity: [1, 0],
			translateY: "500px",
			easing: "easeInOutSine",
			duration: 3000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			endDelay: 1000,
		})

		// ok thumbsup
		.add({
			targets: ".right-ok",
			opacity: 1,
			duration: 3000,
			translateX: ["-20%", "0%"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('three_learn_left');

			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/bottom_row/learn/left.mp3")],
			sprite: {
				active: [0, 252120, true],
				LHBR11: [254000, 2361.5192743764055],
				LHBR12: [258000, 9141.972789115642],
				LHBR14: [269000, 2164.716553287974],
				LHBR15: [273000, 11288.820861678005],
				LHBR17: [286000, 4544.149659863933],
				"LHBR18-0": [292000, 9229.727891156472],
				"LHBR18-1": [303000, 13132.653061224517],
				"LHBR19-20-21": [318000, 6440.521541950091],
				LHBR22: [326000, 22076.689342403653],
				"LHBR23-24-25": [350000, 6565.759637188194],
				LHBR26: [358000, 14401.746031746028],
				"LHBR27-28-29": [374000, 6190.068027210884],
				"LHBR3-0": [382000, 11718.979591836727],
				"LHBR3-1": [395000, 7467.777777777769],
				LHBR30: [404000, 15421.496598639464],
				"LHBR31-32-33": [421000, 6261.632653061213],
				LHBR34: [429000, 16673.832199546494],
				"LHBR35-36-37": [447000, 6852.018140589564],
				"LHBR38-39-40-41-42": [455000, 27318.57142857143],
				LHBR43: [484000, 5009.319727891181],
				LHBR5: [491000, 1127.097505668928],
				LHBR6: [494000, 21056.96145124716],
				LHBR8: [517000, 3184.4897959183527],
				LHBR9: [522000, 11020.453514739187],
				passive: [535000, 548571.4285714284, true],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
