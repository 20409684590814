import store from "@/store/index";

import { Howl } from "howler";
import { Droppable } from '@shopify/draggable';


const positiveSound = new Howl({
	src: [require("@/assets/audio/positive.mp3")],
});
const negativeSound = new Howl({
	src: [require("@/assets/audio/negative.mp3")],
});

var droppableOrigin;


export const setupActivityOne = (wrapperName, className) => {
	const droppable = getDroppable(wrapperName);

	var zoneContainer = [];

	droppable.on("droppable:stop", (evt) => {
		// show next dropzone when dropped
		if (droppableOrigin !== evt.dropzone.dataset.dropzone) {
			evt.cancel();
		}

		if (
			evt.data.dropzone.parentNode.classList.value.includes(`${className} drop`)
		) {
			// If dropped at right place
			document
				.querySelector(`.${className} [data-correct="${droppableOrigin}"]`)
				.classList.add("show-label");
			positiveSound.play();
			if (zoneContainer.indexOf(droppableOrigin) === -1) {
				zoneContainer.push(droppableOrigin);

				if (zoneContainer.length === 5) {
					store.dispatch("offActivity");
				}
			}
		} else {
			evt.cancel();
			negativeSound.play();
			zoneContainer = zoneContainer.filter((el) => el != droppableOrigin);
		}
	});

};


export const setupActivityTwo = (wrapperName, className, hiddenZone) => {
	
	var hiddenZoneContainer = document.querySelectorAll(hiddenZone);
	var seriesContainer = [];
	
	const droppable = getDroppable(wrapperName);

	droppable.on("droppable:stop", (evt) => {
		// show next dropzone when dropped
		if (droppableOrigin !== evt.dropzone.dataset.dropzone) {
			evt.cancel();
		}

		if (
			evt.data.dropzone.parentNode.classList.value.includes(`${className} drop`)
		) {
			evt.data.dropzone.parentNode.style.backgroundColor = evt.data.dropzone.style.backgroundColor;

			// If dropped at right place
			document
				.querySelector(`.${className} [data-correct="${droppableOrigin}"]`)
				.classList.add("show-label");
			positiveSound.play();
			if (seriesContainer.indexOf(droppableOrigin) === -1 || (seriesContainer.length === 4 && droppableOrigin == 4)) {
				seriesContainer.push(droppableOrigin);
				if (seriesContainer.length === 5) {
					// Activity 2 finishes here
					
					store.dispatch("offActivity");
					return;
				}
			}
			// console.log(seriesContainer, i, hiddenZoneContainer);
			setTimeout(() => {
				hiddenZoneContainer[seriesContainer.length - 1].classList.add('none');
				if (seriesContainer.length < hiddenZoneContainer.length) {
					hiddenZoneContainer[seriesContainer.length].classList.remove('none');
				}
			}, 3000);

		} else {
			evt.cancel();
			negativeSound.play();
		}
	});

};


export const setupActivityThree = (wrapperName, className, letters) => {
	
	const droppable = getDroppable(wrapperName);

	var zoneContainer = [];

	droppable.on("droppable:stop", (evt) => {
		console.log('stop',evt);
		// show next dropzone when dropped
		if (droppableOrigin !== evt.dropzone.dataset.dropzone) {
			evt.cancel();
		}

		if (
			evt.data.dropzone.parentNode.classList.value.includes(`${className} drop`)
		) {
			positiveSound.play();
			if (zoneContainer.indexOf(droppableOrigin) === -1) {
				zoneContainer.push(droppableOrigin);
				if (zoneContainer.length === letters) {
					store.dispatch("offActivity");
				}
			}

		} else {
			evt.cancel();
			negativeSound.play();
			zoneContainer = zoneContainer.filter((el) => el != droppableOrigin);
		}
	});

};


const getDroppable = (wrapperName)=>{	
	const droppable = new Droppable(document.querySelectorAll(`.${wrapperName}`), {
		// register dropzones as containers
		draggable: `.draggable`,
		dropzone: `.dropzone`,
		mirror: {
			constrainDimensions: true, // prevent element from shrinking while being dragged
		},
	});

	// --- Draggable events --- //
	droppable.on(
		"drag:start",
		(evt) => (droppableOrigin = evt.originalSource.parentNode.dataset.dropzone)
	);

	droppable.on("droppable:dropped", (evt) => {
		if (droppableOrigin !== evt.dropzone.dataset.dropzone) {
			// if dropped into wrong container then:

			evt.cancel();
		}
	});

	droppable.on("droppable:returned", (evt) => {
		if (droppableOrigin !== evt.dropzone.dataset.dropzone) {
			// if dropped into wrong container then:
			evt.cancel();
		}
	});
	return droppable;
}