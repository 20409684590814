<template>
	<div class="key-text-right none flex-row">
		<h2 class="green-text">H</h2>
		<h2 class="green-text">J</h2>
		<h2 class="yellow-text">K</h2>
		<h2 class="blue-text">L</h2>
		<h2 class="red-text">Ñ</h2>
	</div>
</template>

<script>
export default {
	name: "KeyText",
};
</script>

<style scoped>
</style>