import { computed } from "vue";

const getters = {
	pupil: (state) => computed(()=>state.pupil),
	sptt: (state) => computed(()=>state.sptt),
	trone: (state) => computed(()=>state.trone),

	requesting: (state) => computed(()=>state.requesting),
	loading: (state) => computed(()=>state.loading),
	start: (state) => computed(() => state.start),
	next: (state) => computed(() => state.next),

	primary: (state) => computed(() => state.primary),
	secondary: (state) => computed(() => state.secondary),
	
	animation: (state) => computed(() => state.animation),
	howler: (state) => computed(() => state.howler),

	playing: (state) => computed(() => state.playing),
	maximized: (state) => computed(() => state.maximized),
	
	activity: (state) => computed(() => state.activity),

	accent_down: (state) => computed(() => state.accent_down),
	curr_pointer: (state) => computed(() => state.curr_pointer),
	word_count: (state) => computed(() => state.word_count),
	strokes_count: (state) => computed(() => state.strokes_count),
	text: (state) => computed(() => state.text),
	formatted_text: (state) => computed(() => state.formatted_text),
	typing_text: (state) => computed(() => state.typing_text),

};

export default getters;
