<template>
	<div class="key-text-left none">
		<h2 class="red-text text-z">Z</h2>
		<h2 class="blue-text text-x">X</h2>
		<h2 class="yellow-text text-c">C</h2>
		<h2 class="green-text text-v">V</h2>
		<h2 class="green-text text-b">B</h2>
	</div>
</template>

<script>
export default {
	name: "KeyText",
};
</script>

<style scoped>
</style>