<template>	
	<main class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"> 
		<p class="text-center font-weight-bold" style="color: #325591;">
			¡Hola de nuevo!<br><br>

			¡Lo estás haciendo muy bien! Estás avanzando muy rápidamente.<br><br>

			Para continuar ve al menú de la izquierda.<br><br>

			O puedes hacer clic en la siguiente tarea o repetir un entreno.<br><br>

			¡Vamos!

		</p>
	</main> 
</template>

<script>
import AuthButton from '@/components/AuthButton.vue'
import { setupLesson } from "@/assets/js/repeated_login";
import { onBeforeUnmount, onMounted } from '@vue/runtime-core';
import { useStore } from 'vuex';
export default {
	name: "Learned",
	components: { AuthButton},
	setup() {
		const store = useStore();

		setupLesson();

		onMounted(()=>{
			store.dispatch('loading',false);
			store.dispatch('start',false);
			store.dispatch('next',false);
		});
		onBeforeUnmount(() => {
			store.dispatch("pauseAnimation");
			store.dispatch("reset");
		});

	},

};
</script>


<style scoped>
</style>