<template>
	<div class="learning-presentation none">
		<div class="presentation-1 none">
			<div class="q lesson-img">
				<img
				src="@/assets/images/11-Kiosk-(Shop)-min.png"
				alt="quiosco"
				class="w-100 h-100"
				/>
			</div>
			<div class="q red-text label">
				<h2>A</h2>
				<h2>Q</h2>
				<h2>A</h2>
			</div>
		</div>
		<div class="presentation-2 none">
			<div class="w lesson-img">
				<img
					src="@/assets/images/16-windsurf-board-with-a-sail-min.png"
					alt="Windsurf"
					class="w-100 h-100"
				/>
			</div>
			<div class="w blue-text label">
				<h2>S</h2>
				<h2>W</h2>
				<h2>S</h2>
			</div>
		</div>
		<div class="presentation-3 none">
			<div class="e lesson-img">
				<img
					src="@/assets/images/19-star-fish-min.png"
					alt="Estrella de mar"
					class="w-100 h-100"
				/>
			</div>
			<div class="e yellow-text label">
				<h2>D</h2>
				<h2>E</h2>
				<h2>D</h2>
			</div>
		</div>
		<div class="presentation-4 none">
			<div class="r lesson-img">
				<img
					src="@/assets/images/23-Prince-frog.jpg"
					alt="Rana"
					class="w-100 h-100"
				/>
			</div>
			<div class="r green-text label">
				<h2>F</h2>
				<h2>R</h2>
				<h2>F</h2>
			</div>
		</div>
		<div class="presentation-5 none">
			<div class="t lesson-img">
				<img
					src="@/assets/images/24-Turtle.jpg"
					alt="Tortuga"
					class="w-100 h-100"
				/>
			</div>
			<div class="t green-text label">
				<h2>F</h2>
				<h2>T</h2>
				<h2>F</h2>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Presentation",
};
</script>

<style scoped>
</style>