import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import $ from "jquery";
import { windowBlur } from "@/common/helpers";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started("one_practice");

	if(store.getters.sptt.value.one_practice_finished)
		store.getters.animation.value
			.add({
				duration: 4350,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("RepL1A"));
				},
			});
	else
		store.getters.animation.value
			.add({
				duration: 11760,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTHR1"));
				},
			})

			.add({
				duration: 9050,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTHR2"));
				},
			})
			
			.add({
				duration: 4850,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTHR3"));
				},
			})

			.add({
				duration: 5300,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTHR4"));
				},
			})

			.add({
				duration: 6350,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTHR5"));
				},
			})

			.add({
				duration: 5750,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTHR6"));
				},
			})

			.add({
				duration: 10200,
				begin: () => {
					store.dispatch("primary", store.getters.howler.value.play("TTHR23"));
				},
			});

	store.getters.animation.value
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				window.addEventListener("keypress", keyPressEvent);
				// practice started
				store.dispatch("onActivity");
			},
		})

		// Practice stopped
		.add({
			duration: 1000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
				window.removeEventListener("keypress", keyPressEvent);
				store.dispatch("playing", true);
			}
		});
	if(store.getters.sptt.value.one_practice_finished)
		store.getters.animation.value
			.add({
				duration: 3940,
				begin: () => {
					$(".timeline-controls").addClass('none');
					store.dispatch("primary", store.getters.howler.value.play("RepL1B"));
				},
			});
	else
		store.getters.animation.value
			.add({
				duration: 9000,
				begin: () => {
					store.dispatch("next", true);
					store.dispatch("primary", store.getters.howler.value.play("TTHR26"));
				},
			});

	store.getters.animation.value
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);
				SPTT.finished("one_practice");
			}
		})
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);
	window.removeEventListener("keypress", keyPressEvent);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/home_row/practice.mp3")],
			sprite: {
				RepL1A: [0, 4324.53514739229],
				RepL1B: [6000, 3926.8934240362814],
				TTHR1: [11000, 11747.00680272109],
				TTHR2: [24000, 9047.709750566895],
				TTHR23: [35000, 10197.414965986396],
				TTHR26: [47000, 8597.823129251701],
				TTHR3: [57000, 4848.775510204078],
				TTHR4: [63000, 5298.662131519279],
				TTHR5: [70000, 6348.390022675744],
				TTHR6: [78000, 5748.5487528344665]
			},			
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};

export const setupPractice = () => {
	//ññn
	store.dispatch(
		"text",
		"aaa.sss.ddd.fff.fgf.ñññ.lll.kkk.jjj.jhj.dad.gas.ala.las.sal.gasa.daga.saga.sala.jala.gala.alga.hada.haga.hadas.falsa.salsa.flash.falda.gafas.da la sal.dad la salsa.añada la gasa.la lasaña salada.dad las alas a las hadas"
	);
	store.dispatch(
		"formatted_text",
		store.getters.text.value.split(".").join(" ")
	);
	store.dispatch(
		"typing_text",
		store.getters.text.value
			.split(".")
			.map((word) => (word + " ").repeat(3))
			.join("")
			.trimRight()
	);

	$("#text").text(store.getters.formatted_text.value);
	$("#typing-text").text(
		store.getters.typing_text.value.slice(
			store.getters.curr_pointer.value,
			store.getters.curr_pointer.value + 50
		)
	);
};
export const keyPressEvent = function(evt) {
	if (!store.getters.activity.value) return;

	// highlighting text finished
	if (
		store.getters.typing_text.value.length ==
		store.getters.curr_pointer.value + 1
	) {
		store.dispatch("offActivity");
		$("#text").html(
			'<span class="green">' + store.getters.formatted_text.value + "</span>"
		);
		$("#typing-text").addClass("none");
	} else
		$("#text").html(
			'<span class="green">' +
				store.getters.formatted_text.value
					.split(" ")
					.slice(0, store.getters.word_count.value / 3)
					.join(" ") +
				"</span> <span>" +
				store.getters.formatted_text.value
					.split(" ")
					.slice(store.getters.word_count.value / 3)
					.join(" ") +
				"</span>"
		);

	if (
		store.getters.typing_text.value[store.getters.curr_pointer.value] ===
		evt.key
	) {
		store.dispatch("curr_pointer", store.getters.curr_pointer.value + 1);
		//width += 4;
		//progress bar
		if (
			store.getters.typing_text.value[store.getters.curr_pointer.value + 1] ===
			" "
		) {
			$("#progress").css(
				"width",
				(store.getters.curr_pointer.value /
					store.getters.typing_text.value.length) *
					600
			);
			store.dispatch("word_count", store.getters.word_count.value + 1);
		}
		$("#wordcount").text(Math.floor(store.getters.curr_pointer.value / 5));
		// highlighting typing row
		$("#typing-text").html(
			'<span class="green">' +
				store.getters.typing_text.value
					.slice(0, store.getters.curr_pointer.value)
					.slice(-10) +
				"</span>" +
				"<span class='underline'>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value,
					store.getters.curr_pointer.value + 1
				) +
				"</span>" +
				"<span>" +
				store.getters.typing_text.value.slice(
					store.getters.curr_pointer.value + 1,
					store.getters.curr_pointer.value + 50
				) +
				"</span>"
		);
	} else {
		if (
			store.getters.curr_pointer.value <
			store.getters.typing_text.value.length - 1
		)
			$("#typing-text").html(
				'<span class="green">' +
					store.getters.typing_text.value
						.slice(0, store.getters.curr_pointer.value)
						.slice(-10) +
					"</span>" +
					'<span class="red">' +
					store.getters.typing_text.value[store.getters.curr_pointer.value] +
					"</span><span>" +
					store.getters.typing_text.value.slice(
						store.getters.curr_pointer.value + 1,
						store.getters.curr_pointer.value + 50
					) +
					"</span>"
			);
	}
};
