<template>
	
    <div class="container d-flex justify-content-center align-items-center" style="height:100vh;">

        <div class="row w-75">
			<div class="col-12 text-center my-3">
				<h3>Así estás avanzando:</h3>
			</div>
			<div class="col-sm-4 mb-3">
				<div class="card h-100">
					<div class="card-body">
						<h6 class="d-flex align-items-center mb-3">
							<i class="material-icons text-info mr-2">Etapa 1</i>
							Fila básica
						</h6> 

						<!-- <div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.introduction" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >Introduction</span>
						</div> -->

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.one_learn_both" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >1 - Inicio</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.one_learn_left" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >2 - Aprendizaje</span>
						</div>


						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.one_activity_left" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >3 - Actividades</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.one_learn_right" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >4 - Aprendizaje</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.one_activity_right" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >5 - Actividades</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.one_practice_finished" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
																
							<span class=" bg-transparent rounded px-3" >Entreno</span>
						</div>


					</div>
				</div>
				<div class="progress my-2" style="height:5px;">
					<div class="progress-bar" role="progress" :style="`width:${lessonOnePercentage()}%;`"   style="background-color:turquoise;"></div>
				</div>
			</div>
			<div class="col-sm-4 mb-3">
				<div class="card h-100">
					<div class="card-body">
						<h6 class="d-flex align-items-center mb-3">
							<i class="material-icons text-info mr-2">Etapa 2</i>
							Fila superior
						</h6> 

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.two_learn_both" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >1 - Inicio</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.two_learn_left" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >2 - Aprendizaje</span>
						</div>


						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.two_activity_left" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >3 - Actividades</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.two_learn_right" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >4 - Aprendizaje</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.two_activity_right" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >5 - Actividades</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.two_practice_finished" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
																
							<span class=" bg-transparent rounded px-3" >Entreno</span>
						</div>


					</div>
				</div>
				<div class="progress my-2" style="height:5px;">
					<div class="progress-bar" role="progress" :style="`width:${lessonTwoPercentage()}%;`" style="background-color:turquoise;"></div>
				</div>
			</div>
			<div class="col-sm-4 mb-3">
				<div class="card h-100">
					<div class="card-body">
						<h6 class="d-flex align-items-center mb-3">
							<i class="material-icons text-info mr-2">Etapa 3</i>
							Fila inferior
						</h6> 

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.three_learn_both" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >1 - Inicio</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.three_learn_left" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >2 - Aprendizaje</span>
						</div>


						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.three_activity_left" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >3 - Actividades</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.three_learn_right" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >4 - Aprendizaje</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.three_activity_right" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
							
							<span class=" bg-transparent rounded px-3" >5 - Actividades</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.three_practice_finished" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
																
							<span class=" bg-transparent rounded px-3" >Entreno</span>
						</div>

						<div class="row px-3 align-items-center rounded lesson-progress">
							<i v-if="sptt.mix_finished" class="far fa-check-square" data-toggle="tooltip" data-placement="bottom"></i>
							<i v-else class="far fa-square" data-toggle="tooltip" data-placement="bottom"></i>
																
							<span class=" bg-transparent rounded px-3" >Tecleo Mix</span>
						</div>

					</div>
				</div>
				<div class="progress my-2" style="height:5px;">
					<div class="progress-bar" role="progress" :style="`width:${lessonThreePercentage()}%;`" style="background-color:turquoise;"></div>
				</div>
			</div> 

        </div>
    </div>


</template>

<script>
import User from "@/api/User"
import { ref } from 'vue';
import { useStore } from 'vuex';

export default {
	name: "Profile",
	components: {},
	setup(){
		const user = ref({});
		const store = useStore();

		User.self()
			.then((response) => {
				if (response.status == 200) {
					user.value = response.data
				}
			})
			.catch((err) => {
				console.log("err", err);
			});

		const sptt = store.getters.sptt

		const lessonOnePercentage = ()=>{
			let completed = 0
			if(sptt.value.introduction) completed++;
			if(sptt.value.one_learn_both) completed++;
			if(sptt.value.one_learn_left) completed++;
			if(sptt.value.one_activity_left) completed++;
			if(sptt.value.one_learn_right) completed++;
			if(sptt.value.one_activity_right) completed++;
			if(sptt.value.one_practice) completed++;
			return Math.round(completed/7 * 100);
		}	

		const lessonTwoPercentage = ()=>{
			let completed = 0
			if(sptt.value.two_learn_both) completed++;
			if(sptt.value.two_learn_left) completed++;
			if(sptt.value.two_activity_left) completed++;
			if(sptt.value.two_learn_right) completed++;
			if(sptt.value.two_activity_right) completed++;
			if(sptt.value.two_practice) completed++;
			return Math.round(completed/6 * 100);

		}	

		const lessonThreePercentage = ()=>{
			let completed = 0
			if(sptt.value.three_learn_both) completed++;
			if(sptt.value.three_learn_left) completed++;
			if(sptt.value.three_activity_left) completed++;
			if(sptt.value.three_learn_right) completed++;
			if(sptt.value.three_activity_right) completed++;
			if(sptt.value.three_practice) completed++;
			if(sptt.value.mix) completed++;
			return Math.round(completed/7 * 100);

		}	


		return {
			user,
			sptt,

			lessonOnePercentage,
			lessonTwoPercentage,
			lessonThreePercentage

		}
	}
};
</script>

<style scoped>
	i.far.fa-check-square{
		color:  turquoise;
	}
</style>