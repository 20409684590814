<template>
	<div class="hand-container none">
		<div class="hide little-finger-left">meñique</div>
		<div class="hide little-finger-right">meñique</div>
		<div class="hide ring-finger-left">anular</div>
		<div class="hide ring-finger-right">anular</div>
		<div class="hide middle-finger-left">medio</div>
		<div class="hide middle-finger-right">medio</div>
		<div class="hide index-finger-left">índice</div>
		<div class="hide index-finger-right">índice</div>
		<div class="hide thumb-left">pulgar</div>
		<div class="hide thumb-right">pulgar</div>
		<img src="@/assets/images/left-hand.png" alt="left hand" class="left-hand-image" />
		<img src="@/assets/images/right-hand.png" alt="right hand" class="right-hand-image" />
	</div>
</template>

<script>
export default {
	name: "Hands",
};
</script>

<style scoped>
</style>