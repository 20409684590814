<template>
	<!-- Footer -->
	<footer class="page-footer font-small indigo">
		<!-- Footer Links -->
		<div class="container">
			<!-- Grid row-->
			<div class="row text-center d-flex justify-content-center pt-3">
				<div class="col">
					<WhiteButton to="/faq-individuals" >Preguntas frecuentes</WhiteButton>
				</div>
				<div class="col">
					<WhiteButton to="/terms-and-conditions">Condiciones de servicio</WhiteButton>
				</div>
				<div class="col">
					<WhiteButton to="/privacy-policy" >Política de privacidad</WhiteButton>
				</div>
				<div class="col">
					<WhiteButton to="/contact" >Contacto</WhiteButton>
				</div>
			</div>
			<hr class="rgba-white-light" style="margin: 10px" />
		</div>

		<div class="footer-copyright text-center py-3">
			© 2021 Copyright 
		</div>
	</footer>
</template>

<script>
import WhiteButton from '@/components/WhiteButton.vue'

export default {
	components: { WhiteButton },
	name: "Footer",
};
</script>

<style scoped>

	footer {
		background-color: white;
		bottom: 0;
	}

	footer a {
		color: turquoise;
	}

	footer a:hover {
		text-decoration: none;
		color: turquoise;
	}

	.footer-buttons {
		background-color: transparent !important;
		color: turquoise !important;
		font-weight: bold;
		padding: 0.6em 0em;
		border-radius: 0.25rem;
	}

	.footer-buttons:hover {
		box-shadow: 2px 4px 6px turquoise;
	}
	.col {
		min-width: auto;
	}
</style>
