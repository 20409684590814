<template>
	<section
		class="accordion-section clearfix"
		aria-label="Question Accordions"
	>
		<div class="container">
			<h2 class="text-left my-4 ">Preguntas frecuentes</h2>
			<div
				class="panel-group "
				id="accordion"
				role="tablist"
				aria-multiselectable="true"
			>
				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading0"
					>
						<a
							class="panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse0"
							aria-expanded="true"
							aria-controls="collapse0"
						>
							¿A partir de qué edad se recomienda el programa de
							aprendizaje?
							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse0"
						class="panel-collapse collapse show"
						role="tabpanel"
						aria-labelledby="heading0"
					>
						<div class="panel-body px-3 mb-4">
							El programa de aprendizaje es adecuado para todo el
							mundo de 10 a 100 años de edad. Cualquiera que
							quiera teclear en el ordenador con 10 dedos sin
							mirar las teclas puede aprenderlo con este programa.
						</div>
					</div>
				</div>

				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading1"
					>
						<a
							class="collapsed panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse1"
							aria-expanded="true"
							aria-controls="collapse1"
						>
							¿Con qué frecuencia tengo que practicar después de
							terminar el curso de 1 hora?
							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse1"
						class="panel-collapse collapse"
						role="tabpanel"
						aria-labelledby="heading1"
					>
						<div class="panel-body px-3 mb-4">
							Tienes dos opciones: Si trabajas con el ordenador
							todos los días, simplemente aplicas tu recién
							adquirida habilidad al escribir. Si no, es
							aconsejable usar los entrenamientos del programa y
							escribir cada día durante una semana o dos durante
							unos 10 -15 minutos. De esta forma, tu memoria
							muscular se desarrolla y acabarás siendo un crack.
						</div>
					</div>
				</div>

				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading2"
					>
						<a
							class="collapsed panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse2"
							aria-expanded="true"
							aria-controls="collapse2"
						>
							¡Pero ya escribo rapidísimo con mi propio sistema
							con 4 o 5 dedos!
							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse2"
						class="panel-collapse collapse"
						role="tabpanel"
						aria-labelledby="heading2"
					>
						<div class="panel-body px-3 mb-4">
							Crees que eres rápido, pero la verdad es que ¡no lo
							eres! Si miras las teclas y cometes un error,
							pierdes tiempo. No lo perderías si pudieras escribir
							con 10 dedos y sin mirar. Así podrás duplicar
							rápidamente tu velocidad y pronto podrás escribir
							aún más rápido.
						</div>
					</div>
				</div>

				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading3"
					>
						<a
							class="collapsed panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse3"
							aria-expanded="true"
							aria-controls="collapse3"
						>
							¿Cómo es posible aprender a teclear en sólo 1 hora
							en comparación con los cursos tradicionales de
							mecanografía que requieren por lo menos 20, 25
							horas?

							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse3"
						class="panel-collapse collapse"
						role="tabpanel"
						aria-labelledby="heading3"
					>
						<div class="panel-body px-3 mb-4">
							Porque en los cursos convencionales se aprende a
							teclear por pura repetición. Escribes una y otra vez
							sílabas sin sentido y secuencias de palabras hasta
							que puedas recordarlo. Eso lleva tiempo. Sin
							embargo, con este sistema se aprende mucho más
							rápido porque es un aprendizaje que se basa en cómo
							funciona el cerebro y utiliza, por ejemplo,
							asociaciones y visualizaciones.
						</div>
					</div>
				</div>

				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading4"
					>
						<a
							class="collapsed panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse4"
							aria-expanded="true"
							aria-controls="collapse4"
						>
							¿Cuántas palabras por minuto se pueden alcanzar a
							escribir después de completar el curso de 1 hora?

							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse4"
						class="panel-collapse collapse"
						role="tabpanel"
						aria-labelledby="heading4"
					>
						<div class="panel-body px-3 mb-4">
							Eso depende por supuesto de la edad y la habilidad
							de cada individuo. Un niño no teclea tan rápido
							porque los dedos aún son cortos, pero tiene la
							ventaja de que aún no ha desarrollado malos hábitos.
							Un adolescente suele escribir bastante rápido,
							porque tiene la capacidad de deshacerse rápidamente
							de sus malos hábitos escribiendo y así aumentar su
							velocidad dentro de poco tiempo. Un adulto por fin
							tiene la oportunidad de aprender a teclear
							rápidamente sin mirar el tecleado. Un cambio que
							verdaderamente vale la pena. Si alguien toca un
							instrumento, naturalmente sus dedos son mucho más
							hábiles.
						</div>
					</div>
				</div>

				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading5"
					>
						<a
							class="collapsed panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse5"
							aria-expanded="true"
							aria-controls="collapse5"
						>
							¿Cómo se reparten las 3 etapas?

							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse5"
						class="panel-collapse collapse"
						role="tabpanel"
						aria-labelledby="heading5"
					>
						<div class="panel-body px-3 mb-4">
							Como quieras. Cualquiera puede completar las etapas
							como desee. Ya sea todas una después del otro o con
							un descanso de unos dias después de cada etapa... Se
							recomienda hacer un descanso de una semana como
							máximo y utilizar estos días para completar el
							entreno de tecleo. Cada día 10 o 15 minutos son
							suficientes para mejorar la memoria muscular.
						</div>
					</div>
				</div>

				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading6"
					>
						<a
							class="collapsed panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse6"
							aria-expanded="true"
							aria-controls="collapse6"
						>
							¿Qué hago al final de las 3 etapas?

							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse6"
						class="panel-collapse collapse"
						role="tabpanel"
						aria-labelledby="heading6"
					>
						<div class="panel-body px-3 mb-4">
							Puedes elegir entre dos opciones: Si tienes que
							escribir mucho en el ordenador de todos modos,
							puedes hacerlo y de esta manera aumentarás tu
							velocidad de tecleo. O, si no es así, se puede
							repetir los entrenos de tecleo de las 3 etapas y
							adicionalmente hacer el Tecleo Mix que asegura una
							progresión especial. También puedes hacer los
							entrenos de velocidad separados que ofrecemos: El
							Entreno Velocidad I: Si quieres llegar a una
							velocidad más rápida y concentrada esto es para tí.
							El Entreno Velocidad II: Con este programa de
							entreno se puede llegar a velocidades muy altas de
							forma planificada y consistente. Utilizando la
							inteligencia artificial, este entreno es altamente
							eficaz. Esto es para todos aquellos que necesitan un
							certificado o quieren conseguir una alta velocidad
							rápidamente. Tienes acceso a todos entrenos durante
							tres meses.
						</div>
					</div>
				</div>

				<div class="panel panel-default">
					<div
						class="panel-heading p-3 mb-3"
						role="tab"
						id="heading7"
					>
						<a
							class="collapsed panel-title"
							role="button"
							data-toggle="collapse"
							data-parent="#accordion"
							href="#collapse7"
							aria-expanded="true"
							aria-controls="collapse7"
						>
							¿Este sistema de aprendizaje también es adecuado
							para personas con dislexia?

							<i
								class="fas fa-chevron-down"
								data-v-a35e6b72=""
							></i>
						</a>
					</div>
					<div
						id="collapse7"
						class="panel-collapse collapse"
						role="tabpanel"
						aria-labelledby="heading7"
					>
						<div class="panel-body px-3 mb-4">
							¡Ayuda enormemente a las personas con dislexia!
							Debido al enfoque multisensorial y otros elementos,
							todos aprenden con facilidad. También se ha
							demostrado que escribir en el teclado con los 10
							dedos tiene un efecto positivo en la ortografía de
							los niños con dislexia: ¡reduce sus errores!
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="text-left mb-3">
			<Link class="nav-link font-weight-bold" to="/"><i class="fas fa-arrow-left"></i> Volver</Link>
		</div>
	</section>
</template>

<script>
import Link from "@/components/Link.vue";
export default {
	name: "IndividualFAQ",
	components: {Link},
};
</script>


<style scoped>
.accordion-section{
	margin: 3em;
}
.accordion-section .panel-default > .panel-heading {
	border: 0;
	background: white;
	padding: 0;
}

.accordion-section .panel-default a.panel-title {
	display: flex;
	justify-content: space-between;
	color: #325591;
	text-decoration: none;
	font-size: 1.5rem;
}
.accordion-section .panel-default .panel-body {
	font-size: 1.2rem;
}
</style>