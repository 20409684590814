<template>
	<div class="oval-thought-container right-container none">
		<div
			class="right-cloud"
			:style="{ backgroundImage: 'url(' + require('@/assets/images/cloud-right.png') + ')' }"			
		>
			<div class="cloud-text">
				<span style="font-size: 0.7em;">
					Historia
				</span>
				<br />
				<span class="green-text">
					Y
				</span>
				<span class="green-text">
					U
				</span>
				<span class="yellow-text">
					I
				</span>
				<span class="blue-text">
					O
				</span>
				<span class="red-text">
					P
				</span>
			</div>
		</div>
		<div
			class="right-hand-cloud"
			:style="{ backgroundImage: 'url(' + require('@/assets/images/right-hand.png') + ')' }"
		></div>
	</div>
</template>

<script>
export default {
	name: "CloudHistory",
};
</script>

<style scoped>
</style>