<template>
	<div class="slides none">
		<!-- RIGHT SIDE -->
		<div class="slide-6 red-text">
			<div class="flex">
				<h2 class="letter">
					P
				</h2>
				<div>
					<img
						src="@/assets/images/13-Parrot-min.png"
						class="symbol"
						alt="Papagayo"
					/>
				</div>
			</div>
			<h2 class="word">
				Papagayo
			</h2>
		</div>
		<div class="slide-7 blue-text">
			<div class="flex">
				<h2 class="letter">
					O
				</h2>
				<div>
					<img
						src="@/assets/images/17-opera-min.png"
						class="symbol"
						alt="Ópera"
					/>
				</div>
			</div>
			<h2 class="word">
				Ópera
			</h2>
		</div>
		<div class="slide-8 yellow-text">
			<div class="flex">
				<h2 class="letter">
					I
				</h2>
				<div>
					<img src="@/assets/images/21-Island.jpg" class="symbol" alt="Isla" />
				</div>
			</div>
			<h2 class="word">
				Isla
			</h2>
		</div>
		<div class="slide-9 green-text">
			<div class="flex">
				<h2 class="letter">
					U
				</h2>
				<div>
					<img src="@/assets/images/27-grapes.jpg" class="symbol" alt="Uvas" />
				</div>
			</div>
			<h2 class="word">
				Uvas
			</h2>
		</div>
		<div class="slide-10 green-text">
			<div class="flex">
				<h2 class="letter">
					Y
				</h2>
				<div>
					<img
						src="@/assets/images/28-man-yoga.jpg"
						class="symbol"
						alt="Yoga"
					/>
				</div>
			</div>
			<h2 class="word">
				Yoga
			</h2>
		</div>
	</div>
</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>