<template>
	<div class="slides none">
		<!-- RIGHT SIDE -->
		<div class="slide-6 red-text">
			<div class="flex">
				<h2 class="letter">-</h2>
				<div>
					<img src="@/assets/images/14-Hyphen-min.png" style="width:100%;height:20%;" class="symbol" alt="Guion" />
				</div>
			</div>

			<h2 class="word">Guion</h2>
		</div>
		<div class="slide-7 blue-text">
			<div class="flex">
				<h2 class="letter" style="font-family:auto;">.</h2>
				<div>
					<img src="@/assets/images/18-Ball-min.png" class="symbol" alt="Punto" />
				</div>
			</div>
			<h2 class="word">Punto</h2>
		</div>
		<div class="slide-8 yellow-text">
			<div class="flex">
				<h2 class="letter" style="font-family:auto;">,</h2>
				<div>
					<img src="@/assets/images/22-pool-noodle.jpg" class="symbol" alt="Comma" />
				</div>
			</div>
			<h2 class="word">Comma</h2>
		</div>
		<div class="slide-9 green-text">
			<div class="flex">
				<h2 class="letter">M</h2>
				<div>
					<img src="@/assets/images/29-Music.jpg" class="symbol" alt="Música" />
				</div>
			</div>
			<h2 class="word">Música</h2>
		</div>
		<div class="slide-10 green-text">
			<div class="flex">
				<h2 class="letter">N</h2>
				<div>
					<img src="@/assets/images/30-heart.jpg" class="symbol" alt="Novia Novio" />
				</div>
			</div>
			<h2 class="word">Novia Novio</h2>
		</div>
	</div>

</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>