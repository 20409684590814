<template>
	<div class="right-to-left none">
		<div class="series-row 1">
			<div class="big-text">
				Fila superior Mano derecha Meñique
				<div>
					<img src="@/assets/images/right-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone red-bg" data-dropzone="1">
					<div class="check-label" data-correct="1">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 3">
			<div class="big-text">
				Fila superior Mano derecha Medio
				<div>
					<img src="@/assets/images/right-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone yellow-bg" data-dropzone="3">
					<div class="check-label" data-correct="3">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 4">
			<div class="big-text">
				Fila superior Mano derecha Índice
				<div>
					<img src="@/assets/images/right-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone green-bg" data-dropzone="4">
					<div class="check-label" data-correct="4">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 2">
			<div class="big-text">
				Fila superior Mano derecha Anular
				<div>
					<img src="@/assets/images/right-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone blue-bg" data-dropzone="2">
					<div class="check-label" data-correct="2">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div class="series-row none 5">
			<div class="big-text">
				Fila superior Mano derecha Índice
				<div>
					<img src="@/assets/images/right-hand.png" />
				</div>
			</div>
			<h1>
				=
			</h1>
			<div class="wrapper2 drop dropable-zone">
				<div class="dropzone green-bg" data-dropzone="4">
					<div class="check-label" data-correct="4">
						<img src="@/assets/images/orange-check.png" alt="orange check" />
					</div>
					<h2>
						?
					</h2>
				</div>
			</div>
		</div>
		<div>
			<div class="wrapper2 drag">
				<div class="dropzone occupied" data-dropzone="1">
					<div class="draggable">
						P
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable">
						U
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="3">
					<div class="draggable">
						I
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable">
						Y
					</div>
				</div>
				<div class="dropzone occupied" data-dropzone="2">
					<div class="draggable">
						O
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setupActivityTwo } from "@/common/activities";

export default {
	name: "Second",
	mounted: ()=> setupActivityTwo('wrapper2', 'wrapper2', '.right-to-left .series-row'),
};
</script>

<style scoped>
</style>