<template>
	<p class="message">
		<slot/>
	</p>
</template>

<script>
export default {
	name: "GuestMessage"
}
</script>

<style scoped>
	.message {
		margin: 15px 0 0;
		margin-bottom: 10px;
		color: #b3b3b3;
		font-size: 12px;
	}
</style>