export const redColor = "#DD3D45";
export const lightRedColor = "#FF0000";
export const yellowColor = "#FFC23D";
export const lightYellowColor = "#F5E837";
export const greenColor = "#42A846";
export const lightGreenColor = "#B8E521";
export const blueColor = "#0978FA";
export const lightBlueColor = "#42D3E5";
export const greyColor = "#b7b7b7";
export const blackColor = "#9B9B9B";
export const lightGreyColor = "#d6d6d6";