import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import SPTT from "@/api/SPTT";

import { windowBlur } from '@/common/helpers'

export const startLesson = () => {

	SPTT.started('one_learn_left');

	store.getters.animation.value
		.add({
			targets: ".animation",
			duration: 1000,
			opacity: 1,
			translateY: "0px",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		// hide other rows and show home rows
		.add({
			targets: [
				".row__one .red-button",
				".row__two .red-button",
				".row__four .red-button",
				".row__five .red-button",
				".row__one .green-button",
				".row__two .green-button",
				".row__four .green-button",
				".row__five .green-button",
				".row__one .yellow-button",
				".row__two .yellow-button",
				".row__four .yellow-button",
				".row__five .yellow-button",
				".row__one .blue-button",
				".row__two .blue-button",
				".row__four .blue-button",
				".row__five .blue-button",
				"[data-key='20']",
				"[data-key='222']",
				"[data-key='93-R']",
			],
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: el.classList.contains("blue-button")
					? blueColor
					: greyColor;
			},
		})
		.add({
			targets: [
				"[data-key='13'] img",
				"[data-key='8'] img",
			],
			opacity: 0,
			complete: ({animatables})=>{
				animatables[0].target.classList.add('none');
				animatables[1].target.classList.add('none');
			}
		})
		.add({
			duration: 2000,
			targets: [".keyboard"],
			opacity: 1,
			translateY: ["0px", "240px"],
		})
		// display block slides
		.add({
			targets: ".slides",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
			},
		})
		//  A Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR3"));
			},

			targets: ['[data-char="A"]'],
			backgroundColor: [
				{
					value: lightRedColor,
					duration: 500,
				},
				{
					value: redColor,
					duration: 500,
					delay: 18000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 18000,
				},
			],
		})
		// A letter
		.add(
			{
				targets: [".slide-1 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
				fontSize: "50px",
			},
			"-=19000"
		)
		// A image
		.add({
			targets: ".slide-1 .flex div",
			duration: 2000,
			opacity: 1,
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-1 .letter"],
			fontSize: ["50px", "100px"],
			duration: 1500,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-1 .flex div"],
				scale: 2,
				duration: 1500,
				endDelay: 1000,
			},
			"-=1500"
		)
		.add({
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHHR5"));
				},
				targets: ".slide-1 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},"-=1000"
		)
		.add({
			targets: [".slide-1"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})

		//  S Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR6"));				
			},

			targets: ['[data-char="S"]'],
			backgroundColor: [
				{
					value: lightBlueColor,
					duration: 500,
				},
				{
					value: blueColor,
					duration: 500,
					delay: 10000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 10000,
				},
			],
		})
		.add(
			{
				targets: [".slide-2 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=11000"
		)
		// image
		.add({
			targets: ".slide-2 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-2 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add({
				delay: 0,
				targets: [".slide-2 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add({
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHHR8"));
				},
				targets: ".slide-2 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},"-=1000"
		)
		.add({
			targets: [".slide-2"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})


		// D
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR9"));
			},

			targets: ['[data-char="D"]'],
			backgroundColor: [
				{
					value: lightYellowColor,
					duration: 500,
				},
				{
					value: yellowColor,
					duration: 500,
					delay: 13000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 13000,
				},
			],
		})

		.add(
			{
				targets: [".slide-3 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=14000"
		)
		// image
		.add({
			targets: ".slide-3 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
			endDelay: 1000,
		})
		.add({
			targets: [".slide-3 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add({
				delay: 0,
				targets: [".slide-3 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},"-=2000"
		)
		.add({
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHHR11"));
				},
				targets: ".slide-3 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},"-=1000"
		)
		.add({
			targets: [".slide-3"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})

		// F
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR12"));
			},

			targets: ['[data-char="F"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 12000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 12000,
				},
			],
		})

		.add(
			{
				targets: [".slide-4 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=13000"
		)
		// image
		.add({
			targets: ".slide-4 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-4 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-4 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add({
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHHR14"));
				},
				targets: ".slide-4 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},"-=1000"
		)
		.add({
			targets: [".slide-4"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})

		// G Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR15"));
			},

			targets: ['[data-char="G"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 8000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 8000,
				},
			],
		})

		.add(
			{
				targets: [".slide-5 .letter"],
				opacity: [0, 1],
				duration: 3000,
				translateX: ["20%", "0%"],
			},
			"-=9000"
		)
		// image
		.add({
			targets: ".slide-5 .flex div",
			duration: 2000,
			opacity: [0, 1],
			translateX: ["20%", "0%"],
		})
		.add({
			targets: [".slide-5 .letter"],
			fontSize: ["50px", "100px"],
			duration: 2000,
			delay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".slide-5 .flex div"],
				scale: 2,
				duration: 2000,
				endDelay: 1000,
			},
			"-=2000"
		)
		.add(
			{
				begin: (_anim) => {
					store.dispatch("primary", store.getters.howler.value.play("LHHR17"));
				},
				targets: ".slide-5 .word",
				opacity: [0, 1],
				fontSize: ["0em", "4em"],
				translateY: ["20%", "0%"],
				duration: 2000,
				endDelay: 2000,
			},
			"-=1000"
		)
		.add({
			targets: [".slide-5",".slides"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
				animatables[1].target.classList.add('none')
				
				store.getters.howler.value.fade(
					0.3,
					0,
					3000,
					store.getters.secondary.value
				);
			}
		})

		// remove and hide keyboard
		.add({
			targets: [".keyboard",".animation"],
			translateY: "500px",
			opacity: [1, 0],
			duration: 3000,

			complete: ({animatables}) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},

		})
		// cloud left
		.add({
			duration: 6000,
			translateX: ["-100%", "0%"],
			targets: ".left-container",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");

				store.dispatch("secondary", store.getters.howler.value.play("passive"));
				
				store.getters.howler.value.fade(
					0,
					0.3,
					5000,
					store.getters.secondary.value
				);

				store.dispatch("primary", store.getters.howler.value.play("LHHR18-0"));
			},
			endDelay: 7000,
		})
		.add({
			targets: ".left-container",
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR18-1"));
			},
			opacity: 1,
			duration: 8000,
			endDelay: 10000,
		})
		.add({
			targets: ".left-container",
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR18-2"));
			},
			opacity: [1, 0],
			duration: 8000,
			endDelay: 2000,
			complete: ({animatables}) => {
				animatables[0].target.classList.add("none");
			},
		})
		/** *************************************************************************************************
		 * Learning presentation 1                                                                   *
		 ***************************************************************************************************
		 */
		// school bus AAA
		.add({
			targets: ".learning-presentation",
			opacity: [0, 1],
			begin: ({animatables}) => {
					animatables[0].target.classList.remove("none");
			},
		})
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR19"));
			},
			targets: ".a.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 14000,
		})
		.add({
			targets: ".a.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR20-21-22"));
			},
			endDelay: 2300,
		})
		// label
		.add({
			targets: ".a.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],

			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		
		.add({
			targets: [".presentation-1"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})

		// SSS
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR23"));
			},
			targets: ".s.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 12000,
		})
		.add({
			targets: ".s.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR24-25-26"));
			},
			endDelay: 6200,
		})
		// label
		.add({
			targets: ".s.label h2",
			delay: anime.stagger(1200),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],

			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-2"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})


		// DDD
		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR27"));
			},
			targets: ".d.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 5000,
		})
		.add({
			targets: ".d.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR28-29-30"));
			},
			endDelay: 1000,
		})
		// label
		.add({
			targets: ".d.label h2",
			delay: anime.stagger(1000),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],

			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-3"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})


		// FFF

		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR31"));
			},
			targets: ".f.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 8000,
		})
		.add({
			targets: ".f.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR32-33-34"));
			},
			endDelay: 1200,
		})
		// label
		.add({
			targets: ".f.label h2",
			delay: anime.stagger(1000),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],

			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-4"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
			}
		})

		// GGG

		.add({
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR35"));
			},
			targets: ".g.lesson-img",
			width: "250px",
			height: "250px",
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 4000,
				},
			],
			endDelay: 5000,
		})
		.add({
			targets: ".g.lesson-img",
			width: "250px",
			height: "250px",
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR36-37-38"));
			},
			endDelay: 1000,
		})
		// label
		.add({
			targets: ".g.label h2",
			delay: anime.stagger(1000),
			translateX: [
				{
					value: "20px",
				},
				{
					value: "0px",
					duration: 800,
					delay: 200,
				},
			],
			opacity: [
				{
					value: "0",
				},
				{
					value: "1",
					duration: 800,
					delay: 200,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: [".presentation-2", ".learning-presentation"],
			begin: ({animatables})=>{
				animatables[0].target.classList.add('none')
				animatables[1].target.classList.add('none')
			}
		})

		// slide up keyboard
		.add({
			targets: [".animation"],
			translateY: "50px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			targets: [".keyboard", ".animation .key-text-left"],
			translateY: "0px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			duration: 3000,
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR39-40-41-41-43"));
			},
			// endDelay: 2200
		})
		.add({
			targets: [".keyboard", ".animation"],
			opacity: 1,
			delay: 2200,
		})
		.add({
			delay: anime.stagger(1200),
			targets: [
				'[data-char="A"]',
				'[data-char="S"]',
				'[data-char="D"]',
				'[data-char="F"]',
				'[data-char="G"]',
			],
			backgroundColor: [
				{
					value(el) {
						return el.classList.contains("red-button")
							? lightRedColor
							: el.classList.contains("green-button")
							? lightGreenColor
							: el.classList.contains("yellow-button")
							? lightYellowColor
							: lightBlueColor;
					},
					duration: 500,
				},
				{
					value(el) {
						return el.classList.contains("red-button")
							? redColor
							: el.classList.contains("green-button")
							? greenColor
							: el.classList.contains("yellow-button")
							? yellowColor
							: blueColor;
					},
					duration: 500,
					delay: 1000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1000,
				},
			],
		})
		.add(
			{
				delay: anime.stagger(1000),
				targets: ".key-text-left h2",
				opacity: 1,
				translateX: [10, 0],
				duration: 200,
			},
			"-=6000"
		)
		.add({
			delay: 1000,
			targets: ".key-text-left h2",
			opacity: [1, 0],
			duration: 1000,
		})
		// hide animation
		.add({
			targets: [".animation", ".animation .key-text-left"],
			opacity: [1, 0],
			translateY: "500px",
			easing: "easeInOutSine",
			duration: 3000,
			complete: ({animatables}) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			endDelay: 1000,
		})
		// ok thumbsup
		.add({
			targets: ".right-ok",
			opacity: 1,
			duration: 3000,
			translateX: ["-20%", "0%"],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("LHHR44"));				
				store.getters.howler.value.fade(
					0.5,
					0,
					7000,
					store.getters.secondary.value
				);
			},
			endDelay: 5000,
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('one_learn_left');

			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/home_row/learn/left.mp3")],
			sprite: {
				active: [0, 252120, true],
				LHHR11: [254000, 2709.72789115649],
				LHHR12: [258000, 12625.374149659876],
				LHHR14: [272000, 1967.0748299319598],
				LHHR15: [275000, 8902.380952380952],
				LHHR17: [285000, 3552.7664399093055],
				"LHHR18-0": [290000, 12531.746031746025],
				"LHHR18-1": [304000, 18172.33560090705],
				"LHHR18-2": [324000, 8858.276643990905],
				LHHR19: [334000, 18639.365079365063],
				"LHHR20-21-22": [354000, 7731.043083900204],
				LHHR23: [363000, 16803.83219954649],
				"LHHR24-25-26": [381000, 11990.544217687102],
				LHHR27: [394000, 8945.011337868493],
				"LHHR28-29-30": [404000, 5835.555555555573],
				LHHR3: [411000, 18512.766439909286],
				LHHR31: [431000, 11595.396825396847],
				"LHHR32-33-34": [444000, 6453.197278911546],
				LHHR35: [452000, 7251.836734693881],
				"LHHR36-37-38": [461000, 7251.836734693881],
				"LHHR39-40-41-41-43": [470000, 9456.145124716557],
				LHHR44: [481000, 6687.460317460307],
				LHHR5: [489000, 3004.4217687074593],
				LHHR6: [494000, 10919.206349206377],
				LHHR8: [506000, 2268.1632653061],
				LHHR9: [510000, 13919.99999999996],
				passive: [525000, 548571.4285714284, true],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
