<template>
	<div class="learning-presentation none">
		<!-- 6  Right Hand-->
		<div class="presentation-6 none">
			<div class="hyphen lesson-img">
				<img src="@/assets/images/14-Hyphen-min.png"  style="width:100%;height:20%;" alt="Guion" width="100%" height="100%" />
			</div>
			<div class="hyphen red-text label">
				<h2>Ñ</h2>
				<h2>-</h2>
				<h2>Ñ</h2>
			</div>
		</div>
		<div class="presentation-7 none">
			<div class="punto lesson-img">
				<img src="@/assets/images/18-Ball-min.png" alt="Punto" class="w-100 h-100" />
			</div>
			<div class="punto blue-text label">
				<h2>L</h2>
				<h2 style="font-family:auto;">.</h2>
				<h2>L</h2>
			</div>
		</div>

		<div class="presentation-8 none">
			<div class="comma lesson-img">
				<img src="@/assets/images/22-pool-noodle.jpg"  alt="Comma" class="w-100 h-100" />
			</div>
			<div class="comma yellow-text label">
				<h2>K</h2>
				<h2 style="font-family:auto;">,</h2>
				<h2>K</h2>
			</div>
		</div>

		<div class="presentation-9 none">
			<div class="m lesson-img">
				<img src="@/assets/images/29-Music.jpg" alt="Música"  class="w-100 h-100" />
			</div>
			<div class="m green-text label">
				<h2>J</h2>
				<h2>M</h2>
				<h2>J</h2>
			</div>
		</div>
		<div class="presentation-10 none">
			<div class="n lesson-img">
				<img src="@/assets/images/30-heart.jpg" alt="Novia Novio" class="w-100 h-100" />
			</div>
			<div class="n green-text label">
				<h2>J</h2>
				<h2>N</h2>
				<h2>J</h2>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "Presentation",
};
</script>

<style scoped>
</style>