<template>
	<div class="timeline-controls" v-if="!loading && !start && !next">
		<button class="btn pause" id="reveal-pause-btn" v-if="playing && !activity" @click="pauseAnimation">
			<i class="fas fa-pause"></i>
		</button>
		<button class="btn play" id="reveal-pause-btn" v-if="!playing && !activity" @click="playAnimation">
			<i class="fas fa-play"></i>
		</button>
		<button class="btn full screen" id="full-screen-btn" v-if="!maximized" @click="setMaximized(true)">
			<i class="fas fa-expand"></i>
		</button>
		<button class="btn small screen " id="small-screen-btn" v-if="maximized" @click="setMaximized(false)">
			<i class="fas fa-compress"></i>
		</button>
	</div>
</template>

<script>
import { useStore } from 'vuex';


export default {
	name: "MediaControl",
	setup(){
		const store = useStore();
 
		return { 
			loading: store.getters.loading,
			start: store.getters.start,
			next: store.getters.next,
			playing: store.getters.playing,
			maximized: store.getters.maximized,
			activity : store.getters.activity,
			setMaximized: (value)=>store.dispatch('maximized', value),
			playAnimation: ()=>store.dispatch('playAnimation'),
			pauseAnimation: ()=>store.dispatch('pauseAnimation')
		}
	}
};
</script>

<style scoped>

</style>
