<template>
	<div class="congrats">
		<h2>
			¡Felicidades!<br>
			Has completado con éxito<br>la parte de aprendizaje de la etapa 1.<br>
			Ahora podrás comprobar<br> si de verdad has aprendido las teclas: <br><br>
			<AuthButton to="/one/practice" style="position: static;">¡Tu primer entreno de tecleo!</AuthButton>
		</h2>
	</div>	
</template>

<script>
import AuthButton from '../../../../components/AuthButton.vue'
import { setupActivityTwo } from "@/common/activities";

export default {
	components: { AuthButton },
	name: "Congrats",
	mounted: ()=> setupActivityTwo('wrapper2', 'wrapper2', '.right-to-left .series-row'),
};
</script>

<style scoped>
</style>