<template>
	<div class="guest-page">
		<slot/>
	</div>
</template>

<script>
export default {
	name: "Guest"
}
</script>

<style scoped>
	.guest-page {
		background: #41E0D0;
		height: 100vh;
		display:flex;
		justify-content: center;
		align-items: center;
	}

</style>