import { createApp } from "vue";
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@/assets/css/typerbase.css';
import '@/assets/css/style.css';
import '@/assets/css/playButton.css';

import 'jquery'
import 'jquery-ui'
import 'popper.js'
import 'bootstrap'
import 'animejs'
import '@shopify/draggable'
import 'howler'
import '@stripe/stripe-js';

import 'nprogress/nprogress.css';


import App from "./App.vue";
import router from "./router";
import store from "./store";

createApp(App)
	.use(store)
	.use(router)
	.mount("#app");
