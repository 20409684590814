<template>
	<div class="learning-presentation none">
		<div class="presentation-1 none">
			<div class="z lesson-img">
				<img src="@/assets/images/12-Glass-filled-with-Tomato-Juice-(1)-min.png" alt="Zumo" class="w-100 h-100" />
			</div>
			<div class="z red-text label">
				<h2>A</h2>
				<h2>Z</h2>
				<h2>A</h2>
			</div>
		</div>
		<div class="presentation-2 none">
			<div class="x lesson-img">
				<img src="@/assets/images/15-xylophone-min.png" style="transform: rotate(-90deg)" alt="xilofone"  class="w-100 h-100" />
			</div>
			<div class="x blue-text label">
				<h2>S</h2>
				<h2>X</h2>
				<h2>S</h2>
			</div>
		</div>
		<div class="presentation-3 none">
			<div class="c lesson-img">
				<img src="@/assets/images/20-castle-min.png" alt="Castillo" class="w-100 h-100" />
			</div>
			<div class="c yellow-text label">
				<h2>D</h2>
				<h2>C</h2>
				<h2>D</h2>
			</div>
		</div>
		<div class="presentation-4 none">
			<div class="v lesson-img">
				<img src="@/assets/images/25-Valley.jpg"  alt="Valle" class="w-100 h-100" />
			</div>
			<div class="v green-text label">
				<h2>F</h2>
				<h2>V</h2>
				<h2>F</h2>
			</div>
		</div>
		<div class="presentation-5 none">
			<div class="b lesson-img">
				<img src="@/assets/images/26-scarf.jpg" alt="Bufanda" class="w-100 h-100" />
			</div>
			<div class="b green-text label">
				<h2>F</h2>
				<h2>B</h2>
				<h2>F</h2>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Presentation",
};
</script>

<style scoped>
</style>