<template>
	<div class="container-fluid training-container">
		<div class="row">
			<div
				class="training-left pr-0"
				:class="controls && maximized ? 'none' : 'col-md-2'"
			>
				<AuthSidebar />
			</div>
			<div
				style="
					position: absolute;
					bottom: 1em;
					left: 3em;
					z-index: 1000;
				"
				v-if="controls && (!paidOnly || sptt.paid) && (lesson && !sptt[lesson])"
			>
				<MediaControl />
			</div>
			<div
				id="lesson-1-animation"
				:class="controls && maximized ? 'col-md-12' : 'col-md-10'"
			>
				<NotPaid v-if="!sptt.paid && lesson!==false"/>
				<Learned v-else-if="!next && lesson && sptt[lesson]"/>
				<slot v-else/>
			</div>
		</div>
	</div>
</template>

<script>
import Learned from '../common/components/Learned.vue'
import NotPaid from '../common/components/NotPaid.vue'
import MediaControl from "../common/components/MediaControl.vue";
import AuthSidebar from "../common/components/AuthSidebar.vue";
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
	components: { AuthSidebar, MediaControl, Learned, NotPaid },
	name: "Auth",
	setup() {
		const store = useStore();
		const controls = ref(false);
		const paidOnly = ref(false);
		const lesson = ref(false);
		const route = useRoute();
		watch(
			() => route.meta,
			(meta) => {
				if ('controls' in meta) {
					controls.value = meta.controls;
				} else {
					controls.value = false;
				}
				
				if('paidOnly' in meta){
					paidOnly.value = meta.paidOnly;
				}else{
					paidOnly.value = true;
				}

				if('lesson' in meta){
					lesson.value = meta.lesson;
				}else{
					lesson.value = false;
				}

			},
			{ immediate: true }
		);


		return {
			controls,
			paidOnly,
			lesson,
			sptt: store.getters.sptt,
			maximized: store.getters.maximized,
			next: store.getters.next
		};
	},
};
</script>