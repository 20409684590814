<template>
	<router-link to="/">
		<slot/>
	</router-link>
</template>

<script>
export default {
	name: "Link"
}
</script>

<style scoped>
	a {
		color:#41E0D0;
		text-decoration: none;
	}
</style>