import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import { windowBlur } from "@/common/helpers";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('two_activity_left');

	store.getters.animation.value
		.add({
			targets: [".left-hand-drag", "#overlay-animation"],
			opacity: [0, 1],
			duration: 2000,
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");

				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		/** *************************************************************************************************
		 * Activity 1
		 ***************************************************************************************************
		 */
		// show draggable and hands  -> activity 1
		.add({
			targets: ["#overlay-animation"],
			easing: "easeInOutSine",
			duration: 5700,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR45"));
			},
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				// start Activity 1
				window.removeEventListener("blur", windowBlur);

				store.dispatch("onActivity");
			},
		})

		// activity 1 stopped
		.add({
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			delay: 1000,
			targets: [".left-hand-drag"],
			duration: 3000,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR45-1"));

				animatables[0].target.classList.add("fade-out");
			},
		})
		.add({
			targets: [".left-hand-drag", ".left-to-right", "#overlay-animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.remove("none");
				animatables[2].target.classList.remove("none");

				store.dispatch("primary", store.getters.howler.value.play("LHUR46"));
			},
			duration: 2000,
			complete: ({ animatables }) => {
				animatables[2].target.classList.add("none");
				// Start activity 2
				window.removeEventListener("blur", windowBlur);
				store.dispatch("onActivity");
			},
		})

		// activit 2 stopped
		.add({
			duration: 1000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})

		.add({
			duration: 4000,
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHUR46-1"));
			},
		})
		.add({
			targets: ['.left-to-right'],
			opacity: [1,0],
			duration: 2500,
			complete: ({animatables})=>{
				animatables[0].target.classList.remove("none");
			}
		})


		// ok thumbsup
		.add({
			targets: ".right-ok",
			opacity: [0, 1],
			duration: 1000,
			translateX: ["-20%", "0%"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('two_activity_left');

			},
		});
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/upper_row/activity/left.mp3")],
			sprite: {
				active: [0, 252120, true],
				"LHUR45-1": [254000, 1681.0657596371925],
				LHUR45: [257000, 5702.199546485246],
				"LHUR46-1": [264000, 3832.857142857165],
				LHUR46: [269000, 2178.684807256218],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
