<template>
	<Loader v-if="loading" />
	<StartButton v-if="start" @click="startLesson" />
	<div class="animation none">
		<Keyboard />
		<Hands />
	</div>
	<AuthButton v-if="next" to="/three/learn/left">¡Seguimos!</AuthButton>
</template>

<script>
import { onBeforeUnmount, onMounted } from "vue";
import { useStore } from "vuex";

import Hands from './Hands.vue'
import Keyboard from "@/components/Keyboard/index";
import AuthButton from "@/components/AuthButton.vue";
import StartButton from "@/components/StartButton.vue";
import Loader from "@/components/Loader.vue";

import { setupLesson, startLesson } from "@/assets/js/bottom_row/learn/both";

export default {
	name: "TwoLearnBoth",
	components: { Loader, StartButton, AuthButton, Keyboard, Hands },
	setup() {
		const store = useStore();

		setupLesson();

		onMounted(() => {
			store.dispatch('loading',true);
			store.dispatch('start',false);
			store.dispatch('next',false);
		});
		onBeforeUnmount(() => {
			store.dispatch("pauseAnimation");
			store.dispatch("reset");
		});

		return {
			loading: store.getters.loading,
			start: store.getters.start,
			next: store.getters.next,
			startLesson,
		};
	},
};
</script>

<style scoped>
#explainer-video {
	width: 100%;
	height: 100%;
}
.lesson-finished {
	color: #33649f;
}
</style>