import Home from "../views/Main/HomePage";

// Main Pages
const layout = "Main";
export default [
	// Main pages
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/about",
		name: "About",
		component: () => import("@/views/Main/AboutPage"),
	},
	{
		path: "/contact",
		name: "Contact",
		component: () => import("@/views/Main/ContactPage"),
	},
	{
		path: "/faq-individuals",
		name: "IndividualFAQ",
		component: () => import("@/views/Main/IndividualFAQ"),
	},
	{
		path: "/terms-and-conditions",
		name: "TermsAndConditions",
		component: () => import("@/views/Main/TermsAndConditions"),
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		component: () => import("@/views/Main/PrivacyPolicy"),
	},

];
