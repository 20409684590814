<template>
	<div class="bronze_gif none" >
		<div style="height: 100vh" class="d-flex justify-content-center align-items-center">
			<img src="@/assets/images/bronze-medal.gif"  alt="bronze medal"  />
		</div>
	</div>
	<div class="silver none" >
		<div style="height: 100vh" class="d-flex justify-content-center align-items-center">
			<img src="@/assets/images/silver-medal.gif" style="height:300px" alt="silver medal"  />
		</div>
	</div>
	<div class="silver_gif none" >
		<div style="height: 100vh" class="d-flex justify-content-center align-items-center">
			<img src="@/assets/images/silver-medal.gif" alt="silver medal"  />
		</div>
	</div>
	<div class="gold none" >
		<div style="height: 100vh" class="d-flex justify-content-center align-items-center">
			<img src="@/assets/images/gold-medal.gif" alt="gold medal" style="height:300px" />
		</div>
	</div>
	<div class="gold_gif none" >
		<div class="flex" style=" justify-content:center;">
			<img src="@/assets/images/gold-medal.gif" alt="gold medal"  />
		</div>
	</div>
</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>