<template>
	<div class="oval-thought-container left-container none">
		<div
			class="left-cloud"
			:style="{ backgroundImage: 'url(' + require('@/assets/images/cloud-left.png') + ')' }"
		>
			<div class="cloud-text">
				<span style="font-size: 0.7em">Historia</span>
				<br />
				<span class="red-text">A&nbsp;</span>
				<span class="blue-text">S&nbsp;</span>
				<span class="yellow-text">D&nbsp;</span>
				<span class="green-text">F&nbsp;</span>
				<span class="green-text">G</span>
			</div>
		</div>

		<div
			class="left-hand-cloud"
			:style="{ backgroundImage: 'url(' + require('@/assets/images/left-hand.png') + ')' }"
		></div>
	</div>
</template>

<script>
export default {
	name: "CloudHistory",
};
</script>

<style scoped>
</style>