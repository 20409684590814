<template>
	<div class="d-flex flex-column justify-content-between" style="height:100vh;">
		<div>
			<Header/>
		</div>
		<div style="flex:auto;background-color: #efefef;">
			<slot/>
		</div>
		
	</div>
</template>

<script>

import Header from "@/common/components/Header";

export default {
	name: "Main",
	components: {
		Header,
	}
}
</script>

<style>

</style>