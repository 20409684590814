<template>
	<div class="right-like none" style="height: 80%">
		<img src="@/assets/images/like-right.png" alt="right thumb" />
	</div>
</template>

<script>
export default {
	name: "RightOkay",
};
</script>

<style scoped>
.right-like img {
	width: 500px;
	height: 500px;
}
</style>