<template>
	<div class="slides none">
		<!-- RIGHT SIDE -->
		<div class="slide-6 red-text">
			<div class="flex">
				<h2 class="letter">Ñ</h2>
				<div>
					<img src="@/assets/images/2-Gnu-min.png" class="symbol" alt="nu" />
				</div>
			</div>

			<h2 class="word">Ñu</h2>
		</div>
		<div class="slide-7 blue-text">
			<div class="flex">
				<h2 class="letter">L</h2>
				<div>
					<img src="@/assets/images/4-lion-min.png" class="symbol" alt="Lion" />
				</div>
			</div>
			<h2 class="word">León</h2>
		</div>
		<div class="slide-8 yellow-text">
			<div class="flex">
				<h2 class="letter">K</h2>
				<div>
					<img src="@/assets/images/6-kola-min.png" class="symbol" alt="Koala" />
				</div>
			</div>
			<h2 class="word">Koala</h2>
		</div>
		<div class="slide-9 green-text">
			<div class="flex">
				<h2 class="letter">J</h2>
				<div>
					<img src="@/assets/images/9-Jungle-min.png" class="symbol" alt="jungle" />
				</div>
			</div>
			<h2 class="word">Jungla</h2>
		</div>
		<div class="slide-10 green-text">
			<div class="flex">
				<h2 class="letter">H</h2>
				<div>
					<img src="@/assets/images/10-hammock-min.png" class="symbol" alt="hammock" />
				</div>
			</div>
			<h2 class="word">Hamaca</h2>
		</div>
	</div>

</template>

<script>
export default {
	name: "Slides",
};
</script>

<style scoped>
</style>