<template>	
	<main class="h-100 w-100 d-flex flex-column justify-content-center align-items-center"> 
		<p class="text-center font-weight-bold" style="color: #325591;">You are not authorized to view this content. Please contact your school.</p>
	</main> 
</template>

<script>
import AuthButton from '@/components/AuthButton.vue'
export default {
	name: "NotPaid",
	components: { AuthButton},
};
</script>
