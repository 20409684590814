import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import { windowBlur } from '@/common/helpers'
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('one_learn_both');
	store.getters.animation.value
		.add({
			targets: ".animation",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		.add({
			targets: ".animation",
			opacity: 1,
			translateY: "0px",
		})
		.add({
			duration: 3000,
			targets: ".keyboard",
			opacity: 1,
			translateY: ["-300px", "0px"],
		})
		.add({
			// hands in Ñ
			duration: 4000,
			targets: ".hand-container",
			opacity: 1,
			translateY: [
				{
					value: "200%",
					duration: 500,
				},
				{
					value: "0",
					duration: 2000,
					delay: 1000,
				},
			],
		})
		.add({
			duration: 14000,
			target: ".green-button",
			backgroundColor: greenColor,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR1-0"));
			},
		})
		.add({
			// make keys grey except red
			targets: [
				".green-button",
				".yellow-button",
				".blue-button",
				'[data-key="32"]',
			],
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR1-1"));
			},
			backgroundColor: blackColor,
			delay: 0,
			endDelay: 0,
		})
		.add(
			{
				// show little finger labels
				delay: 0,
				targets: [".little-finger-left", ".little-finger-right"],
				opacity: 1,
				endDelay: 3000,
			},
			"-=1000"
		)
		.add({
			delay: 3000,
			// duration: 3000,
			targets: ".blue-button",
			backgroundColor: blueColor,
			endDelay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".ring-finger-left", ".ring-finger-right"],
				opacity: 1,
			},
			"-=1000"
		)

		.add({
			delay: 2000,
			targets: ".yellow-button",
			backgroundColor: yellowColor,
			endDelay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".middle-finger-left", ".middle-finger-right"],
				opacity: 1,
				endDelay: 3000,
			},
			"-=1000"
		)
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR1-2"));
			},
			targets: ".green-button",
			opacity: 1,
		})
		.add({
			delay: 4000,
			targets: ".green-button",
			backgroundColor: greenColor,
			endDelay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".index-finger-left", ".index-finger-right"],
				opacity: 1,
				endDelay: 4000,
			},
			"-=1000"
		)
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR1-3"));
			},
			targets: ['[data-key="32"]'],
			backgroundColor: greyColor,
			endDelay: 0,
		})
		.add(
			{
				delay: 0,
				targets: [".thumb-left", ".thumb-right"],
				opacity: 1,
				endDelay: 5000,
			},
			"-=2000"
		)
		.add({
			delay: 2000,
			targets: [".thumb-left", ".thumb-right"],
			opacity: 1,
			endDelay: 2000,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR1-4"));
			},
		})

		.add({
			// hide other rows and show home rows
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-0"));
			},
			endDelay: 9000,
			duration: 4000,
			targets: [
				".row__one .red-button",
				".row__two .red-button",
				".row__four .red-button",
				".row__five .red-button",
				".row__one .green-button",
				".row__two .green-button",
				".row__four .green-button",
				".row__five .green-button",
				".row__one .yellow-button",
				".row__two .yellow-button",
				".row__four .yellow-button",
				".row__five .yellow-button",
				".row__one .blue-button",
				".row__two .blue-button",
				".row__four .blue-button",
				".row__five .blue-button",
				"[data-key='20']",
				"[data-key='222']",
				"[data-key='93-R']"
			],
			color(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: el.classList.contains("blue-button")
					? blueColor
					: greyColor;
			},
		})
		.add({
			duration: 4000,
			targets: [
				"[data-key='13'] img",
				"[data-key='8'] img",
			],
			opacity: 0,
			complete: ({animatables})=>{
				animatables[0].target.classList.add('none');
				animatables[1].target.classList.add('none');
			}
		}, "-=13000")
		.add({
			// one by one animate
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-1"));
			},
			delay: 9500,
			targets: '[data-char="A"]',
			backgroundColor: [
				{
					value: lightRedColor,
					duration: 300,
					delay: 12500,
				},
				{
					value: redColor,
					duration: 300,
					delay: 1500,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 300,
					delay: 12500,
				},
				{
					value: "0em",
					duration: 300,
					delay: 1500,
				},
			],
			duration: 4000,
			endDelay: 0,
		})
		.add({
			delay: 1000,
			targets: '[data-char="S"]',
			backgroundColor: [
				{
					value: lightBlueColor,
					duration: 300,
				},
				{
					value: blueColor,
					duration: 300,
					delay: 1500,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 300,
				},
				{
					value: "0em",
					duration: 300,
					delay: 1500,
				},
			],
		})
		.add({
			delay: 500,
			targets: '[data-char="D"]',
			backgroundColor: [
				{
					value: lightYellowColor,
					duration: 300,
				},
				{
					value: yellowColor,
					duration: 300,
					delay: 1300,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 300,
				},
				{
					value: "0em",
					duration: 300,
					delay: 1300,
				},
			],

			// duration: 1600,
			endDelay: 500,
		})
		.add({
			targets: ['[data-char="F"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 300,
				},
				{
					value: greenColor,
					duration: 300,
					delay: 1600,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 300,
				},
				{
					value: "0em",
					duration: 300,
					delay: 1600,
				},
			],
			endDelay: 0,
		})
		.add({
			delay: 200,
			targets: ['[data-char=" "]'],
			backgroundColor: [
				{
					value: lightGreyColor,
					duration: 300,
				},
				{
					value: greyColor,
					duration: 300,
					delay: 1600,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 300,
				},
				{
					value: "0em",
					duration: 300,
					delay: 1600,
				},
			],
			endDelay: 1000,
		})
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-2"));
			},
			targets: ['[data-char="G"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
					delay: 1000,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 3600,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
					delay: 1000,
				},
				{
					value: "0em",
					duration: 500,
					delay: 3600,
				},
			],
			endDelay: 2000,
		})
		.add({
			targets: ['[data-char="F"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 1200,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1200,
				},
			],
		})
		.add({
			delay: 500,
			targets: ['[data-char="G"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 2500,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 2500,
				},
			],
		})

		.add({
			targets: '[data-char="Ñ"]',
			opacity: 1,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-3"));
			},
			endDelay: 6000,
		})
		.add({
			targets: '[data-char="Ñ"]',
			backgroundColor: [
				{
					value: lightRedColor,
					duration: 500,
				},
				{
					value: redColor,
					duration: 500,
					delay: 1000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1000,
				},
			],
		})
		.add({
			targets: '[data-char="L"]',
			backgroundColor: [
				{
					value: lightBlueColor,
					duration: 500,
				},
				{
					value: blueColor,
					duration: 500,
					delay: 1000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1000,
				},
			],
		})
		.add({
			targets: '[data-char="K"]',
			backgroundColor: [
				{
					value: lightYellowColor,
					duration: 500,
				},
				{
					value: yellowColor,
					duration: 500,
					delay: 1000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1000,
				},
			],
		})
		.add({
			targets: ['[data-char="J"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 1000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1000,
				},
			],
		})
		.add({
			delay: 1000,
			targets: ['[data-char=" "]'],
			backgroundColor: [
				{
					value: lightGreyColor,
					duration: 500,
				},
				{
					value: greyColor,
					duration: 500,
					delay: 1500,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 1500,
				},
			],
			endDelay: 2000,
		})

		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-3-1"));
			},
			targets: ['[data-char="H"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 4200,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 4200,
				},
			],
			endDelay: 1000,
		})
		.add({
			targets: ['[data-char="J"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 2500,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 2500,
				},
			],
		})
		.add({
			targets: ['[data-char="H"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 2500,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 2500,
				},
			],
		})
		.add({
			targets: ['[data-char="H"]'],
			opacity: 1,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-4"));
			},
			duration: 2000,
			endDelay: 1500,
		})

		.add({
			// f and j
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-5"));
			},
			targets: ['[data-char="F"]', '[data-char="J"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 7000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 100,
				},
				{
					value: "0em",
					duration: 500,
					delay: 7000,
				},
			],
			endDelay: 1000,
		})
		.add({
			// Underline
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-6"));
			},
			targets: ".under",
			duration: 2000,
			color: [greyColor, "#ffffff"],
			fontSize: "20px",
			endDelay: 4000,
		})
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-7"));
			},

			targets: ['[data-char="J"]', '[data-char="F"]'],
			backgroundColor: [
				{
					value: lightGreenColor,
					duration: 500,
				},
				{
					value: greenColor,
					duration: 500,
					delay: 5000,
				},
			],
			top: [
				{
					value: "0.2em",
					duration: 500,
				},
				{
					value: "0em",
					duration: 500,
					delay: 5000,
				},
			],
		})
		.add({
			delay: 0,
			targets: [
				'[data-char="A"]',
				'[data-char="S"]',
				'[data-char="D"]',
				'[data-char="F"]',
				'[data-char="J"]',
				'[data-char="K"]',
				'[data-char="L"]',
				'[data-char="Ñ"]',
			],
			backgroundColor(el) {
				return el.classList.contains("red-button")
					? lightRedColor
					: el.classList.contains("green-button")
					? lightGreenColor
					: el.classList.contains("yellow-button")
					? lightYellowColor
					: lightBlueColor;
			},
			top: "0.2em",
			duration: 500,
			endDelay: 10000,
		})
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR2-8"));
			},
			targets: [".home-row"],
			backgroundColor(el) {
				return el.classList.contains("red-button")
					? redColor
					: el.classList.contains("green-button")
					? greenColor
					: el.classList.contains("yellow-button")
					? yellowColor
					: blueColor;
			},
			top: "0em",
			duration: 500,
			endDelay: 17000,
		})

		.add({
			// remove hands
			targets: ".hand-container",
			opacity: "0",
			easing: "easeInOutSine",
			duration: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('one_learn_both');

			},
		});
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/home_row/learn/both.mp3")],
			sprite: {
				active: [0, 252120, true],
				"LHHR1-0": [254000, 12691.42857142856],
				"LHHR1-1": [268000, 13205.623582766464],
				"LHHR1-2": [283000, 9349.092970521553],
				"LHHR1-3": [294000, 4090.249433106578],
				"LHHR1-4": [300000, 888.1632653061047],
				"LHHR2-0": [302000, 12504.42176870746],
				"LHHR2-1": [316000, 24514.263038548735],
				"LHHR2-2": [342000, 13595.260770975074],
				"LHHR2-3-1": [357000, 13331.950113378696],
				"LHHR2-3": [372000, 17711.269841269826],
				"LHHR2-4": [391000, 934.8979591836724],
				"LHHR2-5": [393000, 6778.095238095261],
				"LHHR2-6": [401000, 1554.2857142856974],
				"LHHR2-7": [404000, 12644.67120181405],
				"LHHR2-8": [418000, 16921.904761904785],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
