<template>
	<div class="learning-presentation none">
		<div class="presentation-1">
			<div class="a lesson-img">
				<img
					src="@/assets/images/1-bus-min.png"
					alt="School Bus"
					class="w-100 h-100"
				/>
			</div>
			<div class="a red-text label">
				<h2>A</h2>
				<h2>A</h2>
				<h2>A</h2>
			</div>
		</div>
		<div class="presentation-2">
			<div class="s lesson-img">
				<img
					src="@/assets/images/3-sofa-min.png"
					alt="School Bus"
					class="w-100 h-100"
				/>
			</div>
			<div class="s blue-text label">
				<h2>S</h2>
				<h2>S</h2>
				<h2>S</h2>
			</div>
		</div>
		<div class="presentation-3">
			<div class="d lesson-img">
				<img
					src="@/assets/images/5-dune-min.png"
					alt="School Bus"
					class="w-100 h-100"
				/>
			</div>
			<div class="d yellow-text label">
				<h2>D</h2>
				<h2>D</h2>
				<h2>D</h2>
			</div>
		</div>
		<div class="presentation-4">
			<div class="f lesson-img">
				<img
					src="@/assets/images/7-lighhouse-min.png"
					alt="School Bus"
					class="w-100 h-100"
				/>
			</div>
			<div class="f green-text label">
				<h2>F</h2>
				<h2>F</h2>
				<h2>F</h2>
			</div>
		</div>
		<div class="presentation-5">
			<div class="g lesson-img">
				<img
					src="@/assets/images/8-sun-glasses-min.png"
					alt="School Bus"
					class="w-100 h-100"
				/>
			</div>
			<div class="g green-text label">
				<h2>F</h2>
				<h2>G</h2>
				<h2>F</h2>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: "Presentation",
};
</script>

<style scoped>
</style>