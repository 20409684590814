<template>
	<div class="congrats">
		<h2>
			¡Muy bien!
			<br />
			Has completado la parte de aprendizaje de la etapa 2.
			<br />
			Ahora puedes continuar con el entreno:<br /><br />
			<AuthButton to="/two/practice" style="position: static;">Tu entreno de tecleo</AuthButton>
		</h2>
	</div>
</template>

<script>
import AuthButton from '../../../../components/AuthButton.vue'
import { setupActivityTwo } from "@/common/activities";

export default {
	components: { AuthButton },
	name: "Congrats",
	mounted: ()=> setupActivityTwo('wrapper2', 'wrapper2', '.right-to-left .series-row'),
};
</script>

<style scoped>
</style>