import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import {
	redColor,
	lightRedColor,
	yellowColor,
	lightYellowColor,
	greenColor,
	lightGreenColor,
	blueColor,
	lightBlueColor,
	greyColor,
	blackColor,
	lightGreyColor,
} from "@/common/colors";
import { windowBlur } from "@/common/helpers";
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('three_learn_right');

	store.getters.animation.value
		.add({
			targets: ".animation",
			opacity: 1,
			translateY: "0px",
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		.add({
			duration: 2000,
			targets: [".keyboard"],
			opacity: 1,
			translateY: ["0px", "240px"],
		})

		.add({
			duration: 7000,
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR1"));
			},
		})
	

		// display block slides
		.add({
			targets: ".slides",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})



		
	// presentation of bottom key with images starts 

	// hyphen Key in keyboard
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR2"));
		},
		targets: ['[data-char="-"]'],
		backgroundColor: [
			{ value: lightRedColor, duration: 1000 },
			{ value: redColor, duration: 1000, delay: 8000 },
		],
		top: [
			{ value: '0.2em', duration: 1000 },
			{ value: '0em', duration: 1000, delay: 8000 },
		],
	})
	// - letter in block slides
	.add({
		targets: ['.slide-6 .letter'],
		opacity: [0, 1],
		duration: 3000,
		translateX: ['20%', '0%'],
		fontSize: '50px',
	}, '-=9000')
	// - image in block slides
	.add({
		targets: '.slide-6 .flex div',
		duration: 2000,
		opacity: 1,
		translateX: ['20%', '0%'],
	})
	.add({
		targets: ['.slide-6 .letter'],
		fontSize: ['50px', '100px'],
		duration: 1500,
	})
	.add({
		targets: ['.slide-6 .flex div'],
		scale: 2,
		duration: 1500,
	},'-=1500')
	// RHBR4 upon completion of aimation
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR4"));

		},
		targets: '.slide-6 .word',
		opacity: [0, 1],
		fontSize: ['0em', '4em'],
		translateY: ['20%', '0%'],
		duration: 3000,
		endDelay: 1000,
	},'-=1000')

		
		.add({
			targets: [".slide-6"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
	// hyphen slide ends


	// punto Key in keyboard
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR5"));
		},
		targets: ['[data-char="."]'],
		backgroundColor: [
			{ value: lightBlueColor, duration: 1000 },
			{ value: blueColor, duration: 1000, delay: 6500 },
		],
		top: [
			{ value: '0.2em', duration: 1000 },
			{ value: '0em', duration: 1000, delay: 6500 },
		],
		endDelay: 1000,
	})
	// . letter in block slides
	.add({
		targets: ['.slide-7 .letter'],
		opacity: [0, 1],
		duration: 3000,
		translateX: ['20%', '0%'],
		fontSize: '50px',
	}, '-=9000')
	// . image in block slides
	.add({
		targets: '.slide-7 .flex div',
		duration: 2000,
		opacity: 1,
		translateX: ['20%', '0%'],
	})
	.add({
		targets: ['.slide-7 .letter'],
		fontSize: ['50px', '100px'],
		duration: 1500,
	})
	.add({
		targets: ['.slide-7 .flex div'],
		scale: 2,
		duration: 1500,
	},'-=1500')
	// RHBR7 upon completion of aimation
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR7"));
		},
		targets: '.slide-7 .word',
		opacity: [0, 1],
		fontSize: ['0em', '4em'],
		translateY: ['20%', '0%'],
		duration: 3000,
		endDelay: 1000,
	},'-=1000')


	.add({
			targets: [".slide-7"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
	// punto slide ends here




	// Comma Key in keyboard
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR8"));
		},
		targets: ['[data-char=","]'],
		backgroundColor: [
			{ value: lightYellowColor, duration: 1000 },
			{ value: yellowColor, duration: 1000, delay: 20000 },
		],
		top: [
			{ value: '0.2em', duration: 1000 },
			{ value: '0em', duration: 1000, delay: 20000 },
		],
		endDelay: 1000,
	})
	// , letter in block slides
	.add({
		targets: ['.slide-8 .letter'],
		opacity: [0, 1],
		duration: 3000,
		translateX: ['20%', '0%'],
		fontSize: '50px',
	}, '-=21000')
	// , image in block slides
	.add({
		targets: '.slide-8 .flex div',
		duration: 2000,
		opacity: 1,
		translateX: ['20%', '0%'],
	})
	.add({
		targets: ['.slide-8 .letter'],
		fontSize: ['50px', '100px'],
		duration: 1500,
	})
	.add({
		targets: ['.slide-8 .flex div'],
		scale: 2,
		duration: 1500,
	},'-=1500')
	// RHBR10 upon completion of aimation
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR10"));
		},
		targets: '.slide-8 .word',
		opacity: [0, 1],
		fontSize: ['0em', '4em'],
		translateY: ['20%', '0%'],
		duration: 1500,
		endDelay: 1000,
	},'-=1000')

		.add({
			targets: [".slide-8"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
	// comma slide ends here




		// M Key in keyboard
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR11"));

			},
			targets: ['[data-char="M"]'],
			backgroundColor: [
				{ value: lightGreenColor, duration: 1000 },
				{ value: greenColor, duration: 1000, delay: 10000 },
			],
			top: [
				{ value: '0.2em', duration: 1000 },
				{ value: '0em', duration: 1000, delay: 10000 },
			],
			endDelay: 1000,
		})
		// M letter in block slides
		.add({
			targets: ['.slide-9 .letter'],
			opacity: [0, 1],
			duration: 3000,
			translateX: ['20%', '0%'],
			fontSize: '50px',
		}, '-=12000')
		// M image in block slides
		.add({
			targets: '.slide-9 .flex div',
			duration: 2000,
			opacity: 1,
			translateX: ['20%', '0%'],
		})
		.add({
			targets: ['.slide-9 .letter'],
			fontSize: ['50px', '100px'],
			duration: 1500,
		})
		.add({
			targets: ['.slide-9 .flex div'],
			scale: 2,
			duration: 1500,
		},'-=1500')
		// LHBR11 upon completion of aimation
		.add({
			begin: (_anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR13"));
			},
			targets: '.slide-9 .word',
			opacity: [0, 1],
			fontSize: ['0em', '4em'],
			translateY: ['20%', '0%'],
			duration: 1500,
			endDelay: 1000,
		},'-=1000')
	
	

	.add({
			targets: [".slide-9"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// M slide ends here



	// N Key in keyboard
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR14"));
		},
		targets: ['[data-char="N"]'],
		backgroundColor: [
			{ value: lightGreenColor, duration: 1000 },
			{ value: greenColor, duration: 1000, delay: 11500 },
		],
		top: [
			{ value: '0.2em', duration: 1000 },
			{ value: '0em', duration: 1000, delay: 10500 },
		],
		endDelay: 1000,
	})
	// N letter in block slides
	.add({
		targets: ['.slide-10 .letter'],
		opacity: [0, 1],
		duration: 3000,
		translateX: ['20%', '0%'],
		fontSize: '50px',
	}, '-=12000')
	// bufanda image in block slides
	.add({
		targets: '.slide-10 .flex div',
		duration: 2000,
		opacity: 1,
		translateX: ['20%', '0%'],
	})
	.add({
		targets: ['.slide-10 .letter'],
		fontSize: ['50px', '100px'],
		duration: 1500,
	})
	.add({
		targets: ['.slide-10 .flex div'],
		scale: 2,
		duration: 1500,
	},'-=1500')
	// RHBR16 upon completion of aimation
	.add({
		begin: (_anim) => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR16"));

		},
		targets: '.slide-10 .word',
		opacity: [0, 1],
		fontSize: ['0em', '4em'],
		translateY: ['20%', '0%'],
		duration: 4000,
		endDelay: 1000,
	},'-=1000')



	.add({
			targets: [".slide-10", ".slides"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");

				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
		})
	// N slide ends here



	// remove and hide keyboard
		.add({
			targets: [".keyboard", ".animation"],
			translateY: "500px",
			opacity: [1, 0],
			duration: 3000,

			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.add("none");
			},
		})

		// cloud  Right
		.add({
			duration: 8000,
			translateX: ["100%", "0%"],
			targets: ".right-container",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.dispatch("secondary", store.getters.howler.value.play("passive"));

				store.getters.howler.value.fade(
					0,
					0.3,
					5000,
					store.getters.secondary.value
				);

				store.dispatch("primary", store.getters.howler.value.play("RHBR17-0"));
			},
			endDelay: 4000,
		})

		.add({
			targets: ".right-container",
			opacity: [1, 0],
			duration: 2000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
	// cloud history right hand ends


	/** *************************************************************************************************
	 * Learning presentation 2 -.,MN ( GUIONO PUNTO COMMA MUSIC NOVIO NOVIO )                                    *
	 ***************************************************************************************************
	*/

		.add({
			targets: ".learning-presentation",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		// GUION
		// RHBR17-1
		.add({
			targets: ".presentation-6",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

	.add({
		duration: 4000,
		targets: '.hyphen.lesson-img',
		width: '250px',
		height: '250px',
		opacity: [0, 1],
	})
	.add({
		duration: 26500,
		targets: '.hyphen.lesson-img',
		width: '250px',
		height: '250px',
		begin: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR17-1"));
		}
	},"-=3000")
	.add({
		duration: 2000,
		targets: '.hyphen.lesson-img',
		width: '250px',
		height: '250px',
		begin: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR18-19-20"));
		},
	})
	// label
	.add({
		targets: '.hyphen.label h2',
		delay: anime.stagger(1000),
		translateX: [
			{ value: '20px' },
			{ value: '0px', duration: 1000 },
		],
		opacity: [{ value: '0' }, { value: '1', duration: 1000 }],
	})
	.add({
		duration: 2000,
		targets: '.presentation-6',
		opacity: [1, 0],
		complete: ({ animatables }) => {
			animatables[0].target.classList.add("none");
		},
	})
	// animation hyphen ends here



		// punto L-punto-L
	// RHBR21

		.add({
			targets: ".presentation-7",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

		.add({
			duration: 4000,
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR21"));
			},
			targets: '.punto.lesson-img',
			width: '250px',
			height: '250px',
			opacity: [0, 1],
		})
		.add({ duration: 23000 })
		.add({
			targets: '.punto.lesson-img',
			width: '250px',
			height: '250px',
			begin: () => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR22-23-24"));
			},
			endDelay: 2000,
		})
		// label
		.add({
			targets: '.punto.label h2',
			delay: anime.stagger(1000),
			translateX: ['20px', '0px'],
			opacity: [0, 1],
		})
		
		.add({
			duration: 2000,
			opacity: [1, 0],
			targets: [".presentation-7"],
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		// animation punto ends here




		// comma K-comma-K
		.add({
			targets: ".presentation-8",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})




	// RBR25
	.add({
		duration: 4000,
		complete: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR25"));
		},
		targets: '.comma.lesson-img',
		width: '250px',
		height: '250px',
		opacity: [0, 1],
	})
	.add({ duration: 20000 })
	.add({
		targets: '.comma.lesson-img',
		width: '250px',
		height: '250px',
		begin: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR26-27-28"));

		},
		endDelay: 2000,
	})
	// label
	.add({
		targets: '.comma.label h2',
		delay: anime.stagger(1000),
		translateX: ['20px', '0px'],
		opacity: [0, 1],
	})
	.add({
		duration: 2000,
		opacity: [1, 0],
		targets: [".presentation-8"],
		complete: ({ animatables }) => {
			animatables[0].target.classList.add("none");
		},
	})
	// animation comma ends here





	// Music M
	// RHBR29
		.add({
			targets: ".presentation-9",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})

	.add({
		duration: 4000,
		complete: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR29"));
		},
		targets: '.m.lesson-img',
		width: '250px',
		height: '250px',
		opacity: [0, 1],
	})
	.add({ duration: 13000 })
	.add({
		targets: '.m.lesson-img',
		width: '250px',
		height: '250px',
		begin: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR30-31-32"));

		},
		endDelay: 2000,
	})
	// label
	.add({
		targets: '.m.label h2',
		delay: anime.stagger(1000),
		translateX: ['20px', '0px'],
		opacity: [0, 1],
	})
	
	.add({
		duration: 2000,
		opacity: [1, 0],
		targets: [".presentation-9"],
		complete: ({ animatables }) => {
			animatables[0].target.classList.add("none");
		},
	})
	// animation music m ends here




	// novio novio 
// RHBR33
		.add({
			targets: ".presentation-10",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
	.add({
		duration: 4000,
		complete: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR33"));
		},
		targets: '.n.lesson-img',
		width: '250px',
		height: '250px',
		opacity: [0, 1],
	})
	.add({ duration: 11000 })
	.add({
		targets: '.n.lesson-img',
		width: '250px',
		height: '250px',
		begin: () => {
			store.dispatch("primary", store.getters.howler.value.play("RHBR34-35-36"));
		},
		endDelay: 2000,
	})
	// label
	.add({
		targets: '.n.label h2',
		delay: anime.stagger(1000),
		translateX: ['20px', '0px'],
		opacity: [0, 1],
	})

	
	.add({
		duration: 2000,
		opacity: [1, 0],
		targets: [".presentation-10", ".learning-presentation"],
		complete: ({ animatables }) => {
			animatables[0].target.classList.add("none");
			animatables[1].target.classList.add("none");
		},
	})
	// animation bufanda ends here

	// Hide presentation container
	


	/** *************************************************************************************************
	 * Learning presentation 2 ends here                                                               *
	 ***************************************************************************************************
	*/

	// quick animation of all bottom row keys with letters above keyboard starts here

	// slide up keyboard
	// RHBR37-38-39-40-41
	
		// slide up keyboard
		.add({
			targets: [".animation"],
			translateY: "50px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			begin: ({animatables}) => {
				animatables[0].target.classList.remove("none");
			},
		})
				
		// slide up keyboard
		.add({
			targets: [".keyboard"],
			translateY: "0px",
			easing: "easeInOutSine",
			opacity: [0, 1],
			duration: 3000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		})
		.add({
			targets: [".animation .key-text-right"],
			easing: "easeInOutSine",
			translateX: "4em",
			opacity: [0, 1],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
		}, "-=3000")
		.add({
			begin: ()=>{
				store.dispatch(
					"primary",
					store.getters.howler.value.play("RHBR37-38-39-40-41")
				);
			}
		})




		.add({
			targets: [".keyboard", ".animation"],
			opacity: 1,
			delay: 3600,
		})


		.add({
			targets: [
				'[data-char="-"]',
			],
			backgroundColor: [
				{ value: lightRedColor, duration: 500 },
				{ value: redColor, duration: 500, delay: 500 },
			],
			top: [
				{ value: '0.2em', duration: 500 },
				{ value: '0em', duration: 500, delay: 500 },
			]
		})
	
		.add({
			targets: '.key-text-right h2.text-hyphen',
			opacity: 1,
			translateX: [10, 0],
			duration: 200,
		}, "-=1500")
	
		.add({
			delay: 3000,
			targets: [
				'[data-char="."]',
			],
			backgroundColor: [
				{ value: lightBlueColor, duration: 500 },
				{ value: blueColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: '0.2em', duration: 500 },
				{ value: '0em', duration: 500, delay: 1000 },
			]
		})
	
		.add({
			targets: '.key-text-right h2.text-punto',
			opacity: 1,
			translateX: [10, 0],
			duration: 200,
		}, "-=1500")
	
		.add({
			delay: 2500,
			targets: [
				'[data-char=","]',
			],
			backgroundColor: [
				{ value: lightYellowColor, duration: 500 },
				{ value: yellowColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: '0.2em', duration: 500 },
				{ value: '0em', duration: 500, delay: 1000 },
			]
		})
	
		.add({
			targets: '.key-text-right h2.text-comma',
			opacity: 1,
			translateX: [10, 0],
			duration: 200,
		}, "-=1500")
	
		.add({
			delay: 2500,
			targets: [
				'[data-char="M"]',
			],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: '0.2em', duration: 500 },
				{ value: '0em', duration: 500, delay: 1000 },
			],	
		})
	
		.add({
			targets: '.key-text-right h2.text-m',
			opacity: 1,
			translateX: [10, 0],
			duration: 200,
		}, "-=1500")
	
	
		.add({
			delay: 3500,
			targets: [
				'[data-char="N"]',
			],
			backgroundColor: [
				{ value: lightGreenColor, duration: 500 },
				{ value: greenColor, duration: 500, delay: 1000 },
			],
			top: [
				{ value: '0.2em', duration: 500 },
				{ value: '0em', duration: 500, delay: 1000 },
			],
		})
	
		.add({
			targets: '.key-text-right h2.text-n',
			opacity: 1,
			translateX: [10, 0],
			duration: 200,
		}, "-=1500")
	
		.add({
			delay: 1000,
			duration: 11000,
			begin: (anim) => {
				store.dispatch("primary", store.getters.howler.value.play("RHBR43"));
			},
		})
		
		.add({
			delay: 1000,
			targets: '.key-text-right h2',
			opacity: [1, 0],
			duration: 1000,
		})




		// hide animation
		.add({
			targets: [".animation", ".animation .key-text-right"],
			opacity: [1, 0],
			translateY: "500px",
			easing: "easeInOutSine",
			duration: 3000,
			complete: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
			},
			endDelay: 1000,
		})

		// ok thumbsup
		.add({
			targets: ".right-like",
			opacity: 1,
			duration: 3000,
			translateX: ["20%", "0%"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				store.getters.howler.value.fade(
					0.3,
					0,
					5000,
					store.getters.secondary.value
				);
			},
		})

		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('three_learn_right');
			},
		});

	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/bottom_row/learn/right.mp3")],
			sprite: {
				"active": [
				  0,
				  252120,
				  true
				],
				"passive": [
				  254000,
				  548571.4285714285,
				  true
				],
				"RHBR1": [
				  804000,
				  6046.938775510171
				],
				"RHBR10": [
				  812000,
				  1538.5714285714585
				],
				"RHBR11": [
				  815000,
				  11521.405895691601
				],
				"RHBR13": [
				  828000,
				  1699.5918367347258
				],
				"RHBR14": [
				  831000,
				  13238.866213151938
				],
				"RHBR16": [
				  846000,
				  3864.331065759643
				],
				"RHBR17-0": [
				  851000,
				  4579.002267573742
				],
				"RHBR17-1": [
				  857000,
				  26012.60770975057
				],
				"RHBR18-19-20": [
				  885000,
				  6780.453514739179
				],
				"RHBR2": [
				  893000,
				  10036.507936507973
				],
				"RHBR21": [
				  905000,
				  21951.47392290255
				],
				"RHBR22-23-24": [
				  928000,
				  6601.541950113415
				],
				"RHBR25": [
				  936000,
				  17353.650793650784
				],
				"RHBR26-27-28": [
				  955000,
				  6261.63265306127
				],
				"RHBR29": [
				  963000,
				  12576.938775510258
				],
				"RHBR30-31-32": [
				  977000,
				  6708.8888888888505
				],
				"RHBR33": [
				  985000,
				  10286.96145124718
				],
				"RHBR34-35-36": [
				  997000,
				  6476.303854875255
				],
				"RHBR37-38-39-40-41": [
				  1005000,
				  26245.147392290164
				],
				"RHBR4": [
				  1033000,
				  2951.9274376416433
				],
				"RHBR43": [
				  1037000,
				  10608.979591836714
				],
				"RHBR5": [
				  1049000,
				  8480.022675737018
				],
				"RHBR7": [
				  1059000,
				  3345.51020408162
				],
				"RHBR8": [
				  1064000,
				  21647.3469387754
				]
			  }
			,

			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
