<template>
	<main style="margin: 3em">
		<div class="row my-3">
			<div class="col-8 offset-2">
				<h2
					class="d-flex justify-content-center flex-column align-items-start"
				>
					<span>Política de privacidad</span>
					<span style="font-size: 14px"
						>ÚLTIMA ACTUALIZACIÓN: 5 de junio de 2020</span
					>
				</h2>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-8 offset-2">
				<p>
					EDconsulting ("EDconsulting", "nosotros" o "nuestro") se
					compromete a proteger la privacidad y confidencialidad de la
					información personal que podamos recoger. EDconsulting ha
					creado esta política de privacidad ("Política de
					Privacidad") para aconsejarle sobre nuestras prácticas de
					información, tales como los tipos de información que
					recolectamos y cómo podemos usar esa información, y para
					informarle sobre sus derechos de privacidad y cómo la ley lo
					protege. Le instamos a que lea atentamente esta Política de
					privacidad para comprender nuestras políticas y prácticas en
					relación con su información personal y cómo la trataremos.
					Al visitar cualquier parte de nuestros Sitios Web (como se
					define a continuación) o al proporcionar a EDconsulting
					Información Personal por cualquier medio, usted acepta y
					está de acuerdo con las prácticas descritas en esta Política
					de Privacidad y su uso continuo de los Sitios Web (después
					de la publicación de una política de privacidad revisada)
					significa que acepta y está de acuerdo con los términos de
					la política de privacidad revisada, así que por favor revise
					la política periódicamente para ver las actualizaciones.
				</p>
				<p>
					Las personas de la Unión Europea ("UE") deben asegurarse de
					leer la importante información que se proporciona a
					continuación, además de las demás disposiciones de esta
					Política de Privacidad.
				</p>
				<ol type="i">
					<li>INFORMACIÓN IMPORTANTE Y QUIÉNES SOMOS</li>
					<li>LA INFORMACIÓN QUE RECOGEMOS SOBRE USTED</li>
					<li>¿CÓMO SE RECOGE SU INFORMACIÓN PERSONAL</li>
					<li>INTERACCIONES CON TERCEROS</li>
					<li>
						PROVEEDORES DE ANÁLISIS Y SERVIDORES DE PUBLICIDAD DE
						TERCEROS; SEGUIMIENTO EN LÍNEA
					</li>
					<li>CÓMO USAMOS SU INFORMACIÓN PERSONAL</li>
					<li>DIVULGACIÓN DE SU INFORMACIÓN PERSONAL</li>
					<li>SUS ELECCIONES</li>
					<li>SEGURIDAD DE LOS DATOS</li>
					<li>
						USUARIOS FUERA DE ESPAÑA Y TRANSFERENCIAS
						INTERNACIONALES
					</li>
					<li>PREGUNTAS</li>
					<li>
						INFORMACIÓN ADICIONAL PARA LOS RESIDENTES DE LA UNIÓN
						EUROPEA
					</li>
				</ol>
				<div class="section">
					<div class="heading">
						I. INFORMACIÓN IMPORTANTE Y QUIÉNES SOMOS
					</div>
					<div class="sub-heading">
						A. APLICACIÓN DE ESTA POLÍTICA DE PRIVACIDAD
					</div>
					<p>
						Esta Política de Privacidad se aplica a
						www.edconsulting.biz y a todos los demás sitios web,
						características o servicios en línea que son propiedad o
						están controlados por EDconsulting y que publican un
						enlace a esta Política de Privacidad (colectivamente,
						los "Sitios Web"), ya sea que se acceda a través de una
						computadora, un dispositivo móvil o de otra manera.
						Tenga en cuenta, sin embargo, que esta Política de
						Privacidad no se aplica al uso de los sitios web no
						afiliados que tienen un enlace a nuestros sitios web.
						Una vez que entre en otro sitio web (ya sea a través de
						un anuncio, servicio o enlace de contenido), tenga en
						cuenta que no somos responsables de las prácticas de
						privacidad de esos otros sitios web.
					</p>

					<div class="sub-heading">
						B. PROPÓSITO DE ESTA POLÍTICA DE PRIVACIDAD
					</div>
					<p>
						Esta Política de Privacidad tiene como objetivo darle
						información sobre cómo EDconsulting recoge y utiliza su
						Información Personal a través de su uso de los Sitios
						Web, incluyendo cualquier información que usted pueda
						proporcionar a través de los Sitios Web cuando se
						registre en una cuenta, se inscriba para recibir
						información o comunicaciones de nosotros, compre un
						producto o servicio, o solicite más servicios o
						información de nosotros. Es importante que lea esta
						Política de Privacidad junto con cualquier otro aviso de
						privacidad o aviso de procesamiento justo que podamos
						proporcionar en ocasiones específicas cuando estemos
						recopilando o usando Información Personal sobre usted
						para que sea plenamente consciente de cómo y por qué
						estamos usando su información. Esta Política de
						Privacidad complementa los demás avisos y no pretende
						anularlos.
					</p>

					<div class="sub-heading">
						C. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD Y SU DEBER DE
						INFORMARNOS DE LOS CAMBIOS
					</div>
					<p>
						Esta versión fue actualizada por última vez el 5 de
						junio de 2020 y las versiones históricas pueden
						obtenerse contactando con nosotros. Nos reservamos el
						derecho, en cualquier momento y sin previo aviso, de
						añadir, cambiar, actualizar o modificar esta Política de
						Privacidad, simplemente publicando dicho cambio,
						actualización o modificación en los Sitios Web.
						Cualquier cambio, actualización o modificación será
						efectivo inmediatamente después de su publicación en los
						Sitios web. Sin embargo, a menos que usted consienta,
						EDconsulting no utilizará su Información Personal de una
						manera materialmente diferente a la que se estableció en
						nuestra Política de Privacidad publicada en el momento
						en que su Información Personal fue recolectada. Por
						favor revise esta Política de Privacidad regularmente
						para asegurarse de que está al tanto de cualquier cambio
						en nuestras prácticas. Es importante que la información
						personal que tenemos sobre usted sea precisa y actual.
						Por favor, manténganos informados si su información
						personal cambia durante su relación con nosotros. Usted
						es responsable de mantener la exactitud de la
						información que nos envía, como su información de
						contacto. Si se pone en contacto con nosotros con
						cambios, haremos esfuerzos de buena fe para hacer los
						cambios solicitados en nuestras bases de datos activas
						en ese momento tan pronto como sea razonablemente
						posible. Tenga en cuenta, sin embargo, que la
						información puede persistir internamente para nuestros
						propósitos administrativos y que los datos residuales
						pueden permanecer en medios de respaldo o por otras
						razones.
					</p>

					<div class="sub-heading">
						D. CONTENIDO DE TERCEROS, ENLACES A OTROS SITIOS WEB Y
						CONTENIDO DE EDCONSULTAS QUE SE ENCUENTRAN FUERA DE LOS
						SITIOS WEB
					</div>
					<p>
						Los sitios web pueden incluir enlaces a sitios web,
						complementos y aplicaciones de terceros, y cierto
						contenido de los sitios web puede estar alojado y
						servido por terceros que EDconsulting no controla.
						Cuando usted hace clic en un enlace a cualquier otro
						sitio web o ubicación, usted dejará nuestros Sitios Web
						e irá a otro sitio y otra entidad puede recopilar su
						Información Personal. No tenemos control sobre, no
						revisamos y no podemos ser responsables de estos sitios
						web externos o su contenido. Tenga en cuenta que los
						términos de esta Política de Privacidad no se aplican a
						estos sitios web externos, a sus declaraciones de
						privacidad o a cualquier recopilación de su Información
						Personal después de hacer clic en los enlaces a dichos
						sitios web externos. Le recomendamos que lea las
						políticas de privacidad de cada sitio web que visite.
						Los enlaces a sitios web o lugares de terceros son para
						su conveniencia y no significan nuestro respaldo a
						dichos terceros o a sus productos, contenidos o sitios
						web. Además, el contenido de EDconsulting puede ser
						incluido en páginas web y sitios web que no están
						asociados con EDconsulting y sobre los cuales no tenemos
						ningún control. Estos terceros pueden recoger datos a
						través del uso de sus propias cookies, etiquetas de
						píxeles u otra tecnología, recoger información de forma
						independiente o solicitar Información Personal, y pueden
						tener la capacidad de rastrear el uso que usted hace de
						sus sitios web y servicios. EDconsulting no se hace
						responsable de las prácticas de privacidad o el
						contenido de cualquier tercero.
					</p>
				</div>

				<div class="section">
					<div class="heading">
						II. LA INFORMACIÓN QUE RECOGEMOS SOBRE USTED
					</div>
					<p>
						Por información personal o datos personales se entiende
						cualquier información sobre un individuo que permita
						identificarlo. No incluye la información en la que se ha
						eliminado la identidad (datos anónimos). Nosotros y
						nuestros proveedores de servicios externos podemos
						recopilar, utilizar, almacenar y transferir diferentes
						tipos de información personal sobre usted que hemos
						agrupado de la siguiente manera:
					</p>
					<ul>
						<li>
							INFORMACIÓN DE IDENTIDAD incluye nombre, nombre de
							usuario o identificador similar, título, fecha de
							nacimiento y género.
						</li>
						<li>
							INFORMACIÓN DEMOGRÁFICA incluye código postal, edad
							y/o ingresos.
						</li>
						<li>
							INFORMACIÓN DE CONTACTO incluye dirección de
							facturación, dirección de entrega, dirección de
							correo electrónico y números de teléfono.
						</li>
						<li>
							INFORMACIÓN FINANCIERA incluye datos de cuentas
							bancarias y tarjetas de pago (aplicables si se ha
							realizado algún pago).
						</li>
						<li>
							INFORMACIÓN DE TRANSACCIONES incluye detalles sobre
							los pagos a y desde usted y otros detalles de los
							servicios que nos ha comprado (aplicable si se ha
							realizado algún pago).
						</li>
						<li>
							INFORMACIÓN TÉCNICA incluye la dirección del
							protocolo de Internet (IP), sus datos de inicio de
							sesión, el tipo y la versión del navegador, la
							configuración de la zona horaria y la ubicación
							geográfica, los tipos y las versiones de los
							complementos del navegador, el sistema operativo y
							la plataforma y otra tecnología u otro identificador
							único (un conjunto de números o caracteres que se
							asigna a su computadora, teléfono móvil u otro
							dispositivo cuando está en Internet) ("Identificador
							del dispositivo") para cualquier computadora,
							teléfono móvil, tableta u otro dispositivo
							(cualquiera de los cuales se denomina en el presente
							documento "Dispositivo") utilizado para acceder a
							los Sitios web.
						</li>
						<li>
							INFORMACIÓN DE PERFIL incluye su nombre de usuario y
							contraseña, compras o pedidos realizados por usted
							(aplicable si se ha realizado algún pedido), sus
							intereses, preferencias, comentarios sobre productos
							y respuestas a encuestas.
						</li>
						<li>
							DATOS DE USO incluye información sobre cómo utiliza
							nuestros sitios web, productos y servicios,
							incluyendo todas las áreas de nuestros sitios web
							que visita o utiliza y la hora del día en que visitó
							los sitios web, entre otra información.
						</li>
						<li>
							INFORMACIÓN DE COMERCIALIZACIÓN Y COMUNICACIONES
							incluye sus preferencias en la recepción de
							comercialización de nosotros y nuestros terceros y
							sus preferencias de comunicación.
						</li>
						<li>
							INFORMACIÓN SOBRE LA UBICACIÓN incluye información
							sobre su ubicación utilizando una variedad de
							tecnologías, como GPS, dirección IP y redes Wi-Fi
							conectadas o cercanas.
						</li>
						<li>
							INFORMACIÓN DE CONTENIDO DE USUARIO incluye texto
							(incluyendo preguntas, comentarios y sugerencias),
							imágenes, audio, videos u otro contenido
							(colectivamente, "Contenido de Usuario") que usted
							comparte al participar y publicar contenido
							públicamente en reseñas, características
							interactivas u otra funcionalidad de comunicación
							("Características de la Comunidad").
						</li>
					</ul>

					<p>
						También recogemos, usamos y compartimos INFORMACIÓN
						AGREGADA como información estadística o demográfica para
						cualquier propósito. La Información Agregada puede
						derivarse de su Información Personal pero no se
						considera Información Personal en la ley ya que esta
						información no revela directa o indirectamente su
						identidad. Por ejemplo, podemos agregar sus Datos de Uso
						para calcular el porcentaje de usuarios que acceden a
						una función específica del sitio web. Sin embargo, si
						combinamos o conectamos la Información Agregada con su
						Información Personal para que pueda identificarlo
						directa o indirectamente, tratamos la información
						combinada como Información Personal que será utilizada
						de acuerdo con esta Política de Privacidad. No recogemos
						ninguna CATEGORÍA ESPECIAL de Información Personal sobre
						usted (esto incluye detalles sobre su raza o etnia,
						creencias religiosas o filosóficas, vida sexual,
						orientación sexual, opiniones políticas, afiliación
						sindical, información sobre su salud y datos genéticos y
						biométricos). Tampoco recopilamos información sobre
						condenas y delitos penales.
					</p>
				</div>

				<div class="section">
					<div class="heading">
						III. CÓMO RECOGEMOS SU INFORMACIÓN PERSONAL
					</div>
					<p>
						Utilizamos diferentes métodos para recopilar información
						de y sobre usted, incluyendo a través de:
					</p>
					<ul>
						<li>
							INTERACCIONES DIRECTAS. Puede darnos su información
							de identidad, demográfica, de contacto, financiera,
							de perfil, o de marketing y comunicaciones
							rellenando formularios o escribiéndonos por correo,
							teléfono, correo electrónico o de otra forma. Esto
							incluye la Información Personal que usted provee
							cuando usted:
						</li>
						<li>comprar nuestros productos o servicios;</li>
						<li>crear una cuenta en nuestros sitios web;</li>
						<li>
							se suscriben a correos electrónicos o boletines de
							noticias;
						</li>
						<li>solicitar servicios u otra información;</li>
						<li>
							participar en un concurso, promoción o encuesta; o
						</li>
						<li>
							nos da información sobre productos, servicios o los
							sitios web.
						</li>
						<li>
							TECNOLOGÍAS O INTERACCIONES AUTOMATIZADAS. A medida
							que navega por los Sitios Web, podemos recopilar
							automáticamente información técnica, de uso y de
							ubicación sobre su equipo, acciones y patrones de
							navegación. Recopilamos esta Información Personal
							mediante el uso de cookies, etiquetas de píxeles,
							scripts incrustados y otras tecnologías similares.
							También podemos recibir Información Técnica sobre
							usted si visita otros sitios web que emplean
							nuestras cookies].
						</li>
						<li>
							COOKIES - Las cookies son pequeños archivos de datos
							que un sitio web envía a su Equipo mientras usted
							está viendo el sitio web que se almacenan en el
							Equipo. Las cookies pueden usarse para muchos
							propósitos, incluyendo el monitoreo del uso de los
							sitios web, para personalizar el contenido
							específico de sus intereses, para asegurar que no
							vea el mismo anuncio repetidamente, para acelerar
							sus búsquedas y compras y para reconocerlo cuando
							regrese a nuestros Sitios web y/o almacenar su
							nombre de usuario y contraseña para que no tenga que
							volver a ingresarlo cada vez que visite los Sitios
							web. Puede negarse a aceptar las cookies activando
							la configuración adecuada en su navegador. Sin
							embargo, si decide deshabilitar las cookies en su
							Equipo, es posible que algunas características de
							los Sitios web o de nuestro contenido no funcionen
							correctamente.
						</li>
						<li>
							PIXEL TAG - Las Pixel Tags (también conocidas como
							GIFs transparentes, GIFs 1x1 Web beacons, o Web
							bugs) son pequeñas imágenes gráficas u otro código
							de programación web que pueden ser incluidas en los
							sitios web y en nuestros mensajes de correo
							electrónico. A diferencia de las Cookies, que se
							almacenan en el Dispositivo de un usuario, las Pixel
							Tags se incrustan de forma invisible en las páginas
							web. Las Pixel Tags pueden ser invisibles para
							usted, pero cualquier imagen electrónica u otro
							código de programación web insertado en una página
							web o en un correo electrónico puede actuar como una
							pixel tag. Las etiquetas de píxeles o tecnologías
							similares pueden utilizarse con diversos fines,
							entre ellos, sin limitación, contar los visitantes
							de los sitios web, vigilar la forma en que los
							usuarios navegan por los sitios web, contar cuántos
							correos electrónicos enviados se abrieron realmente
							o contar cuántos artículos o enlaces particulares se
							vieron realmente.
						</li>
						<li>
							GRAFICOS EMBEBIDOS - Los GRAFICOS EMBEBIDOS son
							códigos de programación diseñados para recoger
							información sobre sus interacciones con los sitios
							web, como los enlaces en los que hace clic. El
							código se descarga temporalmente en su Dispositivo
							desde nuestro servidor web o de un proveedor de
							servicios de terceros, y sólo está activo mientras
							está conectado a los Sitios web.
						</li>
						<li>
							FLASH LSOS - Cuando publicamos vídeos, los terceros
							pueden utilizar objetos compartidos locales,
							conocidos como "flash cookies", para almacenar sus
							preferencias en cuanto al control de volumen o para
							personalizar determinadas funciones de vídeo. Las
							"Flash Cookies" se diferencian de las "Cookies" de
							los navegadores por la cantidad y el tipo de datos y
							por la forma en que se almacenan los datos. Las
							herramientas de gestión de cookies que proporciona
							su navegador no eliminan las cookies flash. Para
							obtener información sobre cómo administrar la
							privacidad y los ajustes de almacenamiento de las
							cookies de Flash, visite LP/SETTINGS_MANAGER07.HTML.
						</li>
						<li>
							CARACTERÍSTICAS DE LA COMUNIDAD. Los Sitios web
							pueden brindarle la oportunidad de participar y
							publicar Contenido de Usuario públicamente a través
							de revisiones, encuestas, características
							interactivas u otras funciones de comunicación
							("Características de la comunidad"). Podemos
							proporcionarle la funcionalidad para que envíe o
							publique Contenido de Usuario a través de
							Características Comunitarias, vinculando los Sitios
							Web a un servicio de terceros o de otra manera.
							Tenga en cuenta que cierta información, como su
							nombre, puede mostrarse públicamente en los Sitios
							web junto con su Contenido de usuario. El Contenido
							de Usuario que consiste en archivos cargados puede
							contener metadatos, que pueden contener Información
							Personal u otra información sobre o relacionada con
							usted. Usted puede desear eliminar los metadatos de
							sus archivos antes de transmitirlos a EDconsulting .
							TENGA EN CUENTA QUE TODO LO QUE PUBLIQUE A TRAVÉS DE
							LAS CARACTERÍSTICAS DE LA COMUNIDAD ES PÚBLICO -
							OTROS TENDRÁN ACCESO A SU CONTENIDO DE USUARIO Y
							PUEDEN UTILIZARLO O COMPARTIRLO CON TERCEROS. SI
							USTED DECIDE REVELAR VOLUNTARIAMENTE INFORMACIÓN
							PERSONAL, ESA INFORMACIÓN SERÁ CONSIDERADA
							INFORMACIÓN PÚBLICA Y LAS PROTECCIONES DE ESTA
							POLÍTICA DE PRIVACIDAD NO SE APLICARÁN. SI USTED
							ENVÍA CONTENIDO DE USUARIO, ÉSTE PODRÁ SER PUBLICADO
							EN LÍNEA O FUERA DE LÍNEA EN CUALQUIER MOMENTO.
							ADEMÁS, PODEMOS UTILIZAR SU CONTENIDO DE USUARIO Y
							CUALQUIER EXTRACTO DE SU CONTENIDO DE USUARIO EN
							RELACIÓN CON NUESTRAS ACTIVIDADES DE PUBLICIDAD,
							MARKETING, PUBLICIDAD Y PROMOCIÓN.
						</li>
						<li>
							TERCEROS O FUENTES DE ACCESO PÚBLICO. Podemos
							recibir información personal sobre usted de varios
							terceros. Por ejemplo, si usted está en otro sitio
							web y opta por recibir información de nosotros, el
							otro sitio web nos enviará su Información de
							Contacto y otra información para que podamos
							contactarlo como lo solicitó. También podemos
							complementar la información que recopilamos con
							registros externos de terceros para proporcionarle
							la información, los servicios o los bienes que haya
							solicitado, para mejorar nuestra capacidad de
							servirle y para adaptar nuestro contenido a usted.
							Podemos combinar la información que recibimos de
							esas otras fuentes con la información que
							recopilamos a través de los Sitios Web. En esos
							casos, aplicaremos esta Política de privacidad a la
							información combinada. Entre los ejemplos de fuentes
							de terceros se incluyen:
						</li>
						<li>
							Información técnica de las siguientes partes ○
							proveedores de análisis; ○ redes de publicidad; y ○
							información de búsqueda.
						</li>
						<li>
							Información de contacto, financiera y de
							transacciones de los proveedores de servicios
							técnicos, de pago y de entrega.
						</li>
						<li>
							Información de identidad y contacto de fuentes
							públicas.
						</li>
						<li>
							INFORMACIÓN PROPORCIONADA POR OTROS USUARIOS. Si
							decide invitar a un tercero a crear una cuenta y/o
							comprar nuestra mercancía, recopilaremos la
							información de identidad y contacto que nos
							proporcione (por ejemplo, nombre y dirección de
							correo electrónico) para usted y el tercero con el
							fin de enviarle un mensaje y hacer un seguimiento
							con el tercero. Confiamos en que usted obtenga los
							consentimientos del tercero que puedan ser
							requeridos por la ley para permitirnos recopilar
							dicha información y contactar con el tercero como se
							describe en la frase anterior. Usted o el tercero
							pueden contactarnos en info@edconsulting.biz para
							solicitar la eliminación de esta información de
							nuestra base de datos.
						</li>
					</ul>
				</div>

				<div class="section">
					<div class="heading">IV. INTERACCIONES CON TERCEROS</div>
					<p>
						Ciertas funcionalidades de los Sitios Web pueden
						permitir interacciones que usted inicie entre los Sitios
						Web y un sitio o servicio de terceros ("INTERACCIONES DE
						TERCEROS"). Ejemplos de Interacciones con Terceros
						pueden incluir tecnología que le permite "gustar" o
						"compartir" contenido de los Sitios Web en o a otros
						sitios web o servicios; transmitir contenido a los
						Sitios Web desde su cuenta en un sitio web o servicio de
						un tercero; conectar de otra manera los Sitios Web a un
						sitio web o servicio de un tercero, como a través de una
						interfaz de programación de aplicaciones (API) puesta a
						disposición por EDconsulting o un tercero; o para que
						los usuarios se registren para obtener una cuenta e
						inicien sesión a través de sitios de redes sociales como
						Facebook y Google (cada uno un "SNS"). Al registrarse o
						iniciar sesión a través de un SNS, usted está
						permitiendo que los sitios web accedan a su información
						y está aceptando los Términos de Uso y la Política de
						Privacidad del SNS en el uso de dichos servicios. Es
						posible que recibamos información de SNS para
						facilitarte la creación de una cuenta con nosotros.
						Cualquier información que recopilemos de tu cuenta SNS
						puede depender de la configuración de privacidad que
						tengas con ese SNS, por lo que te recomendamos que
						consultes las prácticas de privacidad y datos de SNS.
					</p>
					<p>
						Si decide utilizar las Interacciones con terceros, la
						información que publique o a la que proporcione acceso
						puede mostrarse públicamente en los Sitios web o por el
						proveedor de la Función social que utilice. Del mismo
						modo, si publica información en una plataforma de
						terceros que hace referencia a los Sitios web (por
						ejemplo, mencionando uno de los Sitios web o usando una
						etiqueta asociada a uno de los Sitios web en un tweet o
						una actualización de estado), su publicación puede ser
						publicada en nuestros Sitios web de acuerdo con los
						términos del sitio web o servicio de terceros. Además,
						tanto EDconsulting como el tercero pueden tener acceso a
						cierta información sobre usted y su uso de los Sitios
						Web y el sitio web o servicio del tercero. Además,
						podemos recibir información sobre usted en relación con
						el uso de otros usuarios de las interacciones de
						terceros (por ejemplo, podemos saber que usted es un
						"amigo" o "conexión" del tercero o recibir otra
						información sobre usted que el otro usuario nos permite
						recibir). La información que recopilamos en relación con
						las interacciones con terceros está sujeta a esta
						Política de privacidad.
					</p>
					<p>
						La información recogida y almacenada por el tercero
						sigue estando sujeta a las prácticas de privacidad del
						tercero, incluyendo si el tercero sigue compartiendo
						información con nosotros, los tipos de información
						compartida y sus opciones con respecto a lo que es
						visible para los demás en el sitio web o servicio de ese
						tercero.
					</p>
				</div>

				<div class="section">
					<div class="heading">
						V. PROVEEDORES DE ANÁLISIS Y SERVIDORES DE PUBLICIDAD DE
						TERCEROS; SEGUIMIENTO EN LÍNEA
					</div>
					<p>
						EDconsulting trabaja con ciertos terceros (incluyendo
						anunciantes de la red, agencias de publicidad y
						compañías de análisis) para proporcionarnos información
						sobre el tráfico en los sitios web, para servir
						anuncios, incluyendo nuestros anuncios en otros lugares
						en línea, y para proporcionarnos información sobre el
						uso de los sitios web y la eficacia de nuestros
						anuncios.
					</p>
					<p>
						Estos terceros pueden utilizar el Identificador de
						Dispositivos y Datos de Uso para compilar informes sobre
						la actividad de los usuarios. Por ejemplo, si hizo clic
						en uno de nuestros anuncios que lo llevó a uno de los
						sitios web, nuestro(s) proveedor(es) de servicios
						puede(n) decirnos en qué anuncio hizo clic y dónde
						estaba viendo el anuncio. Estos terceros pueden
						establecer y acceder a sus propias tecnologías de
						rastreo (incluyendo cookies, scripts incrustados y
						etiquetas de píxeles) y pueden de otra manera recopilar
						o tener acceso a su Identificador de Dispositivo, Datos
						de Uso e información relacionada con usted. Las cookies
						y las etiquetas de píxeles, incluidas las establecidas
						por los anunciantes de la red de terceros, pueden
						utilizarse, entre otras cosas, para dirigir anuncios,
						evitar que usted vea los mismos anuncios demasiadas
						veces, realizar investigaciones sobre la utilidad de
						determinados anuncios para usted y ayudar a proporcionar
						análisis. Estos terceros también pueden transferir el
						Identificador de Dispositivos y los Datos de Uso a otros
						terceros cuando la ley así lo requiera, o cuando dichos
						terceros procesen la información analítica en su nombre.
						La capacidad de cada uno de estos terceros de utilizar y
						compartir el Identificador de dispositivos y los Datos
						de uso está restringida por sus respectivos Términos de
						uso y Política de privacidad. Al utilizar nuestros
						Sitios web, usted da su consentimiento para que estos
						terceros procesen sus datos de la manera y para los
						fines establecidos anteriormente. Para obtener una lista
						completa de los servicios analíticos de terceros,
						póngase en contacto con nosotros en
						info@edconsulting.biz.
					</p>
					<p>
						Podemos compartir el Identificador de Dispositivos y los
						Datos de Uso sobre los visitantes con compañías de
						publicidad de terceros, proveedores de análisis y otros
						proveedores con fines similares. Si bien podemos
						utilizar una variedad de proveedores de servicios para
						realizar servicios de publicidad y análisis, algunas de
						estas empresas pueden ser miembros de la Iniciativa de
						Publicidad en la Red ("NAI") o del Programa de
						Autorregulación de la Alianza de Publicidad Digital
						("DAA") para la publicidad conductual en línea.
					</p>
					<p>
						Para obtener información adicional sobre la publicidad
						dirigida y los procedimientos de "exclusión" de los
						miembros de la NAI y las empresas participantes en el
						Programa de Autorregulación de la DAA, puede visitar:
					</p>
					<p>
						<u>
							<li>
								Herramienta de exclusión del NAI (para usuarios del sitio web): <a href="http://www.networkadvertising.org/managing/opt_out.asp">link</a><br>
							</li>
							<li>
								DAA Consumer Choice (para usuarios del sitio web): <a href="http://www.aboutads.info/choices/">link</a> 
							</li>
							<li>
								DAA AppChoices (para usuarios de aplicaciones móviles): <a href="http://youradchoices.com/appchoices">link</a>
							</li>
						</u><br>
						Tenga en cuenta que la exclusión a través de estos
						mecanismos no le impide recibir publicidad. Seguirá
						recibiendo anuncios genéricos mientras visita los sitios
						web y otros lugares en línea. Algunos terceros pueden
						recopilar Información Personal sobre sus actividades en
						línea a lo largo del tiempo y a través de diferentes
						sitios web.
					</p>
					<p>
						La configuración de su navegador puede permitirle
						transmitir automáticamente una señal de "No rastrear" a
						los sitios web y servicios en línea que visite. Sin
						embargo, no hay consenso entre los participantes de la
						industria en cuanto a lo que significa "No rastrear" en
						este contexto. Al igual que muchos sitios web y
						servicios en línea, los sitios web actualmente no
						responden o alteran sus prácticas cuando reciben una
						señal de "No rastrear" del navegador de un visitante.
						Para obtener más información sobre "No rastrear", tal
						vez desee visitar <a href="http://www.allaboutdnt.com">link</a>
					</p>
				</div>
				<div class="section">
					<div class="heading">
						VI. CÓMO USAMOS SU INFORMACIÓN PERSONAL
					</div>
					<p>
						Podemos utilizar la información que recogemos sobre
						usted, incluyendo la información personal y los datos de
						uso:
					</p>
					<ul>
						<li>
							para proporcionarle nuestros productos y servicios y
							el servicio de atención al cliente correspondiente;
						</li>
						<li>
							para procesar su registro y la creación de su cuenta
							en los Sitios Web, incluyendo la verificación de que
							su información de contacto está activa y es válida;
						</li>
						<li>
							para identificarlo como usuario en nuestro sistema;
						</li>
						<li>
							para proporcionarle información, productos o
							servicios que haya solicitado o aceptado recibir;
						</li>
						<li>
							para proporcionar una mejor administración de
							nuestros sitios web y servicios;
						</li>
						<li>
							(si corresponde) para procesar las transacciones que
							inicie, procesar los pagos y proporcionar la
							facturación y el envío exactos;
						</li>
						<li>
							(si procede) para enviarle notificaciones
							administrativas por correo electrónico, como
							confirmaciones de pedidos, actualizaciones del
							estado de los pedidos, avisos de seguridad o de
							apoyo y mantenimiento;
						</li>
						<li>
							(si es aplicable) para facturarle los productos o
							servicios de EDconsulting;
						</li>
						<li>
							responder a sus preguntas relacionadas con las
							oportunidades de empleo u otras solicitudes;
						</li>
						<li>
							enviar boletines de noticias, encuestas, ofertas y
							otros materiales promocionales relacionados con
							nuestros servicios y para otros fines de marketing
							de EDconsulting;
						</li>
						<li>
							para comercializar nuestros productos/servicios,
							incluyendo la recomendación de productos/servicios
							que puedan ser de su interés;
						</li>
						<li>
							para mejorar nuestros sitios web, productos y
							servicios;
						</li>
						<li>
							para presentar nuestros sitios web y sus contenidos
							de una manera adecuada y efectiva para usted y para
							su computadora;
						</li>
						<li>
							para personalizar y adaptar su experiencia en los
							sitios web, por ejemplo, mostrando el contenido que
							creemos que le puede interesar;
						</li>
						<li>
							para mejorar la calidad de la experiencia cuando
							interactúe con nuestro Sitio y Servicios con fines
							comerciales internos;
						</li>
						<li>
							para la prevención del fraude y la seguridad,
							incluyendo la aplicación de nuestros términos de
							servicio y esta Política de Privacidad, la
							protección de nuestros derechos de privacidad,
							seguridad o propiedad y/o los de usted u otros, y la
							protección, investigación o disuasión de actividades
							fraudulentas, dañinas, no autorizadas, no éticas o
							ilegales;
						</li>
						<li>
							para cumplir con nuestras obligaciones y hacer valer
							nuestros derechos derivados de los contratos
							celebrados entre usted y nosotros;
						</li>
						<li>
							para notificarle los cambios en nuestro servicio;
						</li>
						<li>
							para cumplir con las leyes aplicables, las
							solicitudes legales y el proceso legal, como
							responder a las citaciones o solicitudes de las
							autoridades gubernamentales;
						</li>
						<li>
							para contactarlo con respecto a su uso de los Sitios
							Web y, a nuestra discreción, los cambios en las
							políticas o la funcionalidad de los Sitios Web; y
						</li>
						<li>
							para realizar otras funciones como se describe en el
							momento de la recopilación, con su consentimiento, o
							como se describe con más detalle en esta Política de
							Privacidad. Además de lo anterior, podemos utilizar
							datos anónimos para analizar las solicitudes y los
							patrones de uso, de modo que podamos mejorar el
							contenido de nuestros servicios y mejorar la
							navegación en los sitios web.
						</li>
					</ul>
					<p class="sub-heading">
						A.COMUNICACIONES POR CORREO ELECTRÓNICO
					</p>
					<p>
						Podemos utilizar su información de identidad, contacto,
						técnica, de uso y de perfil para formarnos una opinión
						sobre lo que creemos que usted puede querer o necesitar,
						o lo que puede ser de su interés. Así es como decidimos
						qué productos, servicios y ofertas pueden ser relevantes
						para usted. Recibirá nuestros boletines o comunicaciones
						de marketing si nos ha solicitado información, ha
						adquirido bienes o servicios, o si nos ha proporcionado
						sus datos cuando se ha inscrito en un concurso o se ha
						registrado en una promoción y, en cada caso, ha
						consentido recibir comunicaciones de marketing en ese
						momento.
					</p>
					<p class="sub-heading">B.COOKIES</p>
					<p>
						Una cookie es un archivo de datos que contiene un
						identificador (una cadena de letras y números) que es
						enviado por un servidor web a un navegador web y que
						éste almacena. El identificador se envía de nuevo al
						servidor cada vez que el navegador solicita una página
						del servidor. Las cookies pueden ser "persistentes" o
						"de sesión": una cookie persistente será almacenada por
						un navegador web y será válida hasta la fecha de
						caducidad establecida, a menos que el usuario la borre
						antes de la fecha de caducidad; una cookie de sesión, en
						cambio, caducará al final de la sesión del usuario,
						cuando se cierre el navegador web. Las cookies no suelen
						contener ninguna información que identifique
						personalmente a un usuario, pero la información personal
						que almacenamos sobre usted puede estar vinculada a la
						información almacenada en las cookies y obtenida a
						partir de ellas.
					</p>
					<p>
						Podemos utilizar tanto cookies de sesión como cookies
						persistentes para proporcionarle una experiencia más
						personal e interactiva en nuestro Sitio. Este tipo de
						información se recopila para que los Sitios web le
						resulten más útiles y para adaptar la experiencia con
						nosotros a sus intereses y necesidades especiales.
					</p>
					<p>
						La mayoría de los navegadores le permiten negarse a
						aceptar cookies y a eliminarlas. Los métodos para
						hacerlo varían de un navegador a otro y de una versión a
						otra. Sin embargo, puede obtener información sobre cómo
						bloquear y eliminar las cookies en algunos navegadores
						de uso común a través de los siguientes enlaces:
					</p>
					<ul>
						<li>
							CROMO:
							https://support.google.com/chrome/answer/95647?hl=en;
						</li>
						<li>
							BORDE:
							https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy;
						</li>
						<li>
							FIREFOX:
							https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-websitepreferences;
						</li>
						<li>
							EXPLORADOR DE INTERNET:
							https://support.microsoft.com/en-gb/help/17442/
							windows-internet-explorer-delete-manage-cookies;
						</li>
						<li>
							OPERA:
							http://www.opera.com/help/tutorials/security/cookies/;
							y
						</li>
						<li>SAFARI: https://support.apple.com/kb/PH21411.</li>
					</ul>
					<p>
						Tenga en cuenta que el bloqueo de todas las cookies
						tendrá un impacto negativo en la capacidad de uso de
						muchos sitios web y si decide bloquear las cookies, es
						posible que no pueda utilizar todas las funciones de
						nuestros sitios web.
					</p>
				</div>

				<div class="section">
					<div class="heading">
						VII. DIVULGACIÓN DE SU INFORMACIÓN PERSONAL
					</div>
					<p>
						Podemos compartir información no identificable
						personalmente, como las estadísticas agregadas de
						usuarios, a nuestra discreción y sin restricciones.
						Podemos divulgar la información que hemos recopilado
						sobre usted, incluida la información personal, tal y
						como se reveló en el momento en que usted proporcionó su
						información, con su consentimiento, tal y como se
						describe en esta política de privacidad, o en las
						siguientes circunstancias:
					</p>

					<div class="sub-heading">A. TERCEROS INTERNOS</div>
					<p>
						EDconsulting puede, y se reserva el derecho de,
						compartir su información con cualquier otra compañía que
						no sea actualmente, pero que se convierta en una matriz,
						subsidiaria o afiliada de EDconsulting. Usted puede
						tener la oportunidad de recibir información y/o ofertas
						de mercadeo de EDconsulting y de nuestros negocios
						afiliados, socios y agentes. Si usted acepta en ese
						momento recibir dichas comunicaciones, su Información
						Personal será revelada a ese tercero (o terceros). Para
						más información, por favor refiérase a la sección de
						Comunicaciones de Mercadeo.
					</p>
					<div class="sub-heading">B. TERCEROS EXTERNOS</div>
					<p>Proveedores de servicios de terceros</p>
					<p>
						Podemos compartir información personal con terceros
						proveedores de servicios en relación con la prestación
						de servicios a, o en nombre de, EDconsulting y los
						sitios web, incluyendo para llevar a cabo pruebas de
						garantía de calidad, para facilitar la creación de
						cuentas, para proporcionar apoyo técnico, y / o para
						proporcionar otros servicios a EDconsulting. Usted
						reconoce y acepta que su uso de los Sitios Web puede
						incluir que su Información Personal sea transferida y
						almacenada por nuestra compañía de hospedaje de sitios
						Web de acuerdo con su política de privacidad.
					</p>
					<p>
						También podemos compartir información cuando trabajamos
						con empresas, socios o agentes para desarrollar una
						relación directa con usted o en relación con el Programa
						de Referencia.
					</p>
					<p>
						Estos proveedores de servicios pueden almacenar o
						utilizar su información fuera de la UE o de los Estados
						Unidos.
					</p>
					<p>Razones administrativas y legales</p>
					<p>
						Podemos transferir y revelar información, incluida la
						información personal, a terceros:
					</p>
					<ul>
						<li>
							para cumplir con una investigación legal válida, una
							investigación o un proceso como
						</li>
						una orden de registro, una citación, un estatuto o una
						orden judicial, o si en nuestra opinión dicha
						divulgación es requerida por la ley;
						<li>
							para obtener o mantener una cobertura de seguro,
							gestionar los riesgos, obtener
						</li>
						asesoramiento profesional o establecer, ejercer o
						defender reclamaciones legales, ya sea en procedimientos
						judiciales o en un procedimiento administrativo o
						extrajudicial;
						<li>
							para proteger la seguridad, los intereses, los
							derechos, la propiedad o la seguridad
						</li>
						de EDconsulting, de usted o de cualquier tercero; esto
						puede incluir el intercambio de información con otras
						empresas y organizaciones con el fin de protegerse
						contra el fraude y reducir el riesgo de crédito;
						<li>
							para responder a una violación o intento de
							violación de la seguridad de nuestros
						</li>
						sitios web;
						<li>
							para defender o hacer valer nuestros derechos
							legales en virtud de cualquiera de los
						</li>
						Términos de Uso de los Sitios Web, cualquier política
						aplicable a los Sitios Web, o cualquier otro acuerdo que
						pueda tener con EDconsulting;
						<li>
							a petición de las autoridades gubernamentales que
							llevan a cabo una investigación.
						</li>
					</ul>
					<p>
						También podemos utilizar la Información Técnica para
						identificar a los usuarios, y podemos hacerlo en
						cooperación con los propietarios de los derechos de
						autor, los proveedores de servicios de Internet, los
						proveedores de servicios inalámbricos o los organismos
						de aplicación de la ley a nuestra discreción.
					</p>
					<div class="sub-heading">Sitios de redes sociales</div>
					<p>
						Como se establece en la Sección IV, nuestros sitios web
						y servicios te permiten publicar contenido en los SNS.
						Si eliges hacer esto, proporcionaremos información a
						dichos SNS de acuerdo con tus elecciones. Reconoces y
						aceptas que eres el único responsable del uso que hagas
						de esos sitios web y que es tu responsabilidad revisar
						los términos de uso y la política de privacidad de los
						SNS correspondientes. No seremos responsables de ello:
						(i) la disponibilidad o exactitud de tales SNS; (ii) el
						contenido, los productos o servicios en o la
						disponibilidad de tales SNS; o (iii) el uso que hagas de
						cualquiera de tales SNS.
					</p>
					<div class="sub-heading">
						Características de la comunidad
					</div>
					<p>
						Como se establece en la Sección III, nuestros Sitios web
						y servicios le permiten publicar Contenido de usuario a
						través de las Características de la comunidad. Si decide
						utilizar estas funciones, se le identificará por su
						nombre y su inicial del apellido. Tenga en cuenta que
						cualquier Información Personal que incluya en un área
						pública del Sitio será visible públicamente. Una vez
						mostrada públicamente, esa información puede ser
						recogida y utilizada por otros. No podemos controlar
						quién lee sus publicaciones o lo que otros usuarios
						pueden hacer con la información que usted publica
						voluntariamente, por lo que es muy importante que no
						ponga en sus publicaciones datos como información de
						contacto privada que no desea poner a disposición del
						público. Una vez que haya publicado información
						públicamente, aunque podrá editarla y eliminarla del
						Sitio, no podrá editar ni eliminar dicha información
						almacenada en caché, recogida y almacenada en otros
						lugares por otros (por ejemplo, en los motores de
						búsqueda).
					</p>
					<p>
						Exigimos a todos los terceros que respeten la seguridad
						de su información personal y que proporcionen el mismo
						nivel de protección de la privacidad que exige esta
						Política de Privacidad.
					</p>
					<div class="sub-heading">
						C. REPARTO ENTRE EMPRESAS DE EDCONSULTING;
						TRANSFERENCIAS DE EMPRESAS
					</div>
					<p>
						EDconsulting también puede revelar y transferir su
						información personal: (i) a un dueño, copropietario u
						operador subsecuente de los Sitios Web o de la base de
						datos aplicable, o de nuestros productos o servicios;
						(ii) si EDconsulting (o cualquiera de sus compañías
						afiliadas, matrices o subsidiarias) asigna sus derechos
						con respecto a cualquiera de su información a un
						tercero; o (iii) en conexión con o durante la
						negociación de una fusión corporativa, consolidación de
						financiamiento, reestructuración, la adquisición o
						transacción de disolución o procedimiento que involucre
						la venta, transferencia, despojo o divulgación de todo o
						una porción de nuestro negocio o activos incluyendo, sin
						limitación, durante el curso de cualquier proceso de
						diligencia debida. En caso de insolvencia, bancarrota o
						administración judicial, la información personal también
						puede ser transferida como un activo comercial. Si otra
						empresa adquiere nuestra empresa, negocio o activos, esa
						empresa poseerá la información personal recopilada por
						nosotros y asumirá los derechos y obligaciones con
						respecto a su información personal como se describe en
						esta Política de Privacidad. Estas transferencias y
						divulgaciones pueden llevarse a cabo sin previo aviso.
					</p>
				</div>

				<div class="section">
					<div class="heading">VIII. SUS ELECCIONES</div>
					<p>
						Tiene varias opciones en cuanto al uso de la información
						de nuestros sitios web y servicios:
					</p>
					<div class="sub-heading">
						A. ACCESO, ACTUALIZACIÓN, CORRECCIÓN O ELIMINACIÓN DE
						INFORMACIÓN
					</div>
					<p>
						Usted puede revisar, solicitar acceso, actualizar,
						corregir o eliminar su Información Personal
						contactándonos en info@edconsulting.biz.
					</p>
					<p>
						Puede solicitar la eliminación de su información
						personal poniéndose en contacto con nosotros y haremos
						todos los esfuerzos comercialmente razonables para
						satisfacer su solicitud, pero tenga en cuenta que es
						posible que se nos pida que conservemos dicha
						información y que no la eliminemos (o que la conservemos
						durante cierto tiempo, en cuyo caso cumpliremos con su
						solicitud de eliminación sólo después de haber cumplido
						con dichos requisitos). Cuando eliminemos cualquier
						información, ésta se eliminará de la base de datos
						activa, pero puede permanecer en nuestros archivos.
						También podemos conservar la información para la
						prevención de fraudes o propósitos similares. Además,
						tenga en cuenta que es posible que tengamos que eliminar
						su cuenta de usuario para poder eliminar su información
						personal.
					</p>
					<p>
						Usted es responsable de mantener la exactitud de la
						información que nos envía, como su información de
						contacto. Si se pone en contacto con nosotros para hacer
						cambios, haremos esfuerzos de buena fe para hacer los
						cambios solicitados en nuestras bases de datos activas
						en ese momento tan pronto como sea razonablemente
						posible. Tenga en cuenta, sin embargo, que la
						información puede persistir internamente para nuestros
						propósitos administrativos y que los datos residuales
						pueden permanecer en medios de respaldo o por otras
						razones.
					</p>
					<div class="sub-heading">B. OPTIMIZACIÓN</div>

					<p>
						Sólo le enviaremos mensajes de correo electrónico de
						marketing directo u otras comunicaciones similares si
						usted opta por recibir dichos mensajes. En el futuro, si
						no desea que su dirección de correo electrónico u otra
						información de contacto se utilice con fines
						promocionales, puede indicar su preferencia por dejar de
						recibir más comunicaciones nuestras y puede "optar por
						no hacerlo" siguiendo las instrucciones para cancelar la
						suscripción que se proporcionan en el correo electrónico
						que recibe o poniéndose en contacto con nosotros
						directamente (consulte la información de contacto en la
						Sección A anterior). Cuando usted opta por no recibir
						estos mensajes de marketing, esto no se aplicará a la
						Información Personal que nos proporcione como resultado
						de la compra de cualquier servicio u otra transacción y
						podremos enviarle comunicaciones relacionadas con el
						servicio, incluyendo avisos de cualquier actualización
						de nuestros Términos de Uso o Política de Privacidad.
					</p>
					<div class="sub-heading">
						C. LA ELECCIÓN DE NO COMPARTIR INFORMACIÓN PERSONAL
					</div>
					<p>
						Puede elegir no proporcionarnos ninguna información
						personal. En tal caso, es posible que aún pueda acceder
						y utilizar algunos de los Sitios web; sin embargo, es
						posible que no pueda acceder y utilizar aquellas partes
						de los Sitios web que requieren su Información personal.
						Puede optar por no recibir correos electrónicos de
						marketing siguiendo las instrucciones de exclusión
						arriba mencionadas o las que se le proporcionen en esos
						correos electrónicos. Tenga en cuenta que nos reservamos
						el derecho de enviarle ciertas comunicaciones
						relacionadas con su cuenta o con el uso de cualquiera de
						los Sitios web (por ejemplo, anuncios administrativos y
						de servicios, avisos de envío y confirmaciones de
						pedidos) y que estos mensajes de cuenta de transacciones
						pueden no verse afectados si usted opta por no recibir
						comunicaciones de marketing.
					</p>
					<p>
						En los casos en que necesitemos recopilar información
						personal por ley, o en virtud de los términos de un
						contrato que tengamos con usted y usted no proporcione
						esa información cuando se le solicite, es posible que no
						podamos cumplir el contrato que tenemos o que estamos
						tratando de celebrar con usted (por ejemplo, para
						proporcionarle bienes o servicios). En este caso, es
						posible que tengamos que cancelar un producto o servicio
						que tenga con nosotros, pero le notificaremos si este es
						el caso en ese momento.
					</p>
					<div class="sub-heading">
						D. COMUNICACIONES DE MARKETING
					</div>
					<p>
						Nos esforzamos por ofrecerle opciones en relación con
						ciertos usos de la información personal, en particular
						en lo que respecta a la comercialización y la
						publicidad. Puede optar por no recibir dichas
						comunicaciones como se indica en la Sección B anterior.
						Tenga en cuenta que puede seguir recibiendo
						comunicaciones relacionadas con el servicio y otras
						comunicaciones no relacionadas con el marketing.
					</p>
					<div class="sub-heading">
						E. DESVINCULACIÓN DE LAS INTERACCIONES CON TERCEROS Y
						SNS
					</div>
					<p>
						Como se establece en la Sección IV, nuestros sitios web
						y servicios te permiten interactuar o publicar contenido
						a ciertos terceros y SNS. Si desea interrumpir estas
						interacciones y conexiones, consulte la configuración de
						privacidad del tercero o SNS para determinar cómo puede
						ajustar nuestros permisos y administrar la
						interactividad entre los Servicios y su cuenta de medios
						sociales.
					</p>
				</div>

				<div class="section">
					<div class="heading">IX. SEGURIDAD DE LOS DATOS</div>
					<p>
						EDconsulting utiliza medidas de seguridad comercialmente
						razonables para salvaguardar la información personal que
						recogemos de la pérdida, el mal uso y el acceso no
						autorizado, la divulgación, la alteración y la
						destrucción. Sin embargo, tenga en cuenta que ningún
						sistema puede ser completamente seguro y EDconsulting no
						asegura o garantiza la seguridad de cualquier
						información que recogemos. Usted utiliza nuestros sitios
						web y nos proporciona su información bajo su propio
						riesgo.
					</p>
					<p>
						Hemos establecido procedimientos para tratar cualquier
						sospecha de violación de la información personal y le
						notificaremos a usted y a cualquier regulador aplicable
						de una violación cuando estemos legalmente obligados a
						hacerlo.
					</p>
				</div>
				<div class="section">
					<div class="heading">
						X. USUARIOS FUERA DE ESPAÑA Y TRANSFERENCIAS
						INTERNACIONALES
					</div>
					<p>
						Los sitios web están alojados y funcionan en España y
						los terceros proveedores de servicios y asociados operan
						en España y otras jurisdicciones. Si se encuentra fuera
						de España, tenga en cuenta que cualquier información que
						nos proporcione puede ser transferida y procesada en
						España y otros países. Al utilizar los Sitios Web, o al
						proporcionarnos cualquier información, usted reconoce y
						consiente esta transferencia, procesamiento y
						almacenamiento de su información en países donde las
						leyes de privacidad pueden ser menos estrictas que las
						del país donde reside o es ciudadano.
					</p>
				</div>

				<div class="section">
					<div class="heading">XI. PREGUNTAS</div>
					<p>
						Si tiene alguna pregunta o inquietud sobre nuestra
						política o prácticas de privacidad, no dude en ponerse
						en contacto con nosotros por correo electrónico en
						info@edconsulting.biz.
					</p>
				</div>

				<div class="section">
					<div class="heading">
						XII. INFORMACIÓN ADICIONAL PARA LOS RESIDENTES DE LA
						UNIÓN EUROPEA
					</div>
					<div class="sub-heading">A. INFORMACIÓN PERSONAL</div>
					<p>
						Las referencias a "Información Personal" en esta
						Política de Privacidad son equivalentes a "Datos
						Personales" regidos por la legislación europea de
						protección de datos.
					</p>
					<div class="sub-heading">
						B. BASES LEGALES PARA EL TRATAMIENTO DE DATOS PERSONALES
					</div>
					<p>
						Sólo utilizaremos sus datos personales cuando la ley nos
						lo permita. Lo más común es que usemos su información
						personal en las siguientes circunstancias:
					</p>
					<ul>
						<li>
							Cuando necesitemos cumplir las obligaciones
							relativas a un contrato en el que usted sea parte o
							tomar medidas a petición suya antes de celebrar
							dicho contrato.
						</li>
						<li>
							Cuando sea necesario para nuestros intereses
							legítimos (o los de un tercero) y sus intereses y
							derechos fundamentales no prevalezcan sobre esos
							intereses. Nuestros intereses legítimos incluyen los
							relacionados con la conducción y gestión de nuestro
							negocio para permitirnos ofrecerle el mejor
							servicio/producto y la mejor y más segura
							experiencia. Nos aseguramos de considerar y
							equilibrar cualquier impacto potencial sobre usted
							(tanto positivo como negativo) y sus derechos antes
							de procesar su Información Personal para nuestros
							intereses legítimos. No utilizamos su información
							personal para actividades en las que nuestros
							intereses se vean anulados por el impacto sobre
							usted (a menos que tengamos su consentimiento o que
							la ley nos lo exija o permita). Puede obtener más
							información sobre cómo evaluamos nuestros intereses
							legítimos frente a cualquier impacto potencial sobre
							usted con respecto a actividades específicas
							poniéndose en contacto con nosotros.
						</li>
						<li>
							Donde tenemos que cumplir con una obligación legal o
							reglamentaria. Por lo general, no dependemos del
							consentimiento como base legal para el procesamiento
							de su información personal, salvo en relación con el
							envío de comunicaciones de marketing por correo
							electrónico. Tiene derecho a retirar el
							consentimiento para la comercialización en cualquier
							momento poniéndose en contacto con nosotros
							utilizando la información de contacto proporcionada
							anteriormente. A continuación, en forma de tabla,
							hemos presentado una descripción de las formas en
							que planeamos utilizar sus datos personales y de las
							bases jurídicas en las que nos basamos para hacerlo.
							También hemos identificado cuáles son nuestros
							intereses legítimos cuando sea apropiado. Tenga en
							cuenta que podemos procesar sus datos personales por
							más de un motivo legal dependiendo del propósito
							específico para el que estamos utilizando sus datos.
							Por favor, póngase en contacto con nosotros si
							necesita detalles sobre la base legal específica en
							la que nos basamos para procesar sus datos
							personales.
						</li>
					</ul>
					<div class="sub-heading">C. CONTROLADOR</div>
					<p>
						Esta Política de Privacidad se emite en nombre de
						info@edconsulting.biz. Si tiene alguna pregunta sobre
						esta Política de Privacidad, incluyendo cualquier
						solicitud para ejercer sus derechos legales, por favor
						contáctenos usando los detalles que se indican a
						continuación.
					</p>
					<div class="sub-heading">D. RETENCIÓN DE DATOS</div>
					<p>
						Sólo conservaremos sus datos personales durante el
						tiempo que sea necesario para cumplir con los fines para
						los que los hemos recogido, incluyendo el cumplimiento
						de cualquier requisito legal, contable o de información.
					</p>
					<p>
						Para determinar el período de retención apropiado para
						los datos personales, consideramos la cantidad, la
						naturaleza y la sensibilidad de los datos personales, el
						riesgo potencial de daño por el uso o la divulgación no
						autorizados de sus datos personales, los fines para los
						que procesamos sus datos personales y si podemos lograr
						esos fines a través de otros medios, y los requisitos
						legales aplicables. En algunas circunstancias, puede
						pedirnos que eliminemos sus datos: véase la subsección
						Solicitud de eliminación más abajo para más información.
					</p>
					<p>
						En algunas circunstancias, es posible que anonimicemos
						sus datos personales (de modo que ya no puedan asociarse
						con usted) para fines de investigación o estadísticos,
						en cuyo caso podremos utilizar esta información
						indefinidamente sin necesidad de notificárselo.
					</p>
					<div class="sub-heading">E. CAMBIO DE PROPÓSITO</div>
					<p>
						Sólo utilizaremos sus datos personales para los fines
						para los que los hemos recogido, a menos que
						consideremos razonablemente que necesitamos utilizarlos
						por otra razón y que ésta sea compatible con el
						propósito original. Si desea obtener una explicación de
						cómo el procesamiento para el nuevo propósito es
						compatible con el propósito original, por favor
						contáctenos. Si necesitamos utilizar sus datos
						personales para un propósito no relacionado, se lo
						notificaremos y le explicaremos la base legal que nos
						permite hacerlo.
					</p>
					<p>
						Tenga en cuenta que podemos procesar sus datos
						personales sin su conocimiento o consentimiento, en
						cumplimiento de las normas anteriores, cuando la ley lo
						exija o lo permita.
					</p>
					<div class="sub-heading">
						F. DERECHOS DE LOS DATOS PERSONALES DE LOS RESIDENTES EN
						LA UE
					</div>
					<p>
						En determinadas circunstancias, los residentes de la UE
						pueden tener derechos en virtud de las leyes de
						protección de datos en relación con sus datos
						personales, como se indica a continuación:
					</p>
					<ul>
						<li>
							SOLICITAR ACCESO a sus datos personales (comúnmente
							conocido como "solicitud de acceso del sujeto de
							datos"). Esto le permite recibir una copia de los
							datos personales que tenemos sobre usted y comprobar
							que los estamos procesando legalmente.
						</li>
						<li>
							SOLICITAN LA CORRECCIÓN de los datos personales que
							tenemos sobre usted. Esto le permite corregir
							cualquier dato incompleto o inexacto que tengamos
							sobre usted, aunque puede que necesitemos verificar
							la exactitud de los nuevos datos que nos
							proporcione.
						</li>
						<li>
							SOLICITAR LA ERRORIZACIÓN de sus datos personales.
							Esto le permite pedirnos que borremos o eliminemos
							datos personales cuando no hay una buena razón para
							que continuemos procesándolos. También tiene derecho
							a solicitarnos que eliminemos o borremos sus datos
							personales cuando haya ejercido con éxito su derecho
							a oponerse al procesamiento (véase más abajo),
							cuando podamos haber procesado su información de
							forma ilegal o cuando estemos obligados a borrar sus
							datos personales para cumplir con la legislación
							local. Tenga en cuenta, sin embargo, que no siempre
							podremos cumplir con su solicitud de borrado por
							razones legales específicas que se le notificarán,
							si procede, en el momento de su solicitud.
						</li>
						<li>
							OBJETO DEL TRATAMIENTO de sus datos personales
							cuando nos basamos en un interés legítimo (o en los
							de un tercero) y hay algo en su situación particular
							que hace que quiera oponerse al tratamiento por este
							motivo ya que considera que afecta a sus derechos y
							libertades fundamentales. También tiene derecho a
							oponerse cuando estemos procesando sus datos
							personales con fines de marketing directo. En
							algunos casos, podemos demostrar que tenemos motivos
							legítimos convincentes para procesar su información
							que prevalecen sobre sus derechos y libertades.
						</li>
						<li>
							SOLICITAN RESTRICCIÓN DEL PROCESAMIENTO de sus datos
							personales. Esto le permite pedirnos que suspendamos
							el procesamiento de sus datos personales en los
							siguientes casos: a) si desea que establezcamos la
							exactitud de los datos; b) si nuestro uso de los
							datos es ilegal pero usted no desea que los
							borremos; c) si necesita que conservemos los datos
							aunque ya no los necesitemos ya que usted los
							necesita para establecer, ejercer o defender
							reclamaciones legales; o d) si se ha opuesto a
							nuestro uso de sus datos pero necesitamos verificar
							si tenemos motivos legítimos imperiosos para
							utilizarlos.
						</li>
						<li>
							SOLICITA LA TRANSFERENCIA de sus datos personales a
							usted o a un tercero. Le proporcionaremos a usted, o
							a un tercero que haya elegido, sus datos personales
							en un formato estructurado, de uso común y legible
							por máquina. Tenga en cuenta que este derecho sólo
							se aplica a la información automatizada que usted
							inicialmente nos dio su consentimiento para
							utilizarla o cuando utilizamos la información para
							cumplir un contrato con usted.
						</li>
						<li>
							RETIRE EL CONSENTIMIENTO en cualquier momento en el
							que confiemos en el consentimiento para procesar sus
							datos personales. Sin embargo, esto no afectará a la
							legalidad de cualquier procesamiento realizado antes
							de que usted retire su consentimiento. Si retira su
							consentimiento, es posible que no podamos
							proporcionarle ciertos productos o servicios. Le
							informaremos de ello en el momento en que retire su
							consentimiento.
						</li>
					</ul>
					<p>
						Si desea ejercer alguno de los derechos arriba
						mencionados, póngase en contacto con
						info@edconsulting.biz.
					</p>
					<div class="sub-heading">Normalmente no se requiere pagar nada</div>
					<p>
						No tendrá que pagar una cuota para acceder a sus datos
						personales (o para ejercer cualquiera de los otros
						derechos). Sin embargo, podemos cobrar una tarifa
						razonable si su solicitud es claramente infundada,
						repetitiva o excesiva. Alternativamente, podemos
						negarnos a cumplir con su solicitud en estas
						circunstancias.
					</p>
					<div class="sub-heading">Lo que podemos necesitar de usted</div>
					<p>
						Es posible que necesitemos solicitarle información
						específica para ayudarnos a confirmar su identidad y
						asegurar su derecho a acceder a sus datos personales (o
						a ejercer cualquiera de sus otros derechos). Esta es una
						medida de seguridad para asegurar que los datos
						personales no se revelen a ninguna persona que no tenga
						derecho a recibirlos. También podemos ponernos en
						contacto con usted para pedirle más información en
						relación con su solicitud para acelerar nuestra
						respuesta.
					</p>
					<div class="sub-heading">Límite de tiempo para responder</div>
					<p>
						Tratamos de responder a todas las solicitudes legítimas
						en el plazo de un mes. Ocasionalmente puede llevarnos
						más de un mes si su solicitud es particularmente
						compleja o ha hecho varias solicitudes. En este caso, le
						notificaremos y le mantendremos informado.
					</p>
					<div class="sub-heading">Quejas a la autoridad supervisora</div>
					<p>
						Los residentes de la UE pueden tener derecho a presentar
						una queja en cualquier momento ante la autoridad de
						supervisión para cuestiones de protección de datos. Sin
						embargo, agradeceríamos la oportunidad de tratar sus
						preocupaciones antes de que se dirija a la autoridad
						supervisora, así que por favor primero póngase en
						contacto con nosotros directamente para permitirnos
						hacerlo.
					</p>
				</div>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-8 offset-2">
				<div class="text-left mb-3">
					<Link class="font-weight-bold" to="/"><i class="fas fa-arrow-left"></i> Volver</Link>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import Link from "@/components/Link.vue";
export default {
	name: "PrivacyPolicy",
	components: {Link},
};
</script>


<style scoped>
.heading {
	font-weight: bold;
	font-size: 20pt;
}
.sub-heading{
	font-size: 16pt;
	font-weight: bold;
}
p {
	font-size: 12pt;
	text-align: justify;
}

.content-container {
	display: block;
	width: 45vw;
}
</style>