<template>
	<div>
		NotFoound
	</div>
</template>

<script>
export default {
	name: "NotFound",
	components: {},
};
</script>
