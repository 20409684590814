<template>
	<div class="keyboard-drag none">
		<div class="drag-drop">
			<div
				class="keyboard-wrapper keyboard-act drop activity-5"
				style="left: -3.9em; width: 40.5em; top: -17em"
				tabindex="0"
			>
				<div
					class="keyboard-activity dropzone red-bg"
					data-dropzone="11"
				></div>
				<div
					class="keyboard-activity dropzone blue-bg"
					data-dropzone="12"
				></div>
				<div
					class="keyboard-activity dropzone yellow-bg"
					data-dropzone="13"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="14"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="15"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="16"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="17"
				></div>
				<div
					class="keyboard-activity dropzone yellow-bg"
					data-dropzone="18"
				></div>
				<div
					class="keyboard-activity dropzone blue-bg"
					data-dropzone="19"
				></div>
				<div
					class="keyboard-activity dropzone red-bg"
					data-dropzone="20"
				></div>
			</div>
			<div
				class="keyboard-wrapper keyboard-act drop activity-5"
				style="top: -16.8em; width: 41em"
			>
				<div
					class="keyboard-activity dropzone red-bg"
					data-dropzone="1"
				></div>
				<div
					class="keyboard-activity dropzone blue-bg"
					data-dropzone="2"
				></div>
				<div
					class="keyboard-activity dropzone yellow-bg"
					data-dropzone="3"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="4"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="5"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="6"
				></div>
				<div
					class="keyboard-activity dropzone green-bg"
					data-dropzone="7"
				></div>
				<div
					class="keyboard-activity dropzone yellow-bg"
					data-dropzone="8"
				></div>
				<div
					class="keyboard-activity dropzone blue-bg"
					data-dropzone="9"
				></div>
				<div
					class="keyboard-activity dropzone red-bg"
					data-dropzone="10"
				></div>
			</div>
			<div
				class="keyboard-wrapper drag"
				style="position: relative; top: -5em"
			>
				<div class="dropzone occupied" data-dropzone="15">
					<div class="draggable">T</div>
				</div>
				<div class="dropzone occupied" data-dropzone="8">
					<div class="draggable">K</div>
				</div>
				<div class="dropzone occupied" data-dropzone="19">
					<div class="draggable">O</div>
				</div>
				<div class="dropzone occupied" data-dropzone="6">
					<div class="draggable">H</div>
				</div>
				<div class="dropzone occupied" data-dropzone="11">
					<div class="draggable">Q</div>
				</div>
				<div class="dropzone occupied" data-dropzone="2">
					<div class="draggable">S</div>
				</div>
				<div class="dropzone occupied" data-dropzone="13">
					<div class="draggable">E</div>
				</div>
				<div class="dropzone occupied" data-dropzone="7">
					<div class="draggable">J</div>
				</div>
				<div class="dropzone occupied" data-dropzone="14">
					<div class="draggable">R</div>
				</div>
				<div class="dropzone occupied" data-dropzone="10">
					<div class="draggable">Ñ</div>
				</div>
			</div>
			<div
				class="keyboard-wrapper drag"
				style="position: relative; top: -5em"
			>
				<div class="dropzone occupied" data-dropzone="20">
					<div class="draggable">P</div>
				</div>
				<div class="dropzone occupied" data-dropzone="4">
					<div class="draggable">F</div>
				</div>
				<div class="dropzone occupied" data-dropzone="16">
					<div class="draggable">Y</div>
				</div>
				<div class="dropzone occupied" data-dropzone="3">
					<div class="draggable">D</div>
				</div>
				<div class="dropzone occupied" data-dropzone="12">
					<div class="draggable">W</div>
				</div>
				<div class="dropzone occupied" data-dropzone="1">
					<div class="draggable">A</div>
				</div>
				<div class="dropzone occupied" data-dropzone="17">
					<div class="draggable">U</div>
				</div>
				<div class="dropzone occupied" data-dropzone="9">
					<div class="draggable">L</div>
				</div>
				<div class="dropzone occupied" data-dropzone="18">
					<div class="draggable">I</div>
				</div>
				<div class="dropzone occupied" data-dropzone="5">
					<div class="draggable">G</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { setupActivityThree } from "@/common/activities";

export default {
	name: "Third",
	mounted: () => setupActivityThree("keyboard-wrapper", "keyboard-act", 20),
};
</script>

<style scoped>
</style>