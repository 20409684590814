<template>
	<div class="congrats w-100">
		<h2>
			<p>Has llegado a la meta final, ¡felicidades!</p>
			<p>Ahora ya sabes la ubicación de todas las teclas en la fila básica, la fila superior y la fila inferior. -</p> 
			<p>¡Genial!</p>
			<p>Esto te habría llevado al menos 20 - 25 horas con los métodos tradicionales.</p>

			<p>El siguiente paso es practicar la agilidad. Entonces, vamos al "Gimnasio para los dedos”:</p>

			<p>Puedes repetir todos los entrenos de tecleo de las 3 etapas</p>
			<p>tantas veces como quieras durante 3 meses, ¡además del Tecleo Mix!</p>

			<p>¡Te convertirás en un campeón! ¡Que te vaya bien! </p> 
			<AuthButton to="/three/practice" style="position: static;">Tu entreno de tecleo</AuthButton>
		</h2>
	</div>
</template>

<script>
import AuthButton from '../../../../components/AuthButton.vue'
import { setupActivityTwo } from "@/common/activities";

export default {
	components: { AuthButton },
	name: "Congrats",
	mounted: ()=> setupActivityTwo('wrapper2', 'wrapper2', '.right-to-left .series-row'),
};
</script>

<style scoped>
</style>