const getDefaultState = () =>  {
	return {
		pupil: !!localStorage.getItem('pupil'),

		sptt: {
			paid: true,
		
			introduction: false,

			one_learn_both: false,
			one_learn_left: false,
			one_activity_left: false,
			one_learn_right: false,
			one_activity_right: false,
			one_practice: false,
			one_practice_finished: false,

			two_learn_both: false,
			two_learn_left: false,
			two_activity_left: false,
			two_learn_right: false,
			two_activity_right: false,
			two_practice: false,
			two_practice_finished: false,

			three_learn_both: false,
			three_learn_left: false,
			three_activity_left: false,
			three_learn_right: false,
			three_activity_right: false,
			three_practice: false,
			three_practice_finished: false,

			mix: false,
			mix_finished: false,
		},

		requesting: false,
		loading: false,
		start: false,
		next: false,
		
		playing: false,
		maximized: false,

		activity: false,
	
		primary: null,
		secondary: null,
	
		animation: null,
		howler: null,

		accent_down:false,
		curr_pointer: 0,
		word_count: 0,
		strokes_count:0,
		text: "",
		formatted_text: "",
		typing_text: "",
		
	}
};


export default getDefaultState;