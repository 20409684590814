import store from "@/store/index";

import { Howl } from "howler";
import anime from "animejs";
import { windowBlur } from '@/common/helpers'
import SPTT from "@/api/SPTT";

export const startLesson = () => {
	SPTT.started('one_activity_left');
	store.getters.animation.value
		.add({
			duration: 2000,
			begin: () => {
				store.dispatch("secondary", store.getters.howler.value.play("active"));
				store.getters.howler.value.fade(
					0,
					0.3,
					3000,
					store.getters.secondary.value
				);
			},
		})
		// Example of activity
		.add({
			delay: 1000,
			targets: "#left-hand-example-video",
			opacity: [0, 1],
			easing: "easeInOutSine",
			translateX: ["-10%", "0%"],
			duration: 3000,
			endDelay: 1000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
			},
			complete: () => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR45-1"));
			},
		})
		.add({
			delay: 21000,
			targets: "#left-hand-example-video",
			opacity: [1, 0],
			duration: 2000,
			endDelay: 1000,
			easing: "easeInOutSine",
			complete: ({ animatables }) => {
				animatables[0].target.classList.add("none");
			},
		})
		/** *************************************************************************************************
		 * Activity 1
		 ***************************************************************************************************
		 */
		// show draggable and hands  -> activity 1
		.add({
			delay: 0,
			targets: [".left-hand-drag", "#overlay-animation"],
			opacity: [0, 1],
			easing: "easeInOutSine",
			duration: 5000,
			begin: ({ animatables }) => {
				animatables[0].target.classList.remove("none");
				animatables[1].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("LHHR46"));
			},
			complete: ({ animatables }) => {
				animatables[1].target.classList.add("none");
				// start Activity 1
				window.removeEventListener("blur", windowBlur);

				store.dispatch("onActivity");
			},
		})

		// activity 1 stopped
		.add({
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})
		.add({
			delay: 1000,
			targets: [".left-hand-drag"],
			duration: 2500,
			begin: ({ animatables }) => {
				store.dispatch("primary", store.getters.howler.value.play("LHHR46-1"));

				animatables[0].target.classList.add("fade-out");
			},
		})
		.add({
			delay: 500,
			targets: [".left-hand-drag", ".left-to-right", "#overlay-animation"],
			begin: ({ animatables }) => {
				animatables[0].target.classList.add("none");
				animatables[1].target.classList.remove("none");
				animatables[2].target.classList.remove("none");

				store.dispatch("primary", store.getters.howler.value.play("LHHR47"));
			},
			duration: 22000,
			complete: ({ animatables }) => {
				animatables[2].target.classList.add("none");
				// Start activity 2
				window.removeEventListener("blur", windowBlur);
				store.dispatch("onActivity");
			},
		})

		// activit 2 stopped
		.add({
			duration: 3000,
			begin: () => {
				window.addEventListener("blur", windowBlur);
			},
		})

		// ok thumbsup
		.add({
			targets: ".right-ok",
			opacity: [0, 1],
			duration: 1000,
			translateX: ["-20%", "0%"],
			begin: ({ animatables }) => {
				console.log('begin')

				animatables[0].target.classList.remove("none");
				store.dispatch("primary", store.getters.howler.value.play("RHHR1-1"));
				
			},
			complete: ()=> console.log('complete'),

			endDelay: 15000,
		})
		.add({
			begin: () => {
				window.removeEventListener("blur", windowBlur);
				store.dispatch("playing", false);

				store.dispatch("next", true);
				SPTT.finished('one_activity_left');

			},
		});
	store.dispatch("start", false);
	store.dispatch("playing", true);
	window.addEventListener("blur", windowBlur);

	store.getters.animation.value.play();
};

export const setupLesson = () => {
	store.dispatch(
		"howler",
		new Howl({
			src: [require("@/assets/audio/home_row/activity/left.mp3")],
			sprite: {
				active: [0, 252120, true],
				"LHHR45-1": [254000, 12846.757369614523],
				"LHHR46-1": [268000, 2249.433106575964],
				LHHR46: [272000, 4598.84353741495],
				LHHR47: [278000, 22894.19501133784],
				"RHHR1-1": [302000, 14234.013605442158],
			},
			loop: false,
			onload: () => {
				store.dispatch("loading", false);
				store.dispatch("start", true);
			},
			onloaderror: (id, err) => {
				console.log("error while loding");
				console.log(id, err);
			},
		})
	);
	store.dispatch(
		"animation",
		anime.timeline({ easing: "linear", autoplay: false })
	);
};
