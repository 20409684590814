import Api from './Api'
import csrf from './csrf'
import store from '@/store/index'

export default {

	async all(){
		store.dispatch('requesting',true);
		await csrf.getCookie();
		try{
			const response = await Api.get('/user/sptt')
			if (response.status == 200) {
				store.dispatch("sptt", response.data);
			}
			store.dispatch('requesting', false)
		}catch(err){
			console.log("err", err);
			store.dispatch('requesting', false)
		}
	},

	async finished(lesson){
		await csrf.getCookie();

		const response = await Api.patch('/user/sptt/finished', {lesson})

		if(response.status == 200){
			let spttObject = {};
			if(['one_practice','two_practice','three_practice','mix'].includes(lesson)){
				spttObject[lesson] = false
				spttObject[lesson+'_finished'] = true
			}else{
				spttObject[lesson] = true
			}
			store.dispatch('sptt', spttObject)
		}

	},

	async started(lesson){
		await csrf.getCookie();

		return Api.patch('/user/sptt/started', {lesson})
	}
	
}